import { dispatch, dispatchNoPayload } from './middleware';
import { EXPORT_API, FUEL_LOGS_API } from '../configs/api';
import Store from '../../data/store/store';
// import axios from 'axios';
import { createFuelLogsDTO } from '../dto/fuelLogs';
import { handleError, handleSuccess } from './messaging';
import { fetchOrganisations } from './organisations';
import { createOrganisationDTO } from '../dto/organisations';

import { exportApi, getData } from '../../logic/middleware/services/apiRequest';
import { exportToExcelWithLogo } from '../utilities/excel';
import { FUELLOGS_FILE_HEADER } from '../../data/constants/general';
import { closeExportModal, convertToPdf, formatExcelExport } from './devices';
import fileDownload from 'js-file-download';
/*import { datadogLogs } from '@datadog/browser-logs';*/

export function refreshFuelLogs(id, param = '', activePage = 1, resetOffset = false) {
	dispatchNoPayload('FUEL_LOGS_LOADING');
	/*axios(
      FUEL_LOGS_API + '?pagecount=' +
      getDataFromStore().dataSizeLimit +
      '&pagenumber=' +
      (resetOffset ? 1 : activePage) +
      param,
      {
          headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
              'Content-Type': 'application/json',
          },
      }
  ) */
	getData(
		FUEL_LOGS_API,
		`?pagecount=${getDataFromStore().dataSizeLimit}&pagenumber=${resetOffset ? 1 : activePage}${param}`
	)
		.then(async (response) => {
			const dtoResponse = createFuelLogsDTO(response?.data?.data);
			if (dtoResponse === undefined) dispatch('FEED_FUEL_LOGS', []);
			else dispatch('FEED_FUEL_LOGS', dtoResponse);

			dispatch('FUEL_LOGS_SET_PAGE_COUNT', response?.data?.count || 0);
			dispatch('FUEL_LOGS_SET_PAGE_NUMBER', activePage);
			dispatchNoPayload('LOGS_FUEL_LOADED');
			handleSuccess(response);
		})
		.catch((error) => {
			dispatchNoPayload('LOGS_FUEL_LOADED');
			/*datadogLogs.logger.error('Refresh fuel logs api', {}, error);*/
			handleError(error);
		});
}

export function exportAll(param, fileExtension) {
	exportApi(EXPORT_API + '/fuel-log', param)
		.then((response) => {
			fileDownload(response?.data, `Fuel-Logs.${fileExtension}`);
		})
		.catch((error) => {
			/*datadogLogs.logger.error('Fuel logs export api', {}, error);*/
			handleError(error);
		});
}

export function downloadDocumentFile(id) {
	return exportApi(EXPORT_API + `/fuel-log/${id}`);
}

export function fetchDocumentFile(id) {
	return exportApi(EXPORT_API, `/fuel-log/${id}`);
}

export function exportData(data, fileName, extension) {
	if (extension === 'excel') {
		exportToExcelWithLogo(formatExcelExport(data), `${!fileName ? 'Devices' : fileName}`, FUELLOGS_FILE_HEADER);
	} else {
		convertToPdf(formatExcelExport(data), fileName);
	}
	closeExportModal();
}

function getDataFromStore() {
	return {
		pageNumber: Store.getState().fuelLogs.pageNumber,
		dataSizeLimit: Store.getState().ui.dataSizeLimit,
		initialDate: Store.getState().dates.initialDate,
		finalDate: Store.getState().dates.finalDate,
	};
}

export function getOrganisationsForDropdown(params = '') {
	return fetchOrganisations(params)
		.then((response) => {
			return createOrganisationDTO(response?.data?.data).map.map((organisation, index) => {
				return {
					key: index,
					value: organisation.id,
					text: organisation.name,
				};
			});
		})
		.catch((error) => {
			handleError(error);
			return [];
		});
}

export function toggleAccordion(e, titleProps, current) {
	const { index } = titleProps;
	const { activeIndex } = {
		activeIndex: current,
	};
	const newIndex = activeIndex === index ? -1 : index;
	dispatchNoPayload('FUEL_LOGS_EDIT_MODAL_CLOSE');
	dispatch('FUEL_LOGS_TOGGLE_ACCORDION', newIndex);
}
