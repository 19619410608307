import { EXPORT_API, VEHICLE_GROUPS_API } from '../configs/api';
import { VEHICLE_GROUPS_FILE_HEADER } from '../../data/constants/general';
import { createVehicleGroupDTO } from '../dto/vehicleGroups';
import { createOrganisationDTO } from '../dto/organisations';
import { createVehicleDTO } from '../dto/vehicles';
import { createUsersDTO } from '../dto/users';
import { fetchUsers } from './users';
import { fetchVehicles } from './vehicles';
import { fetchOrganisations } from './organisations';
import Logo from '../../ui/assets/images/logo.png';
import { handleError } from './messaging';
import { exportToExcelWithLogo } from '../utilities/excel';
import { dispatch, dispatchNoPayload } from './middleware';
import Store from '../../data/store/store';
import axios from 'axios';
import { jsPDF } from 'jspdf';
import fileDownload from 'js-file-download';
import { getData } from './services/apiRequest';
/*import { datadogLogs } from '@datadog/browser-logs';*/

export function fetchVehicleGroups(params = '') {
	return getData(VEHICLE_GROUPS_API, params);
	// axios(VEHICLE_GROUPS_API + params, {
	// 	headers: {
	// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 		'Content-Type': 'application/json',
	// 	},
	// });
}

export function refreshVehicleGroups(params = '', activePage = 1, resetOffset = false) {
	dispatchNoPayload('VEHICLE_GROUPS_LOADING');

	// axios()
	// 	VEHICLE_GROUPS_API +
	// 		'?pagecount=' +
	// 		getPageNumberFromStore().dataSizeLimit +
	// 		'&pagenumber=' +
	// 		(resetOffset ? 1 : activePage) +
	// 		params,
	// 	{
	// 		headers: {
	// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 			'Content-Type': 'application/json',
	// 		},
	// 	}
	// )
	getData(
		VEHICLE_GROUPS_API +
			'?pagecount=' +
			getPageNumberFromStore().dataSizeLimit +
			'&pagenumber=' +
			(resetOffset ? 1 : activePage) +
			params
	)
		.then((response) => {
			dispatch('FEED_VEHICLE_GROUPS', createVehicleGroupDTO(response?.data?.data));
			// dispatch('ACTIVE_FILTER', response?.data?.data);
			dispatch('VEHICLE_GROUPS_SET_PAGE_COUNT', response?.data?.count || 0);
			dispatch('VEHICLE_GROUPS_SET_PAGE_NUMBER', activePage);
		})
		.catch((error) => {
			dispatchNoPayload('VEHICLE_GROUPS_LOADED');
			/*datadogLogs.logger.error('refresh vehicle groups api', {}, error);*/
			handleError(error);
		});
}

export function searchVehicleGroups(query) {
	return `&search=${encodeURIComponent(query)
		.trim()
		.split('+')
		.map((searchTerm) => `${searchTerm}`)}`;
}

// Export
export function openExportModal() {
	dispatchNoPayload('VEHICLE_GROUPS_EXPORT_MODAL_OPEN');
}

export function closeExportModal() {
	dispatchNoPayload('VEHICLE_GROUPS_EXPORT_MODAL_CLOSE');
}

export function formatExcelExport(response) {
	return response.map((group) => {
		return {
			Name: group.name,
			Organisation: group.organisation.name,
			Vehicles: group.vehicleCount,
			Users: group.userCount,
			Description: group.description,
		};
	});
}

export function exportData(data, fileName, extension) {
	if (extension === 'excel') {
		exportToExcelWithLogo(
			formatExcelExport(data),
			`${!fileName ? 'Vehicle Groups' : fileName}`,
			VEHICLE_GROUPS_FILE_HEADER
		);
	} else {
		convertToPdf(formatExcelExport(data), fileName);
	}

	closeExportModal();
}

export function convertToPdf(data, fileName) {
	const doc = new jsPDF({ orientation: 'l', unit: 'mm', format: 'a4' });

	let col = ['Name', 'Organisation', 'Vehicles', 'Users', 'Description'];
	let rows = [];

	data.forEach((group) => {
		let temp = [];
		Object.keys(group).forEach((key, index) => {
			if (col[index] === key) temp[index] = group[key];
		});

		rows.push(temp);
	});

	let header = function (data) {
		doc.addImage(Logo, 'png', data.settings.margin.left, 10, 40, 14);
	};

	doc.autoTable(col, rows, {
		margin: { top: 25 },
		beforePageContent: header,
		theme: 'grid',
	});

	doc.save(`${!fileName ? 'Vehicle Groups' : fileName}`);
}

export function exportAll(param, fileExtension) {
	// dispatchNoPayload('EXPORTING_VEHICLE_GROUPS_EXPORT_DATA');

	axios(EXPORT_API + '/vehicleGroups' + param, {
		headers: {
			Authorization: 'Bearer ' + localStorage.getItem('token'),
			'Content-Type': 'application/json',
		},
	})
		.then((response) => {
			openExportModal();
			// dispatch('SET_VEHICLE_GROUPS_EXPORT_DATA', createVehicleGroupDTO(response?.data?.data));
			// dispatchNoPayload('EXPORTED_VEHICLE_GROUPS_EXPORT_DATA');
			fileDownload(response?.data, fileExtension);
		})
		.catch((error) => {
			handleError(error);
			/*datadogLogs.logger.error('vehicle group export api', {}, error);*/
			dispatchNoPayload('EXPORTED_VEHICLE_GROUPS_EXPORT_DATA');
		});
}

export function openFilterModal() {
	dispatchNoPayload('VEHICLE_GROUPS_FILTER_MODAL_OPEN');
}

export function closeFilterModal() {
	dispatchNoPayload('VEHICLE_GROUPS_FILTER_MODAL_CLOSE');
}

// Accordion
export function toggleAccordion(e, titleProps, current) {
	const { index } = titleProps;
	const { activeIndex } = {
		activeIndex: current,
	};
	const newIndex = activeIndex === index ? -1 : index;
	dispatchNoPayload('VEHICLE_GROUPS_EDIT_MODAL_CLOSE');
	dispatch('VEHICLE_GROUPS_TOGGLE_ACCORDION', newIndex);
}

// Dropdown
export function getOrganisationsForDropdown(params = '') {
	return fetchOrganisations(params)
		.then((response) => {
			return createOrganisationDTO(response?.data?.data).map((organisation, index) => {
				return {
					key: index,
					value: organisation.id,
					text: organisation.name,
				};
			});
		})
		.catch((error) => {
			handleError(error);
			return [];
		});
}

export function getVehiclesForDropdown(params = '') {
	return fetchVehicles(params)
		.then((response) => {
			return createVehicleDTO(response?.data?.data).map((vehicle, index) => {
				return {
					key: index,
					value: vehicle.id,
					text: vehicle.registrationNumber,
				};
			});
		})
		.catch((error) => {
			handleError(error);
			return [];
		});
}

export function getUsersForDropdown(params = '') {
	return fetchUsers(params)
		.then((response) => {
			return createUsersDTO(response?.data?.data).map((user, index) => {
				return {
					key: index,
					value: user.id,
					text: user.name,
				};
			});
		})
		.catch((error) => {
			handleError(error);
			return [];
		});
}

// Connect to Store
function getPageNumberFromStore() {
	return {
		pageNumber: Store.getState().vehicleGroups.pageNumber,
		dataSizeLimit: Store.getState().ui.dataSizeLimit,
	};
}
