import { EXPORT_API, GEOFENCES_API, USERS_API } from '../configs/api';
import { USERS_FILE_HEADER } from '../../data/constants/general';
import { createUsersDTO } from '../dto/users';
import { createOrganisationDTO } from '../dto/organisations';
import { createVehicleGroupDTO } from '../dto/vehicleGroups';
import { createRolesDTO } from '../dto/roles';
import { createVehicleDTO } from '../dto/vehicles';
import { createGeoFenceDTO } from '../dto/geofences';
import Logo from '../../ui/assets/images/logo.png';
import { dispatch, dispatchNoPayload } from './middleware';
import { fetchVehicleGroups } from './vehicleGroups';
import { fetchOrganisations } from './organisations';
import { fetchVehicles } from './vehicles';
import Store from '../../data/store/store';
import { exportToExcelWithLogo } from '../utilities/excel';
import { handleError } from './messaging';
import { fetchRoles } from './roles';

import axios from 'axios';
import { jsPDF } from 'jspdf';
import fileDownload from 'js-file-download';
/*import { datadogLogs } from '@datadog/browser-logs';*/

// import fileDownload from 'js-file-download';

export function fetchUsers(params = '') {
	return axios(USERS_API + params, {
		headers: {
			Authorization: 'Bearer ' + localStorage.getItem('token'),
			'Content-Type': 'application/json',
		},
	});
}

export function refreshUsers(params = '', activePage = 1, resetOffset = false) {
	dispatchNoPayload('USERS_LOADING');

	axios(
		USERS_API +
			'?pagecount=' +
			getPageNumberFromStore().dataSizeLimit +
			'&pagenumber=' +
			(resetOffset ? 1 : activePage) +
			params,
		{
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
				'Content-Type': 'application/json',
			},
		}
	)
		.then((response) => {
			dispatch('FEED_USERS', createUsersDTO(response?.data?.data));
			dispatch('USERS_SET_PAGE_COUNT', response?.data?.count || 0);
			dispatch('USERS_SET_PAGE_NUMBER', activePage);
		})
		.catch((error) => {
			dispatchNoPayload('USERS_LOADED');
			/*datadogLogs.logger.error('Refresh user api', {}, error);*/

			handleError(error);
		});
}

export function searchUsers(query) {
	return `&search=${encodeURIComponent(query)
		.trim()
		.split('+')
		.map((searchTerm) => `${searchTerm}`)}`;
}

// Export
export function openExportModal() {
	dispatchNoPayload('USERS_EXPORT_MODAL_OPEN');
}

export function closeExportModal() {
	dispatchNoPayload('USERS_EXPORT_MODAL_CLOSE');
}

export function formatExcelExport(response) {
	return response.map((user) => {
		return {
			Name: user.name,
			Type: user.type,
			Phone: user.phone,
			Email: user.email,
			Organisation: user.organisation.name,
			Role: user.role.name,
			'Vehicle Groups': user.vehicleGroups.map((group) => group.name).join(', '),
		};
	});
}

export function exportData(data, fileName, extension) {
	if (extension === 'excel') {
		exportToExcelWithLogo(formatExcelExport(data), `${!fileName ? 'Users' : fileName}`, USERS_FILE_HEADER);
	} else {
		convertToPdf(formatExcelExport(data), fileName);
	}

	closeExportModal();
}

export function convertToPdf(data, fileName) {
	const doc = new jsPDF({ orientation: 'l', unit: 'mm', format: 'a4' });

	let col = ['Name', 'Type', 'Phone', 'Email', 'Organisation', 'Role', 'Vehicle Groups'];
	let rows = [];

	data.forEach((user) => {
		let temp = [];
		Object.keys(user).forEach((key, index) => {
			if (col[index] === key) temp[index] = user[key];
		});

		rows.push(temp);
	});

	let header = function (data) {
		doc.addImage(Logo, 'png', data.settings.margin.left, 10, 40, 14);
	};

	doc.autoTable(col, rows, {
		margin: { top: 25 },
		beforePageContent: header,
		theme: 'grid',
	});

	doc.save(`${!fileName ? 'Users' : fileName}`);
}

export function exportAll(param) {
	// dispatchNoPayload('EXPORTING_USERS_EXPORT_DATA');

	axios(EXPORT_API + '/users' + param, {
		responseType: 'arraybuffer',
		headers: {
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	})
		.then((response) => {
			// openExportModal();
			// dispatch('SET_USERS_EXPORT_DATA', createUsersDTO(response?.data?.data));
			// dispatchNoPayload('EXPORTED_USERS_EXPORT_DATA');
			// fileDownload(response.data, `Users.${fileExtension}`);

			const contentDisposition = response.headers['content-disposition'];
			const matches = /filename="([^"]+)"/.exec(contentDisposition);

			if (matches) {
				const fileName = matches[1];
				fileDownload(response?.data, fileName);
			}
		})
		.catch((error) => {
			handleError(error);
			/*datadogLogs.logger.error('User export api', {}, error);*/
			dispatchNoPayload('EXPORTED_USERS_EXPORT_DATA');
		});
}

// Verification
export function openSendVerificationModal() {
	dispatchNoPayload('USERS_SEND_VERIFICATION_MODAL_OPEN');
}

export function closeSendVerificationModal() {
	dispatchNoPayload('USERS_SEND_VERIFICATION_MODAL_CLOSE');
}

// Accordion
export function toggleAccordion(e, titleProps, current) {
	const { index } = titleProps;
	const { activeIndex } = {
		activeIndex: current,
	};
	const newIndex = activeIndex === index ? -1 : index;
	dispatchNoPayload('USERS_EDIT_MODAL_CLOSE');
	dispatch('USERS_TOGGLE_ACCORDION', newIndex);
}

// Dropdowns
export function getOrganisationsForDropdown(params) {
	return fetchOrganisations(params)
		.then((response) => {
			let organisation = createOrganisationDTO(response.data.data);
			if (organisation) {
				return createOrganisationDTO(response.data.data).map((organisation, index) => {
					return {
						key: index,
						value: organisation.id,
						text: organisation.name,
					};
				});
			} else return [];
		})
		.catch((error) => {
			handleError(error);
			/*datadogLogs.logger.error('User organisation dropdown api', {}, error);*/
			return [];
		});
}

export function getVehicleGroupsForDropdown(params) {
	return fetchVehicleGroups(params)
		.then((response) => {
			return createVehicleGroupDTO(response?.data?.data).map((vehicleGroup, index) => {
				return {
					key: index,
					value: vehicleGroup.id,
					text: vehicleGroup.name,
				};
			});
		})
		.catch((error) => {
			handleError(error);
			/*datadogLogs.logger.error('User vehicle group dropdown api', {}, error);*/
			return [];
		});
}

export function getVehiclesForDropdown(params) {
	return fetchVehicles(params)
		.then((response) => {
			return createVehicleDTO(response?.data?.data).map((vehicle, index) => {
				return {
					key: index,
					value: vehicle.id,
					text: vehicle.registrationNumber,
				};
			});
		})
		.catch((error) => {
			handleError(error);
			/*datadogLogs.logger.error('Users vehicle dropdown api', {}, error);*/
			return [];
		});
}

export function getGeofenceForDropdown(params) {
	return axios(GEOFENCES_API + params, {
		headers: {
			Authorization: 'Bearer ' + localStorage.getItem('token'),
			'Content-Type': 'application/json',
		},
	})
		.then((response) => {
			return createGeoFenceDTO(response?.data?.data).map((geofence, index) => {
				return {
					key: index,
					value: geofence.id,
					text: geofence.name,
				};
			});
		})
		.catch((error) => {
			handleError(error);
			return [];
		});
}

export function getDynamicRolesForDropdown(params = '') {
	return fetchRoles(params)
		.then((response) => {
			return createRolesDTO(response?.data?.data).map((vehicle, index) => {
				return {
					key: index,
					value: vehicle.id,
					text: vehicle.name,
				};
			});
		})
		.catch((error) => {
			handleError(error);
			/*datadogLogs.logger.error('User role vehicle api', {}, error);*/
			return [];
		});
}

export function getUserTypesForDropdown() {
	return [
		{
			key: 0,
			value: 'Default',
			text: 'Default',
		},
		{
			key: 1,
			value: 'Driver',
			text: 'Driver',
		},
		{
			key: 2,
			value: 'Parent',
			text: 'Parent',
		},
	];
}

export function getRolesForDropdown(params = '') {
	return fetchRoles(params)
		.then((response) => {
			return createRolesDTO(response?.data?.data).map((role, index) => {
				return {
					key: index,
					value: role.id,
					text: role.name,
				};
			});
		})
		.catch((error) => {
			handleError(error);
			/*datadogLogs.logger.error('Users role dropdown api ', {}, error);*/
			return [];
		});
}

export function openFilterModal() {
	dispatchNoPayload('USERS_FILTER_MODAL_OPEN');
}

export function closeFilterModal() {
	dispatchNoPayload('USERS_FILTER_MODAL_CLOSE');
}

// Connect to Store
function getPageNumberFromStore() {
	return {
		pageNumber: Store.getState().users.pageNumber,
		dataSizeLimit: Store.getState().ui.dataSizeLimit,
	};
}
