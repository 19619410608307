import React from 'react';
import { RESET_PASSWORD_API, USERS_API, VERIFIED_ASSETS_API } from '../../../../../logic/configs/api';
import BasicLoader from '../../../blocks/molecules/loader/BasicLoader';
import SearchBox from '../../../blocks/atoms/search-box/SearchBox';
import FooterPagination from '../../../blocks/molecules/pagination/Pagination';
import ToggleSwitch from '../../../blocks/molecules/toggle-switch/ToggleSwitch';
import RestoreModal from '../../../blocks/organisms/modals/users/RestoreModal';
import DeleteModal from '../../../blocks/organisms/modals/users/DeleteModal';
import PasswordModal from '../../../blocks/organisms/modals/users/PasswordModal';
import AddModal from '../../../blocks/organisms/modals/users/AddModal';
import ExportModal from '../../../blocks/organisms/modals/users/ExportModal';
import { CategoryContentMembrane } from '../../../styles/containers/CategoryContentMembrane';
import { CategoryHeader } from '../../../styles/containers/CategoryHeader';
import { Expander } from '../../../styles/place-holders/Expander';
import { CategoryMain } from '../../../styles/containers/CategoryMain';
import { AccordionHeader } from '../../../styles/containers/AccordionHeader';
import { AccordionMain } from '../../../styles/containers/AccordionMain';
import { CategoryFooter } from '../../../styles/containers/CategoryFooter';
import { AccordionMainContent } from '../../../styles/containers/AccordionMainContent';
import PlaceHolder from '../../../blocks/molecules/place-holder/PlaceHolder';
import VehicleGroupModal from '../../../blocks/organisms/modals/users/VehicleGroupModal';
import SendVerificationModal from '../../../blocks/organisms/modals/users/SendVerificationModal';
import TransitionLoader from '../../../blocks/molecules/loader/TransitionLoader';
import FilterModal from '../../../blocks/organisms/modals/users/FilterModal';
import {
	closeFilterModal,
	closeSendVerificationModal,
	exportAll,
	exportData,
	getOrganisationsForDropdown,
	getRolesForDropdown,
	getUserTypesForDropdown,
	getVehicleGroupsForDropdown,
	getVehiclesForDropdown,
	openFilterModal,
	openSendVerificationModal,
	refreshUsers,
	searchUsers,
	toggleAccordion,
} from '../../../../../logic/middleware/users';
import { dispatch, dispatchNoPayload } from '../../../../../logic/middleware/middleware';

import { generateSuccessMessage, handleError, showMessage } from '../../../../../logic/middleware/messaging';
import { logout } from '../../../../../logic/middleware/auth';
import { capitalizeWord, validateEmail, validateMobileNumber } from '../../../../../logic/utilities/validator';
import { connect } from 'react-redux';
import Store from '../../../../../data/store/store';

import { Actions, HeaderElement, Row, RowElement, UnVerifiedContent } from '../../../styles/Accordion';
import { Accordion, Button, Checkbox, Dropdown, Form, Icon, Input } from 'semantic-ui-react';
import { MessageType } from '../../../../../data/constants/message-type';
import VehicleModal from '../../../blocks/organisms/modals/users/VehicleModal';
import RemoveVehicleModal from '../../../blocks/organisms/modals/users/RemoveVehicleModal';
import accessSetter from '../../../../../logic/utilities/accessSetter';
import { trackMixpanelEvent } from '../../../../../logic/middleware/mixpanel';

import { FILE_TYPE_EXTENSIONS } from '../../../../../data/constants/file-types';
import { deleteData, patchData, postData } from '../../../../../logic/middleware/services/apiRequest';

/*import { datadogLogs } from '@datadog/browser-logs';*/
import styled from 'styled-components';
import ImportInfo from '../../../../common/ImportInfo/ImportInfo';
// import { CheckboxContainer } from '../../../styles/Containers';

export const Container = styled.section`
	.ui.toggle.checkbox input:checked ~ .box:before,
	.ui.toggle.checkbox input:checked ~ label:before {
		background-color: ${(props) => props.theme.positive} !important;
	}

	.ui.toggle.checkbox .box:before,
	.ui.toggle.checkbox label:before {
		background-color: ${(props) => props.theme.danger} !important;
	}
`;

class Users extends React.Component {
	user_id = '';
	timeoutId;
	// searchQuery = '';
	isSuperAdminAccess = localStorage.getItem('isSuperAdmin');

	deletedMode = false;
	selectAllActive = false;
	asset = '';
	assetType = null;
	sortOrder = '';
	activeSort = '';

	// fileTemplate = [
	// 	{ name: 'User Name', requestTitle: 'User Name', type: 'string' },
	// 	{ name: 'User Type', requestTitle: 'User Type', type: 'string' },
	// 	{ name: 'User Contact Number', requestTitle: 'User Contact Number', type: 'number' },
	// 	{ name: 'User email', requestTitle: 'User email', type: 'string' },
	// 	{ name: 'Organisation', requestTitle: 'Organisation', type: 'string' },
	// 	{ name: 'User Role', requestTitle: 'User Role', type: 'string' },
	// ];
	// SheetJSFT = ['xlsx', 'xls']
	// 	.map(function (x) {
	// 		return '.' + x;
	// 	})
	// 	.join(',');

	// inputData;

	activeFilters = {
		organisations: [],
		types: [],
		roles: [],
		email: null,
		phone: null,
	};

	constructor(props) {
		super(props);

		// this.fileInputRef = React.createRef();

		this.state = {
			initialLoading: true,
			organisations: [],
			vehicleGroups: [],
			vehicles: [],
			userTypes: getUserTypesForDropdown(),
			roles: [],
			email: null,
			phone: null,
			manageAccess: false,
			createAccess: false,
			readAccess: false,
			updateAccess: false,
			deleteAccess: false,
			importInfo: false,

			//Reset Password
			resetPasswordID: '',

			nameFA: null,
			userTypeFA: 'Default',
			mobileNumberFA: null,
			isEnabledSMSFA: true,
			emailIdFA: null,
			isEnabledEmailFA: true,
			isEnabledPushFA: true,
			canLoginFA: false,
			passwordFA: null,
			organisationFA: null,
			vehicleGroupFA: null,
			selectedRoleFA: null,

			nameFE: null,
			userTypeFE: null,
			mobileNumberFE: null,
			isEnabledSMSFE: null,
			emailFE: null,
			isEnabledEmailFE: null,
			isEnabledPushFE: null,
			canLoginFE: null,
			passwordFE: null,
			vehicleGroupsFE: null,
			vehicleFE: null,
			selectedRoleFE: null,
			// usersResponse: null,

			// Selection
			anyRowSelected: false,
			selectedRows: new Set(),

			filters: {
				organisations: [],
				types: [],
				roles: [],
				email: null,
				phone: null,
			},

			deletedMode: false,
			disableUpdateButton: true,

			fileName: 'Users',
			fileExtension: 'pdf',
			searchQuery: '',
		};

		this.toggleDeletedMode = this.toggleDeletedMode.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.add = this.add.bind(this);
		this.cleanAddOperation = this.cleanAddOperation.bind(this);
		this.export = this.export.bind(this);
		this.getFilteredUsers = this.getFilteredUsers.bind(this);
		this.update = this.update.bind(this);
		this.deleteUser = this.deleteUser.bind(this);
		this.restoreUser = this.restoreUser.bind(this);
		this.cleanEditOperation = this.cleanEditOperation.bind(this);
		this.cleanDeleteOperation = this.cleanDeleteOperation.bind(this);
		this.cleanRestoreOperation = this.cleanRestoreOperation.bind(this);
		this.handleDataSizeLimitChange = this.handleDataSizeLimitChange.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.cleanSelectedRows = this.cleanSelectedRows.bind(this);
		this.cleanSendVerificationOperation = this.cleanSendVerificationOperation.bind(this);

		this.handleExportSelection = this.handleExportSelection.bind(this);
		this.handleSelectAll = this.handleSelectAll.bind(this);
		this.resetUserPassword = this.resetUserPassword.bind(this);
		this.cleanResetPasswordOperation = this.cleanResetPasswordOperation.bind(this);
		this.sort = this.sort.bind(this);
		this.sendVerificationLink = this.sendVerificationLink.bind(this);
		// this.cleanImportOperation = this.cleanImportOperation.bind(this);

		// this.importDevices = this.importDevices.bind(this);

		this.onNameChange = this.onNameChange.bind(this);
		this.onUserTypeChange = this.onUserTypeChange.bind(this);
		this.onEmailChange = this.onEmailChange.bind(this);
		this.onShowPasswordIconClick = this.onShowPasswordIconClick.bind(this);
		this.onPasswordChange = this.onPasswordChange.bind(this);
		this.onPhoneChange = this.onPhoneChange.bind(this);
		this.onOrganisationChange = this.onOrganisationChange.bind(this);
		this.onVehicleGroupChange = this.onVehicleGroupChange.bind(this);
		this.onVehicleGroupUpdate = this.onVehicleGroupUpdate.bind(this);
		this.onRoleFAChange = this.onRoleFAChange.bind(this);
		this.onCanLoginFAChange = this.onCanLoginFAChange.bind(this);
		this.onCanLoginFEChange = this.onCanLoginFEChange.bind(this);
		this.cleanVGOperation = this.cleanVGOperation.bind(this);
		this.onPasswordFEChange = this.onPasswordFEChange.bind(this);
		this.onOrganisationFilterChange = this.onOrganisationFilterChange.bind(this);
		this.onTypeFilterChange = this.onTypeFilterChange.bind(this);
		this.onRoleFilterChange = this.onRoleFilterChange.bind(this);
		this.onEmailFilterChange = this.onEmailFilterChange.bind(this);
		this.onIsEnabledSMSFAChange = this.onIsEnabledSMSFAChange.bind(this);
		this.onIsEnabledEmailFAChange = this.onIsEnabledEmailFAChange.bind(this);
		this.onIsEnabledPushFAChange = this.onIsEnabledPushFAChange.bind(this);

		this.onPasswordSubmit = this.onPasswordSubmit.bind(this);
		this.updateVehicleGroup = this.updateVehicleGroup.bind(this);
		this.onRoleFEChange = this.onRoleFEChange.bind(this);
		this.onVehicleUpdate = this.onVehicleUpdate.bind(this);
		// this.handleInputFile = this.handleInputFile.bind(this);
		// this.importUsers = this.importUsers.bind(this);
		this.fileExtensionChange = this.fileExtensionChange.bind(this);
		this.fileNameChange = this.fileNameChange.bind(this);
		this.manageAccessSetter = this.manageAccessSetter.bind(this);
		this.onNameChangeFE = this.onNameChangeFE.bind(this);
		this.handleSearchQuery = this.handleSearchQuery.bind(this);
		this.onPhoneFilterChange = this.onPhoneFilterChange.bind(this);
		// this.handleCancelClick = this.handleCancelClick.bind(this);

		this.handleOpenImportInfo = this.handleOpenImportInfo.bind(this);
		this.handleCloseImportInfo = this.handleCloseImportInfo.bind(this);
	}

	manageAccessSetter(rule, action) {
		this.setState({ [action]: rule });
	}

	componentDidMount() {
		this.parentName = this.props.location?.state?.parentName;

		if (this.parentName) {
			dispatch('UPDATE_SEARCH_QUERY', this.parentName);
			this.handleSearch({ target: { value: this.parentName } });
			return;
		}
		if (this.props.searchQuery) {
			// this.setState({ searchQuery: this.props.searchQuery });
			dispatch('UPDATE_SEARCH_QUERY', this.props.searchQuery);
			this.handleSearch({ target: { value: this.props.searchQuery } });
			return;
		}

		trackMixpanelEvent('Users', { Action: 'View' });
		const permissions = JSON.parse(localStorage.getItem('permissions'));
		const usersPermissions = permissions?.filter((permission) => permission.subject === 'User');
		if (usersPermissions) {
			for (const userPrm of usersPermissions) {
				accessSetter(userPrm?.action, this.manageAccessSetter);
			}
		}
		getRolesForDropdown(`?dropdown=true`).then((response) => {
			this.setState({
				roles: response,
			});
		});

		// getRolesForDropdown(`?dropdown=true`).then((response) => {
		// 	this.setState({
		// 		roles: response,
		// 	});
		//
		// });

		if (this.props.filters) {
			this.activeFilters = this.state.filters;
			this.activeFilters.organisations = this.props.filters.organisations;
			this.activeFilters.email = this.props.filters.email;
			this.activeFilters.phone = this.props.filters.phone;
			this.getFilteredUsers();
			return;
		}

		refreshUsers();
	}

	// Fetch
	getFilteredUsers(activePage = 1, resetAccordion = true) {
		if (resetAccordion) {
			dispatch('USERS_TOGGLE_ACCORDION', -1);
		}

		let query = '';

		if (this.state.searchQuery !== '') {
			query = query + searchUsers(this.state.searchQuery);
		}

		if (this.activeFilters.organisations?.length) {
			query =
				query + this.activeFilters.organisations.map((organisation) => `&organisations[]=${organisation}`).join('');
		}

		if (this.activeFilters?.email) {
			if (this.activeFilters.email.includes(1)) {
				query = query + `&emailVerified=true`;
			} else {
				query = query + `&emailVerified`;
			}
		}

		if (this.activeFilters.phone) {
			if (this.activeFilters.phone.includes(1)) {
				query = query + `&phoneVerified=true`;
			} else {
				query = query + `&phoneVerified`;
			}
		}

		if (this.activeFilters.types?.length) {
			query = query + this.activeFilters.types.map((type) => `&type[]=${type}`).join('');
		}

		if (this.activeFilters.roles?.length) {
			query = query + this.activeFilters.roles.map((role) => `&roles[]=${role}`).join('');
		}

		if (this.activeSort !== '' && this.sortOrder !== '') {
			if (this.sortOrder === 'asc') {
				query = query + `&sortBy=${this.activeSort}&orderBy=${this.sortOrder}`;
			} else {
				query = query + `&sortBy=${this.activeSort}&orderBy=${this.sortOrder}`;
			}
		}

		if (this.deletedMode) {
			query = query + `&deleted=true`;
		}

		refreshUsers(query, activePage);
	}

	handleSearch(e) {
		let value = e.target.value;

		if (this.parentName) {
			this.setState({ searchQuery: this.parentName });
			dispatch('UPDATE_SEARCH_QUERY', this.parentName);
		} else {
			dispatch('UPDATE_SEARCH_QUERY', value);
			this.setState({ searchQuery: value });
		}

		if (this.timeoutId) {
			clearTimeout(this.timeoutId);
		}

		this.timeoutId = setTimeout(() => {
			this.getFilteredUsers();
			this.cleanSelectedRows();
		}, 500);
	}

	// handleInputFile(e) {
	// 	const files = e.target.files;
	// 	if (files && files[0] && EXCEL_FILE_TYPES.indexOf(files[0].type) !== -1) {
	// 		this.inputData = files[0];
	// 		dispatch('USERS_IMPORT_MODAL_OPEN');
	// 	} else generateErrorMessage('Only excel file types are allowed.');
	// }

	// async importUsers(response) {
	// 	const usersData = response.data;
	//
	// 	const body = usersData.map((data) => {
	// 		return {
	// 			name: data[0],
	// 			type: data[1],
	// 			organisation: {
	// 				id: data.organisation.id,
	// 				organisation: data[4],
	// 			},
	// 			phone: data[2],
	// 			role: {
	// 				id: data.role.id,
	// 				role: data[5],
	// 			},
	// 			email: data[3],
	// 		};
	// 	});
	// 	postData(USERS_API_BULK, '', body)
	// 		.then((res) => {
	// 			this.setState({ usersResponse: res.data }, () => {
	// 				handleSuccess();
	// 				response.success();
	// 			});
	//
	// 			if (res.status === 206) {
	// 				generateSuccessMessage(res.data, 'Partial Success');
	// 			} else {
	// 				generateSuccessMessage(res.data, 'Created');
	// 			}
	// 		})
	// 		.catch((err) => {
	// 			handleError(err);
	// 			datadogLogs.logger.error('User import api', {}, err);
	// 			response.error();
	// 		});
	// }

	toggleDeletedMode() {
		this.deletedMode = !this.deletedMode;
		this.setState({
			deletedMode: !this.state.deletedMode,
		});

		this.getFilteredUsers();
		this.cleanSelectedRows();
	}

	// cleanImportOperation() {
	// 	dispatch('USERS_IMPORT_MODAL_CLOSE');
	// 	if (this.fileInputRef && this.fileInputRef.current) {
	// 		this.fileInputRef.current.value = '';
	// 	}
	// 	this.setState({ usersResponse: null });
	// }

	// Export
	fileNameChange(e) {
		this.setState({ fileName: e.target.value });
	}

	fileExtensionChange(e, { value }) {
		this.setState({ fileExtension: value });
	}

	handleExportSelection(checkedData, id) {
		let selectedRows = this.state.selectedRows;

		if (selectedRows.has(id)) {
			selectedRows.delete(id);
		} else selectedRows.add(id);

		this.setState({
			selectedRows: selectedRows,
			anyRowSelected: !!selectedRows.size,
		});
	}

	handleSelectAll(checked) {
		let selectedRows = new Set();

		if (checked) {
			this.selectAllActive = true;
			this.props.users.forEach((user) => selectedRows.add(user.id));
			this.setState({
				selectedRows: selectedRows,
				anyRowSelected: !!selectedRows.size,
			});
		} else {
			this.selectAllActive = false;
			this.setState({
				selectedRows: new Set(),
				anyRowSelected: !!selectedRows.size,
			});
		}
	}

	export() {
		const { users } = this.props;

		let usersToExport = [];
		users.forEach((user) => {
			if (this.state.selectedRows.has(user.id)) {
				usersToExport.push(user);
			}
		});

		return usersToExport;
	}

	getFilterListQuery() {
		let query = '';

		query = query + `?fileType=${this.state.fileExtension}`;

		if (this.state.searchQuery !== '') {
			query = query + searchUsers(this.state.searchQuery);
		}

		if (this.activeFilters?.organisations?.length) {
			query =
				query + this.activeFilters.organisations.map((organisation) => `&organisations[]=${organisation}`).join('');
		}

		if (this.activeFilters.email) {
			if (this.activeFilters.email.includes(1)) {
				query = query + `&emailVerified=true`;
			} else {
				query = query + `&emailVerified`;
			}
		}
		if (this.activeFilters.phone) {
			if (this.activeFilters.phone.includes(1)) {
				query = query + `&phoneVerified=true`;
			} else {
				query = query + `&phoneVerified`;
			}
		}
		if (this.activeFilters?.types?.length) {
			query = query + this.activeFilters.types.map((type) => `&type[]=${type}`).join('');
		}

		if (this.activeFilters?.roles?.length) {
			query = query + this.activeFilters.roles.map((roles) => `&roles[]=${roles}`).join('');
		}

		if (this.deletedMode) {
			query = query + `&deleted=true`;
		}

		if (this.activeSort !== '' && this.sortOrder !== '') {
			if (this.sortOrder === 'asc') {
				query = query + `&sortBy=${this.activeSort}&orderBy=${this.sortOrder}`;
			} else {
				query = query + `&sortBy=${this.activeSort}&orderBy=${this.sortOrder}`;
			}
		}

		return query;
	}

	onNameChange(e, { value }) {
		const capitalizedValue = capitalizeWord(value);
		this.setState({
			nameFA: capitalizedValue,
		});
	}

	onUserTypeChange(e, { value }) {
		this.setState({
			userTypeFA: value,
		});
	}

	onEmailChange(e) {
		this.setState({
			emailIdFA: e.target.value,
		});
	}

	onShowPasswordIconClick() {
		this.props.passwordVisible
			? Store.dispatch({ type: 'USERS_PASSWORD_NOT_VISIBLE' })
			: Store.dispatch({ type: 'USERS_PASSWORD_VISIBLE' });
	}

	onPasswordChange(e, { value }) {
		this.setState({
			passwordFA: value,
		});
	}

	onPhoneChange(e) {
		this.setState({
			mobileNumberFA: e.target.value,
		});
	}

	onOrganisationChange(e, { value }) {
		this.setState({
			organisationFA: value,
			vehicleGroupFA: null,
			selectedRoleFA: '',
		});

		getVehicleGroupsForDropdown(`?organisations[]=${value}&dropdown=true`).then((response) => {
			this.setState({ vehicleGroups: response });
		});
	}

	onVehicleGroupChange(e, { value }) {
		this.setState({
			vehicleGroupFA: value,
		});
	}

	onRoleFAChange(e, { value }) {
		this.setState({
			selectedRoleFA: value,
		});
	}

	onCanLoginFAChange(e, { checked }) {
		this.setState({
			canLoginFA: checked,
		});
	}

	onIsEnabledSMSFAChange(e, { checked }) {
		this.setState({
			isEnabledSMSFA: checked,
		});
	}

	onIsEnabledEmailFAChange(e, { checked }) {
		this.setState({
			isEnabledEmailFA: checked,
		});
	}

	onIsEnabledPushFAChange(e, { checked }) {
		this.setState({
			isEnabledPushFA: checked,
		});
	}

	// Add
	add() {
		const {
			nameFA,
			userTypeFA,
			mobileNumberFA,
			isEnabledSMSFA,
			emailIdFA,
			isEnabledEmailFA,
			isEnabledPushFA,
			passwordFA,
			organisationFA,
			vehicleGroupFA,
			selectedRoleFA,
			canLoginFA,
		} = this.state;
		let vehicleGroups = [];

		if (!nameFA) {
			showMessage('Name should not be empty', MessageType.Error);
			return;
		}

		if (!organisationFA) {
			showMessage('Organisation should not be empty', MessageType.Error);
			return;
		}

		if (vehicleGroupFA !== null) {
			vehicleGroups = vehicleGroupFA.map((group) => {
				return {
					id: group,
				};
			});
		}

		let queryObj = {};

		if (nameFA) queryObj = { ...queryObj, name: nameFA.trim() };
		if (userTypeFA) queryObj = { ...queryObj, type: userTypeFA };
		if (mobileNumberFA) queryObj = { ...queryObj, phone: mobileNumberFA.trim() };
		if (emailIdFA) queryObj = { ...queryObj, email: emailIdFA.trim() };
		if (passwordFA) queryObj = { ...queryObj, password: passwordFA.trim() };

		if (selectedRoleFA) {
			queryObj = { ...queryObj, role: { id: selectedRoleFA } };
		}

		queryObj = { ...queryObj, isEnabledSMS: isEnabledSMSFA };
		queryObj = { ...queryObj, isEnabledEmail: isEnabledEmailFA };
		queryObj = { ...queryObj, isEnabledPush: isEnabledPushFA };

		queryObj = { ...queryObj, isLoginEnabled: canLoginFA };

		if (organisationFA)
			queryObj = {
				...queryObj,
				organisation: {
					id: organisationFA,
				},
			};

		if (vehicleGroups.length) queryObj = { ...queryObj, vehicleGroups: vehicleGroups };

		if (emailIdFA || mobileNumberFA) {
			dispatchNoPayload('USERS_ADDING');
			// axios
			// 	.post(USERS_API, queryObj, {
			// 		headers: {
			// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
			// 			'Content-Type': 'application/json',
			// 		},
			// 	})
			postData(USERS_API, '', queryObj)
				.then((response) => {
					dispatchNoPayload('USERS_ADDED');
					generateSuccessMessage(response);
					this.getFilteredUsers(this.props.pageNumber);
					this.cleanAddOperation();
				})
				.catch((error) => {
					dispatchNoPayload('USERS_ADDED');
					/*datadogLogs.logger.error('User add api', {}, error);*/
					handleError(error);
				});
		} else {
			showMessage('Please enter either email or phone.', MessageType.Error);
		}
	}

	cleanAddOperation() {
		dispatchNoPayload('USERS_ADD_MODAL_CLOSE');
		dispatchNoPayload('USERS_PASSWORD_NOT_VISIBLE');

		this.setState({
			nameFA: null,
			userTypeFA: 'Default',
			emailIdFA: null,
			mobileNumberFA: null,
			isEnabledSMSFA: true,
			isEnabledEmailFA: true,
			isEnabledPushFA: true,
			canLoginFA: false,
			passwordFA: null,
			organisationFA: null,
			vehicleGroupFA: null,
			organisations: [],
			vehicleGroups: [],
			selectedRoleFA: null,
		});

		this.user_id = '';
	}

	// Update
	update(id) {
		const {
			nameFE,
			userTypeFE,
			mobileNumberFE,
			isEnabledSMSFE,
			emailFE,
			isEnabledEmailFE,
			isEnabledPushFE,
			selectedRoleFE,
			canLoginFE,
			passwordFE,
		} = this.state;

		let queryObj = {};
		if (nameFE) queryObj = { ...queryObj, name: nameFE.trim() };
		if (userTypeFE) queryObj = { ...queryObj, type: userTypeFE };
		if (mobileNumberFE) queryObj = { ...queryObj, phone: mobileNumberFE.trim() };
		if (isEnabledSMSFE !== null) queryObj = { ...queryObj, isEnabledSMS: isEnabledSMSFE };
		if (emailFE) queryObj = { ...queryObj, email: emailFE.trim() };
		if (isEnabledEmailFE !== null) queryObj = { ...queryObj, isEnabledEmail: isEnabledEmailFE };
		if (isEnabledPushFE !== null) queryObj = { ...queryObj, isEnabledPush: isEnabledPushFE };
		if (selectedRoleFE !== null) queryObj = { ...queryObj, role: { id: selectedRoleFE } };
		if (canLoginFE !== null) queryObj = { ...queryObj, isLoginEnabled: canLoginFE };
		if (passwordFE) queryObj = { ...queryObj, password: passwordFE.trim() };

		dispatchNoPayload('USERS_UPDATING');

		// axios
		// 	.patch(USERS_API + `/${id}`, queryObj, {
		// 		headers: {
		// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 		},
		// 	})
		patchData(USERS_API + `/${id}`, queryObj)
			.then((response) => {
				dispatchNoPayload('USERS_UPDATED');
				generateSuccessMessage(response);
				this.getFilteredUsers(this.props.pageNumber, false);
				this.cleanEditOperation();
			})
			.catch((error) => {
				dispatchNoPayload('USERS_UPDATED');
				handleError(error);
			});
	}

	onRoleFEChange(e, { value }) {
		this.setState({
			selectedRoleFE: value,
		});
	}

	onNameChangeFE(e, { value }) {
		const capitalizedValue = capitalizeWord(value);
		this.setState({
			nameFE: capitalizedValue,
		});
	}

	onCanLoginFEChange(data) {
		this.setState({
			canLoginFE: data.checked,
			disableUpdateButton: false,
		});

		if (!data.checked) {
			this.setState({
				passwordFE: null,
			});
		}
	}

	onPasswordFEChange(e, { value }) {
		this.setState({
			passwordFE: value,
		});
	}

	cleanEditOperation() {
		dispatchNoPayload('USERS_EDIT_MODAL_CLOSE');

		this.setState({
			nameFE: null,
			userTypeFE: null,
			mobileNumberFE: null,
			isEnabledSMSFE: null,
			emailFE: null,
			isEnabledEmailFE: null,
			isEnabledPushFE: null,
			canLoginFE: null,
			passwordFE: null,
			vehicleGroupsFE: null,
			selectedRoleFE: null,
		});
	}

	// Delete
	deleteUser(id) {
		dispatchNoPayload('USERS_DELETING');
		// axios
		// 	.delete(USERS_API + `/${id}`, {
		// 		headers: {
		// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 		},
		// 	})
		deleteData(USERS_API, `/${id}`)
			.then((response) => {
				dispatchNoPayload('USERS_DELETED');
				generateSuccessMessage(response);
				this.getFilteredUsers(this.props.pageNumber);
				this.cleanDeleteOperation();
			})
			.catch((error) => {
				dispatchNoPayload('USERS_DELETED');
				handleError(error);
			});
	}

	cleanDeleteOperation() {
		closeSendVerificationModal();
		dispatchNoPayload('USERS_DELETE_MODAL_CLOSE');

		this.user_id = '';
	}

	sendVerificationLink() {
		dispatchNoPayload('USERS_VERIFICATION_LINK_SENDING');
		// axios
		// 	.post(
		// 		VERIFIED_ASSETS_API,
		// 		{ asset: this.asset, assetType: this.assetType },
		// 		{
		// 			headers: {
		// 				Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 			},
		// 		}
		// 	)
		postData(VERIFIED_ASSETS_API, '', { asset: this.asset, assetType: this.assetType })
			.then((response) => {
				dispatchNoPayload('USERS_VERIFICATION_LINK_SENDED');
				generateSuccessMessage(response, 'SUCCESS');
				dispatch('USERS_TOGGLE_ACCORDION', -1);
				// this.getFilteredUsers(this.props.pageNumber, false);
				this.cleanSendVerificationOperation();
			})
			.catch((error) => {
				dispatchNoPayload('USERS_VERIFICATION_LINK_SENDED');
				/*datadogLogs.logger.error('User email verification api', {}, error);*/
				handleError(error);
			});
	}

	cleanSendVerificationOperation() {
		dispatchNoPayload('USERS_SEND_VERIFICATION_MODAL_CLOSE');

		this.asset = '';
		this.assetType = null;
	}

	// Reset Password
	resetUserPassword(id) {
		let query = {
			password: this.password,
		};
		dispatchNoPayload('USERS_PASSWORD_SUBMITING');
		// axios
		// 	.post(RESET_PASSWORD_API + `/${id}/change-password`, query, {
		// 		headers: {
		// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 			'Content-Type': 'application/json',
		// 		},
		// 	})
		postData(RESET_PASSWORD_API, `/${id}/change-password`, query)
			.then((response) => {
				dispatchNoPayload('USERS_PASSWORD_SUBMITED');
				generateSuccessMessage(response);
				if (localStorage.getItem('userId') === id) {
					logout();
				} else {
					this.getFilteredUsers(this.props.pageNumber, false);
					this.cleanResetPasswordOperation();
				}
			})
			.catch((error) => {
				dispatchNoPayload('USERS_PASSWORD_SUBMITED');
				/*datadogLogs.logger.error('User reset password api', {}, error);*/
				handleError(error);
			});
	}

	cleanResetPasswordOperation() {
		dispatchNoPayload('USERS_PASSWORD_MODAL_CLOSE');
		dispatchNoPayload('USERS_PASSWORD_NOT_VISIBLE');

		this.password = '';
	}

	onPasswordSubmit(e) {
		this.password = e.target.value;
	}

	// Restore
	restoreUser(id) {
		dispatchNoPayload('USERS_RESTORING');
		// axios
		// 	.post(
		// 		USERS_API + `/${id}/restore`,
		// 		{},
		// 		{
		// 			headers: {
		// 				Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 			},
		// 		}
		// 	)
		postData(USERS_API, `/${id}/restore`)
			.then((response) => {
				dispatchNoPayload('USERS_RESTORED');
				generateSuccessMessage(response, 'User Restored');
				this.getFilteredUsers(this.props.pageNumber);

				this.cleanRestoreOperation();
			})
			.catch((error) => {
				handleError(error);
				/*datadogLogs.logger.error('User restore api', {}, error);*/
				dispatchNoPayload('USERS_RESTORED');
			});
	}

	cleanRestoreOperation() {
		dispatchNoPayload('USERS_RESTORE_MODAL_CLOSE');

		this.user_id = '';
	}

	// Vehicle Group Update
	updateVehicleGroup(id) {
		let query = {};
		if (this.state.vehicleGroupsFE) {
			query = {
				...query,
				vehicleGroups: this.state.vehicleGroupsFE.map((group) => {
					return {
						id: group,
					};
				}),
			};
		}
		dispatchNoPayload('USERS_UPDATING');

		// axios
		// 	.patch(USERS_API + `/${id}`, query, {
		// 		headers: {
		// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 		},
		// 	})
		patchData(USERS_API + `/${id}`, query)
			.then((response) => {
				dispatchNoPayload('USERS_UPDATED');
				generateSuccessMessage(response);
				this.getFilteredUsers(this.props.pageNumber, false);
				this.cleanVGOperation();
			})
			.catch((error) => {
				dispatchNoPayload('USERS_UPDATED');
				/*datadogLogs.logger.error('User vehicle group update API', {}, error);*/
				handleError(error);
			});
	}

	cleanVGOperation() {
		dispatchNoPayload('USERS_VG_MODAL_CLOSE');

		this.setState({ vehicleGroupsFE: [], vehicleGroups: [] });
		this.user_id = '';
	}

	onVehicleGroupUpdate(e, { value }) {
		this.setState({ vehicleGroupsFE: value });
	}

	removeVehicle(id) {
		dispatchNoPayload('USERS_UPDATING');

		// axios
		// 	.delete(USERS_API + `/${id}/vehicle`, {
		// 		headers: {
		// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 		},
		// 	})
		deleteData(USERS_API, `/${id}/vehicle`)
			.then((response) => {
				dispatchNoPayload('USERS_UPDATED');
				generateSuccessMessage(response);
				this.getFilteredUsers(this.props.pageNumber, false);
				this.cleanRemoveVehicleOperation();
			})
			.catch((error) => {
				dispatchNoPayload('USERS_UPDATED');
				/*datadogLogs.logger.error('User remove vehicle api', {}, error);*/
				handleError(error);
			});
	}

	cleanRemoveVehicleOperation() {
		dispatchNoPayload('USERS_REMOVE_VEHICLE_MODAL_CLOSE');

		this.user_id = '';
	}

	// Vehicle Update
	updateVehicle(id) {
		let query = {};
		if (this.state.vehicleFE) {
			query = {
				...query,
				driverVehicle: { id: this.state.vehicleFE },
			};
		}
		dispatchNoPayload('USERS_UPDATING');

		// axios
		// 	.patch(USERS_API + `/${id}`, query, {
		// 		headers: {
		// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 		},
		// 	})
		patchData(USERS_API + `/${id}`, query)
			.then((response) => {
				dispatchNoPayload('USERS_UPDATED');
				generateSuccessMessage(response);
				this.getFilteredUsers(this.props.pageNumber, false);
				this.cleanVehicleOperation();
			})
			.catch((error) => {
				dispatchNoPayload('USERS_UPDATED');
				handleError(error);
			});
	}

	cleanVehicleOperation() {
		dispatchNoPayload('USERS_VEHICLE_MODAL_CLOSE');

		this.setState({ vehicleFE: null });
		this.user_id = '';
	}

	onVehicleUpdate(e, { value }) {
		this.setState({ vehicleFE: value });
	}

	sort(param, activeSort, sortOrder) {
		if (!param) {
			this.activeSort = param;
			this.sortOrder = '';
		} else {
			if (activeSort !== param) {
				this.sortOrder = 'asc';
				this.activeSort = param;
			} else {
				if (sortOrder === 'asc') {
					this.sortOrder = 'desc';
				} else {
					this.sortOrder = 'asc';
				}
			}
		}

		dispatch('USERS_SORT_ORDER', this.sortOrder);
		dispatch('USERS_ACTIVE_ORDER', this.activeSort);
		this.getFilteredUsers(this.props.pageNumber);
	}

	onOrganisationFilterChange(e, { value }) {
		// this.setState({
		// 	filters: { ...this.state.filters, organisations: value },
		// });
		dispatch('ACTIVE_FILTER', { ...this.props.filters, organisations: value });
	}

	onTypeFilterChange(e, { value }) {
		this.setState({
			filters: { ...this.state.filters, types: value },
		});
	}

	onRoleFilterChange(e, { value }) {
		this.setState({
			filters: { ...this.state.filters, roles: value },
		});
	}

	onEmailFilterChange(e, { value }) {
		// this.setState({
		// 	filters: { ...this.state.filters, email: value },
		// });
		dispatch('ACTIVE_FILTER', { ...this.props.filters, email: value });
	}

	onPhoneFilterChange(e, { value }) {
		// this.setState({
		// 	filters: { ...this.state.filters, phone: value },
		// });
		dispatch('ACTIVE_FILTER', { ...this.props.filters, phone: value });
	}

	// Pagination
	handleDataSizeLimitChange() {
		this.getFilteredUsers();
	}

	handlePageChange(activePage) {
		this.getFilteredUsers(activePage);
		this.cleanSelectedRows();
	}

	cleanSelectedRows() {
		this.setState({
			anyRowSelected: false,
			selectedRows: new Set(),
		});
	}

	componentDidUpdate(prevProps) {
		if (prevProps.users !== this.props.users) {
			if (this.selectAllActive) this.handleSelectAll(true);
		}

		if (prevProps.pageNumber !== this.props.pageNumber) {
			document.getElementById('AccordionMain').scrollTo(0, 0);
		}

		if (prevProps.loading !== this.props.loading) {
			if (!this.props.loading) {
				this.setState({
					initialLoading: false,
				});
			}
		}
	}

	componentWillUnmount() {
		// this.fileTemplate = [];
		// this.inputData = '';
		dispatchNoPayload('USERS_UNMOUNT');
	}

	handleSearchQuery(value) {
		dispatch('UPDATE_SEARCH_QUERY', value);
		this.setState({ searchQuery: value });
	}

	handleOpenImportInfo() {
		this.setState({
			importInfo: true,
		});
	}

	handleCloseImportInfo() {
		this.setState({
			importInfo: false,
		});
	}

	render() {
		return (
			<CategoryContentMembrane>
				<CategoryHeader>
					<SearchBox
						placeholder={'Search in users'}
						searchQuery={this.state.searchQuery}
						value={this.props.searchQuery}
						clearSearchIconClick={() => {
							this.setState({ searchQuery: '' }, () => {
								this.getFilteredUsers(this.props.pageNumber);
								this.parentName = '';
							});
							dispatch('UPDATE_SEARCH_QUERY', '');
						}}
						// value={this.props.searchQuery}
						handleSearchQuery={this.handleSearchQuery}
						// disabled={!this.state.searchQuery && (this.props.loading || this.props.exportingData)}
						handleSearch={this.handleSearch}
					/>
					<Button
						icon
						primary
						disabled={this.props.loading || this.props.exportingData}
						title="Refresh"
						onClick={() => this.getFilteredUsers(this.props.pageNumber)}>
						<Icon name="refresh" />
					</Button>
					<Expander />
					<Button
						icon
						primary={
							!(
								this.activeFilters.organisations.length ||
								this.activeFilters.types.length ||
								this.activeFilters.roles.length ||
								this.activeFilters.email ||
								this.activeFilters.phone
							)
						}
						disabled={
							this.props.loading || this.props.exportingData
							//|| !(this.state.manageAccess || this.state.readAccess)
						}
						color={
							this.activeFilters.organisations.length ||
							this.activeFilters.types.length ||
							this.activeFilters.roles.length ||
							this.activeFilters.email ||
							this.activeFilters.phone
								? 'orange'
								: null
						}
						title="Filter Users"
						onClick={() => {
							openFilterModal();
							if (this.isSuperAdminAccess === 'true')
								getOrganisationsForDropdown(`?dropdown=true`).then((response) => {
									this.setState({ organisations: response });
								});
						}}>
						<Icon name="filter" />
					</Button>
					{/*Add*/}
					{!this.state.deletedMode && (
						<Button
							primary
							onClick={() => {
								trackMixpanelEvent('Users', { Action: 'Add' });
								dispatchNoPayload('USERS_ADD_MODAL_OPEN');
								getOrganisationsForDropdown(`?dropdown=true`).then((response) => {
									this.setState({
										organisations: response,
									});
								});
							}}
							disabled={
								this.props.loading || this.props.exportingData
								// || !(this.state.manageAccess || this.state.createAccess)
							}>
							Add
						</Button>
					)}

					<Button
						primary
						title="Import Users from file"
						disabled={this.props.loading || this.props.exportingData}
						onClick={this.handleOpenImportInfo}
						// onClick={() => this.fileInputRef.current.click()}
						// onClick={() => {
						// 	this.setState(
						// 		{
						// 			importInfo: true,
						// 		});
						// }}
					>
						Import
					</Button>

					{this.state.importInfo && (
						<ImportInfo
							users={{
								UserimportInfo: this.state.importInfo,
								onClose: this.handleCloseImportInfo,
								fileTemplate: [
									{ name: 'User Name', requestTitle: 'User Name', type: 'string' },
									{ name: 'User Type', requestTitle: 'User Type', type: 'string' },
									{ name: 'User Contact Number', requestTitle: 'User Contact Number', type: 'number' },
									{ name: 'User Email', requestTitle: 'User email', type: 'string' },
									{ name: 'Organisation', requestTitle: 'Organisation', type: 'string' },
									{ name: 'User Role', requestTitle: 'User Role', type: 'string' },
								],
							}}
						/>
					)}

					{/*<input*/}
					{/*	style={{ display: 'none' }}*/}
					{/*	id="file"*/}
					{/*	ref={this.fileInputRef}*/}
					{/*	type="file"*/}
					{/*	className="form-control"*/}
					{/*	accept={this.SheetJSFT}*/}
					{/*	onChange={this.handleInputFile}*/}
					{/*/>*/}

					{/*Export*/}

					{/*<Button*/}
					{/*	primary*/}
					{/*	disabled={*/}
					{/*		!this.state.anyRowSelected || this.props.loading || !this.props.users.length || this.props.exportingData*/}
					{/*	}*/}
					{/*	onClick={() => {*/}
					{/*		openExportModal();*/}
					{/*		dispatch('SET_USERS_EXPORT_DATA', this.export());*/}
					{/*	}}>*/}
					{/*	Export*/}
					{/*</Button>*/}

					{/*Export All*/}

					<Button.Group>
						<Button
							primary
							disabled={this.props.loading || !this.props.users.length || this.props.exportingData}
							loading={this.props.exportingData}
							onClick={() => exportAll(this.getFilterListQuery())}>
							Export
						</Button>
						<Dropdown
							className="button icon"
							style={{ background: '#ffffff', color: '#1b3c71', borderColor: '#1b3c71' }}
							floating
							value={this.state.fileExtension}
							options={FILE_TYPE_EXTENSIONS}
							// defaultValue={this.state.fileExtension}
							onChange={this.fileExtensionChange}
							trigger={<></>}
						/>
					</Button.Group>
					{this.isSuperAdminAccess === 'true' && (
						<ToggleSwitch
							label="Deleted Mode"
							disabled={this.props.loading || this.props.exportingData}
							checked={this.deletedMode}
							action={this.toggleDeletedMode}
						/>
					)}
				</CategoryHeader>

				<CategoryMain>
					<AccordionHeader background={this.deletedMode}>
						{/*<HeaderElement*/}
						{/*	width="3%"*/}
						{/*	disabled={this.props.loading || this.props.exportingData || !this.props.users.length}>*/}
						{/*	<CheckboxContainer>*/}
						{/*		<Checkbox*/}
						{/*			checked={this.props.users.length !== 0 && this.state.selectedRows.size === this.props.users.length}*/}
						{/*			indeterminate={*/}
						{/*				this.state.selectedRows.size !== 0 && this.state.selectedRows.size !== this.props.users.length*/}
						{/*			}*/}
						{/*			onClick={(e, data) => {*/}
						{/*				this.handleSelectAll(data.checked);*/}
						{/*			}}*/}
						{/*		/>*/}
						{/*	</CheckboxContainer>*/}
						{/*</HeaderElement>*/}
						<HeaderElement
							width="5%"
							inActive={true}
							disabled={this.props.loading || this.props.exportingData || !this.props.users.length}
							onClick={() => {
								this.sort('', '', '');
							}}>
							No.
						</HeaderElement>
						<HeaderElement
							width="12%"
							onClick={() => {
								this.sort('name', this.props.activeSort, this.props.sortOrder);
							}}
							disabled={this.props.loading || this.props.exportingData || !this.props.users.length}
							activeSort={this.activeSort === 'name'}>
							{this.props.activeSort === 'name' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Name
						</HeaderElement>
						<HeaderElement
							width="8%"
							onClick={() => {
								this.sort('type', this.props.activeSort, this.props.sortOrder);
							}}
							disabled={this.props.loading || this.props.exportingData || !this.props.users.length}
							activeSort={this.activeSort === 'type'}>
							{this.props.activeSort === 'type' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Type
						</HeaderElement>
						<HeaderElement
							width="10%"
							disabled={this.props.loading || this.props.exportingData || !this.props.users.length}>
							Phone
						</HeaderElement>
						<HeaderElement
							width="14%"
							disabled={this.props.loading || this.props.exportingData || !this.props.users.length}>
							Email
						</HeaderElement>
						<HeaderElement
							width="14%"
							onClick={() => {
								this.sort('organisation', this.props.activeSort, this.props.sortOrder);
							}}
							disabled={this.props.loading || this.props.exportingData || !this.props.users.length}
							activeSort={this.activeSort === 'organisation'}>
							{this.props.activeSort === 'organisation' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Organisation
						</HeaderElement>
						<HeaderElement
							width="10%"
							onClick={() => {
								this.sort('role', this.props.activeSort, this.props.sortOrder);
							}}
							disabled={this.props.loading || this.props.exportingData || !this.props.users.length}
							activeSort={this.activeSort === 'role'}>
							{this.props.activeSort === 'role' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Role
						</HeaderElement>
						<HeaderElement
							width="14%"
							onClick={() => {
								this.sort('vehicleGroups', this.props.activeSort, this.props.sortOrder);
							}}
							disabled={this.props.loading || this.props.exportingData || !this.props.users.length}
							activeSort={this.activeSort === 'vehicleGroups'}>
							{this.props.activeSort === 'vehicleGroups' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Vehicle Groups
						</HeaderElement>
						{this.deletedMode && (
							<HeaderElement
								width="10%"
								disabled={this.props.loading || this.props.exportingData || !this.props.users.length}>
								Deleted Date
							</HeaderElement>
						)}
					</AccordionHeader>

					<AccordionMain id="AccordionMain">
						{this.state.initialLoading ? (
							<BasicLoader />
						) : this.props.users.length ? (
							<Accordion fluid>
								{this.props.users.map((user, index) => {
									return (
										<section key={index}>
											<Accordion.Title
												active={this.props.activeIndex === index}
												index={index}
												onClick={(event, data) => {
													toggleAccordion(event, data, this.props.activeIndex);
													this.cleanEditOperation();
												}}
												style={{ padding: 0 }}>
												<Row>
													{/*<RowElement style={{ marginRight: '1rem' }} width="3%">*/}
													{/*	<CheckboxContainer>*/}
													{/*		<Checkbox*/}
													{/*			checked={this.state.selectedRows.has(user.id)}*/}
													{/*			onChange={(e, data) => {*/}
													{/*				e.stopPropagation();*/}
													{/*				this.handleExportSelection(data.checked, user.id);*/}
													{/*			}}*/}
													{/*		/>*/}
													{/*	</CheckboxContainer>*/}
													{/*</RowElement>*/}
													<RowElement width="5%">
														{1 + index + (this.props.pageNumber - 1) * this.props.dataSizeLimit}
													</RowElement>
													<RowElement
														width="12%"
														title={user.name ? user.name : 'Not Available'}
														style={{
															textOverflow: 'ellipsis',
															overflow: 'hidden',
															whiteSpace: 'nowrap',
															color: `${user.name ? 'black' : 'grey'}`,
														}}>
														{user.name ? user.name : 'Not Available'}
													</RowElement>
													<RowElement
														width="8%"
														title={user.type ? user.type : 'Not Available'}
														style={{
															textOverflow: 'ellipsis',
															overflow: 'hidden',
															whiteSpace: 'nowrap',
															color: `${user.name ? 'black' : 'grey'}`,
														}}>
														{`${user.type[0].toUpperCase()}${
															user.type.slice(1).toLowerCase() ? user.type.slice(1).toLowerCase() : 'Not Available'
														}`}
													</RowElement>
													{user.phone && !user.phoneVerified ? (
														<RowElement
															style={{
																fontWeight: 'bold',
																color: 'darkgrey',
															}}
															width="10%"
															title="This phone is not verified. Please click here to verify.">
															<UnVerifiedContent>
																<button
																	onClick={(e) => {
																		e.stopPropagation();
																		this.asset = user.phone;
																		this.assetType = 1;
																		openSendVerificationModal();
																	}}>
																	{user.phone ? user.phone : 'Not Available'}
																</button>
															</UnVerifiedContent>
														</RowElement>
													) : (
														<RowElement
															width="10%"
															title={user.phone ? user.phone : 'Not Available'}
															style={{
																fontWeight: 'bold',
																color: `${user.phone ? 'mediumseagreen' : 'darkgrey'}`,
															}}>
															{user.phone ? user.phone : 'Not Available'}
														</RowElement>
													)}
													{user.email && !user.emailVerified ? (
														<RowElement width="14%" title="This email is not verified. Please click here to verify.">
															<UnVerifiedContent>
																<button
																	style={{
																		textOverflow: 'ellipsis',
																		overflow: 'hidden',
																		color: 'darkgrey',
																		whiteSpace: 'nowrap',
																		fontWeight: 'bold',
																	}}
																	onClick={(e) => {
																		e.stopPropagation();
																		this.asset = user.email;
																		this.assetType = 2;
																		openSendVerificationModal();
																	}}>
																	{user.email ? user.email : 'Not Available'}
																</button>
															</UnVerifiedContent>
														</RowElement>
													) : (
														<RowElement
															width="14%"
															title={user.email ? user.email : 'Not Available'}
															style={{
																color: `${user.email ? 'mediumseagreen' : 'darkgrey'}`,
																textOverflow: 'ellipsis',
																overflow: 'hidden',
																fontWeight: 'bold',
																whiteSpace: 'nowrap',
															}}>
															{user.email ? user.email : 'Not Available'}
														</RowElement>
													)}
													<RowElement
														width="14%"
														title={user.organisation.name ? user.organisation.name : 'Not Available'}
														style={{
															textOverflow: 'ellipsis',
															overflow: 'hidden',
															whiteSpace: 'nowrap',
															color: `${user.organisation.name ? 'black' : 'grey'}`,
														}}>
														{user.organisation.name ? user.organisation.name : 'Not Available'}
													</RowElement>
													<RowElement width="10%" title={user.role?.name ? user.role?.name : 'Not Available'}>
														{user.role?.name ? user.role?.name : 'Not Available'}
													</RowElement>
													<RowElement
														style={{
															textOverflow: 'ellipsis',
															overflow: 'hidden',
															whiteSpace: 'nowrap',
															color: `${user.vehicleGroups.length ? 'black' : 'grey'}`,
														}}
														width="14%"
														title={user.vehicleGroups.map((group) => group.name).join(', ')}
														wrap="true">
														{user.vehicleGroups.map((group) => group.name).join(',')
															? user.vehicleGroups.length
															: 'Not Available'}
														{/*{user.vehicleGroups.map((group) => (*/}
														{/*  <Label size="small" style={{marginBottom: '.25em'}}>*/}
														{/*    {group.name}*/}
														{/*  </Label>*/}
														{/*))}*/}
													</RowElement>
													{this.deletedMode && (
														<RowElement width="10%" title={user.deletedDate ? user.deletedDate : 'Not Available'}>
															{user.deletedDate ? user.deletedDate : 'Not Available'}
														</RowElement>
													)}
												</Row>
											</Accordion.Title>
											{this.props.activeIndex === index && (
												<Accordion.Content active={this.props.activeIndex === index}>
													<AccordionMainContent>
														<Form>
															<Form.Group widths="equal">
																<Form.Field required={true}>
																	<label>Name</label>
																	<Input
																		fluid
																		value={
																			this.state.nameFE !== null
																				? this.state.nameFE
																				: user.name === null
																				? ''
																				: user.name
																		}
																		disabled={!this.props.editMode}
																		// onChange={(e) => this.setState({ nameFE: e.target.value })}
																		onChange={(e) => {
																			const newName = capitalizeWord(e.target.value);
																			const oldName = user.name;

																			const isEmptyName = newName.trim() === '';

																			if (newName === oldName || isEmptyName) {
																				this.setState({ disableUpdateButton: true });
																			} else {
																				this.setState({ disableUpdateButton: false });
																			}

																			this.setState({
																				nameFE: newName,
																			});
																		}}
																	/>
																</Form.Field>
																<Form.Field>
																	<label>User Type</label>
																	{/*<Dropdown*/}
																	{/*	fluid*/}
																	{/*	search*/}
																	{/*	selection*/}
																	{/*	clearable*/}
																	{/*	value={this.state.userTypeFE}*/}
																	{/*	placeholder={user.type}*/}
																	{/*	defaultValue={user.type}*/}
																	{/*	options={getUserTypesForDropdown()}*/}
																	{/*	onClose={({value}) => {*/}
																	{/*		value=null;*/}
																	{/*		// this.setState({ userTypeFE: value });*/}
																	{/*	}}*/}
																	{/*	onChange={(e, { value }) => {*/}
																	{/*		const newUserType = value;*/}
																	{/*		const oldUserType = user.type;*/}
																	{/*		if (newUserType === oldUserType) {*/}
																	{/*			this.setState({ disableUpdateButton: true });*/}
																	{/*		} else {*/}
																	{/*			this.setState({ disableUpdateButton: false });*/}
																	{/*		}*/}
																	{/*		this.setState({ userTypeFE: newUserType });*/}
																	{/*	}}*/}
																	{/*	disabled={!this.props.editMode}*/}
																	{/*/>*/}
																	<Dropdown
																		// inline
																		fluid
																		search
																		selection
																		// clearable
																		options={getUserTypesForDropdown()}
																		defaultValue={user.type}
																		disabled={!this.props.editMode}
																		onChange={(e, { value }) => {
																			const newUserType = value;
																			const oldUserType = user.type;
																			if (newUserType === oldUserType) {
																				this.setState({ disableUpdateButton: true });
																			} else {
																				this.setState({ disableUpdateButton: false });
																			}
																			this.setState({ userTypeFE: newUserType });
																		}}
																	/>
																</Form.Field>

																<Form.Field required={true}>
																	<label>Organisation</label>
																	<Input fluid defaultValue={user.organisation.name} disabled />
																</Form.Field>
															</Form.Group>
															<Form.Group widths="equal">
																<Form.Field
																	control={Input}
																	label="Phone"
																	error={
																		!validateMobileNumber(this.state.mobileNumberFE) && {
																			content: 'Phone number should be valid.',
																		}
																	}>
																	<Input
																		fluid
																		value={
																			this.state.mobileNumberFE !== null
																				? this.state.mobileNumberFE
																				: user.phone === null
																				? ''
																				: user.phone
																		}
																		// onChange={(e) => this.setState({ mobileNumberFE: e.target.value })}
																		onChange={(e) => {
																			const newPhone = e.target.value;
																			const oldPhone = user.phone;
																			const verifiedPhone = validateMobileNumber(newPhone);

																			const isEmptyMobileNumber = newPhone.trim() === '';

																			if (!verifiedPhone || newPhone === oldPhone || isEmptyMobileNumber) {
																				this.setState({ disableUpdateButton: true });
																			} else {
																				this.setState({ disableUpdateButton: false });
																			}

																			this.setState({ mobileNumberFE: newPhone });
																		}}
																		disabled={!this.props.editMode}
																	/>
																</Form.Field>
																<Form.Field
																	control={Input}
																	label="Email"
																	// error={
																	// 	!validateEmail(this.state.emailFE) ||
																	// 	(user?.email?.length > 0 &&
																	// 		this.state.emailFE?.length === 0 && { content: 'Email should be valid.' })
																	// }
																	error={
																		!validateEmail(this.state.emailFE) ||
																		(user?.email?.length > 0 && this.state.emailFE?.length === 0)
																			? { content: 'Email should be valid.' }
																			: null
																	}>
																	<Input
																		fluid
																		value={
																			this.state.emailFE !== null
																				? this.state.emailFE
																				: user.email === null
																				? ''
																				: user.email
																		}
																		disabled={!this.props.editMode}
																		onChange={(e) => {
																			const newEmail = e.target.value;
																			const oldEmail = user.email;
																			const verifiedEmail = validateEmail(newEmail);

																			const isEmptyEmail = newEmail.trim() === '';

																			if (!verifiedEmail || newEmail === oldEmail || isEmptyEmail) {
																				this.setState({ disableUpdateButton: true });
																			} else {
																				this.setState({ disableUpdateButton: false });
																			}
																			this.setState({ emailFE: newEmail });
																		}}
																	/>
																</Form.Field>

																<Form.Field required={true}>
																	<label>Role</label>
																	<Dropdown
																		fluid
																		search
																		selection
																		value={
																			this.state.selectedRoleFE !== null ? this.state.selectedRoleFE : user.role.id
																		}
																		options={
																			user.type === 'Driver' || user.type === 'Parent'
																				? this.state.roles.filter((role) => {
																						return role.text !== 'Tracknerd Super Admin';
																				  })
																				: this.state.roles
																		}
																		disabled={!this.props.editMode}
																		onChange={(e, { value }) => {
																			this.setState({
																				selectedRoleFE: value,
																			});
																			this.setState({ disableUpdateButton: false });
																		}}
																	/>
																</Form.Field>
															</Form.Group>
															<Form.Group widths="equal">
																<Form.Field>
																	<section
																		style={{
																			width: '100%',
																			display: 'flex',
																			marginTop: '2em',
																		}}>
																		<label
																			style={{
																				fontWeight: 'bold',
																				flexGrow: '0.2',
																			}}>
																			Login Enabled
																		</label>
																		{/*<Expander />*/}
																		<Container>
																			<Checkbox
																				toggle
																				checked={
																					this.state.canLoginFE === null ? user.isLoginEnabled : this.state.canLoginFE
																				}
																				onChange={(e, data) => this.onCanLoginFEChange(data)}
																				disabled={!this.props.editMode}
																			/>
																		</Container>
																	</section>
																</Form.Field>

																<Form.Field>
																	<section
																		style={{
																			width: '100%',
																			display: 'flex',
																			marginTop: '2em',
																		}}>
																		<label
																			style={{
																				fontWeight: 'bold',
																				flexGrow: '0.2',
																			}}>
																			SMS Alert
																		</label>
																		{/*<Expander />*/}
																		<Container>
																			<Checkbox
																				toggle
																				onChange={(e, data) => {
																					this.setState({
																						isEnabledSMSFE: data.checked,
																						disableUpdateButton: false,
																					});
																				}}
																				checked={
																					this.state.isEnabledSMSFE === null
																						? user.isEnabledSMS
																						: this.state.isEnabledSMSFE
																				}
																				disabled={!this.props.editMode}
																			/>
																		</Container>
																	</section>
																</Form.Field>
																<Form.Field>
																	<section
																		style={{
																			width: '100%',
																			display: 'flex',
																			marginTop: '2em',
																		}}>
																		<label
																			style={{
																				fontWeight: 'bold',
																				flexGrow: '0.2',
																			}}>
																			Email Alert
																		</label>
																		{/*<Expander />*/}
																		<Container>
																			<Checkbox
																				toggle
																				onChange={(e, data) =>
																					this.setState({
																						isEnabledEmailFE: data.checked,
																						disableUpdateButton: false,
																					})
																				}
																				checked={
																					this.state.isEnabledEmailFE === null
																						? user.isEnabledEmail
																						: this.state.isEnabledEmailFE
																				}
																				disabled={!this.props.editMode}
																			/>
																		</Container>
																	</section>
																</Form.Field>
																<Form.Field>
																	<section
																		style={{
																			width: '100%',
																			display: 'flex',
																			marginTop: '2em',
																		}}>
																		<label
																			style={{
																				fontWeight: 'bold',
																				flexGrow: '0.2',
																			}}>
																			Push Alert
																		</label>
																		{/*<Expander />*/}
																		<Container>
																			<Checkbox
																				toggle
																				onChange={(e, data) => {
																					this.setState({
																						isEnabledPushFE: data.checked,
																						disableUpdateButton: false,
																					});
																				}}
																				checked={
																					this.state.isEnabledPushFE === null
																						? user.isEnabledPush
																						: this.state.isEnabledPushFE
																				}
																				disabled={!this.props.editMode}
																			/>
																		</Container>
																	</section>
																</Form.Field>
															</Form.Group>
														</Form>

														{/*Actions*/}
														{this.state.deletedMode ? (
															<Actions>
																<Button
																	primary
																	// disabled={!(this.state.manageAccess || this.state.createAccess)}
																	onClick={() => {
																		trackMixpanelEvent('Users', { Action: 'Restore' });
																		dispatchNoPayload('USERS_RESTORE_MODAL_OPEN');
																		this.user_id = user.id;
																	}}>
																	Restore
																</Button>
															</Actions>
														) : this.props.editMode ? (
															<Actions>
																<Button
																	secondary
																	onClick={() => {
																		this.cleanEditOperation();
																		this.setState({
																			canLoginFE: user.isLoginEnabled,
																		});
																	}}>
																	Cancel
																</Button>
																<Button
																	positive
																	disabled={this.state.disableUpdateButton}
																	loading={this.props.updating}
																	onClick={() => {
																		this.update(user.id);
																	}}>
																	Update
																</Button>
															</Actions>
														) : (
															<Actions>
																<Button
																	primary
																	// disabled={!(this.state.manageAccess || this.state.updateAccess)}
																	onClick={() => {
																		trackMixpanelEvent('Users', { Action: 'Edit' });
																		dispatchNoPayload('USERS_EDIT_MODAL_OPEN');
																	}}>
																	Edit
																</Button>
																{user.type !== 'Parent' && (
																	<Button
																		color="teal"
																		// disabled={!(this.state.manageAccess || this.state.updateAccess)}
																		onClick={() => {
																			trackMixpanelEvent('Users', { Action: 'Edit Vehicle Groups' });
																			this.user_id = user.id;
																			this.setState({
																				vehicleGroupsFE: user.vehicleGroups.map((group) => group.id),
																			});
																			dispatchNoPayload('USERS_VG_MODAL_OPEN');
																			getVehicleGroupsForDropdown(
																				`?organisations[]=${user.organisation.id}&dropdown=true`
																			).then((response) => {
																				this.setState({ vehicleGroups: response });
																			});
																		}}>
																		Edit Vehicle Groups
																	</Button>
																)}

																{user.isLoginEnabled && (
																	<Button
																		color="teal"
																		// disabled={!(this.state.manageAccess || this.state.updateAccess)}
																		onClick={() => {
																			trackMixpanelEvent('Users', { Action: 'Reset Password' });
																			this.password = '';
																			this.setState({
																				resetPasswordID: user.id,
																			});
																			dispatchNoPayload('USERS_PASSWORD_MODAL_OPEN');
																		}}>
																		Reset Password
																	</Button>
																)}

																{user.type === 'Driver' &&
																	(user.vehicle ? (
																		<>
																			<Button
																				color="orange"
																				// disabled={!(this.state.manageAccess || this.state.updateAccess)}
																				onClick={() => {
																					trackMixpanelEvent('Users', { Action: 'change Vehicle' });
																					this.user_id = user.id;
																					dispatchNoPayload('USERS_VEHICLE_MODAL_OPEN');
																					this.setState({
																						vehicleFE: user.vehicle.id,
																					});
																					getVehiclesForDropdown(
																						`?organisations[]=${user.organisation.id}&dropdown=true`
																					).then((response) => {
																						this.setState({ vehicles: response });
																					});
																				}}>
																				Change Vehicle
																			</Button>
																			<Button
																				secondary
																				// disabled={!(this.state.manageAccess || this.state.updateAccess)}
																				onClick={() => {
																					trackMixpanelEvent('Users', { Action: 'Remove Vehicle' });
																					dispatchNoPayload('USERS_REMOVE_VEHICLE_MODAL_OPEN');
																					this.user_id = user.id;
																				}}>
																				Remove Vehicle
																			</Button>
																		</>
																	) : (
																		<Button
																			color="green"
																			// disabled={!(this.state.manageAccess || this.state.updateAccess)}
																			onClick={() => {
																				trackMixpanelEvent('Users', { Action: 'Assign Vehicle' });
																				this.user_id = user.id;
																				dispatchNoPayload('USERS_VEHICLE_MODAL_OPEN');
																				getVehiclesForDropdown(
																					`?organisations[]=${user.organisation.id}&dropdown=true`
																				).then((response) => {
																					this.setState({ vehicles: response });
																				});
																			}}>
																			Assign Vehicle
																		</Button>
																	))}

																<Button
																	negative
																	// disabled={!(this.state.manageAccess || this.state.deleteAccess)}
																	onClick={() => {
																		trackMixpanelEvent('Users', { Action: 'Delete' });
																		dispatchNoPayload('USERS_DELETE_MODAL_OPEN');
																		this.user_id = user.id;
																	}}>
																	Delete
																</Button>
															</Actions>
														)}
													</AccordionMainContent>
												</Accordion.Content>
											)}
										</section>
									);
								})}
							</Accordion>
						) : (
							<PlaceHolder />
						)}
					</AccordionMain>
				</CategoryMain>

				<CategoryFooter background={this.deletedMode}>
					<FooterPagination
						handleDataSizeLimitChange={this.handleDataSizeLimitChange}
						pageNumber={this.props.pageNumber}
						handlePageChange={this.handlePageChange}
						count={this.props.count}
						disabled={this.props.loading || !this.props.users.length || this.props.exportingData}
					/>
				</CategoryFooter>

				{this.props.loading && !this.state.initialLoading && <TransitionLoader />}

				{this.props.addModalOpen && (
					<AddModal
						onClose={this.cleanAddOperation}
						open={this.props.addModalOpen}
						onNameChange={this.onNameChange}
						selectedUserType={this.state.userTypeFA}
						userTypeOptions={this.state.userTypes}
						onUserTypeChange={this.onUserTypeChange}
						email={this.state.emailIdFA}
						onEmailChange={this.onEmailChange}
						passwordVisible={this.props.passwordVisible}
						onShowPasswordIconClick={this.onShowPasswordIconClick}
						onPasswordChange={this.onPasswordChange}
						phone={this.state.mobileNumberFA}
						onPhoneChange={this.onPhoneChange}
						selectedOrganisation={this.state.organisationFA}
						organisations={this.state.organisations}
						onOrganisationChange={this.onOrganisationChange}
						vehicleGroups={this.state.vehicleGroups}
						selectedVehicleGroup={this.state.vehicleGroupFA}
						onVehicleGroupChange={this.onVehicleGroupChange}
						roleOptions={this.state.roles}
						selectedRoleFA={this.state.selectedRoleFA}
						onRoleFAChange={this.onRoleFAChange}
						canLoginFA={this.state.canLoginFA}
						onCanLoginFAChange={this.onCanLoginFAChange}
						isEnabledSMSFA={this.state.isEnabledSMSFA}
						onIsEnabledSMSFAChange={this.onIsEnabledSMSFAChange}
						isEnabledEmailFA={this.state.isEnabledEmailFA}
						onIsEnabledEmailFAChange={this.onIsEnabledEmailFAChange}
						isEnabledPushFA={this.state.isEnabledPushFA}
						onIsEnabledPushFAChange={this.onIsEnabledPushFAChange}
						onCancelClick={this.cleanAddOperation}
						disabled={
							!this.state.nameFA ||
							this.state.userTypeFA.length === 0 ||
							!this.state.organisationFA ||
							!this.state.selectedRoleFA ||
							!this.state.passwordFA ||
							!this.state.mobileNumberFA
						}
						onAddButtonClick={this.add}
						addButtonLoading={this.props.adding}
					/>
				)}

				{/*{this.props.importModalOpen && (*/}
				{/*	<UserImport*/}
				{/*		template={this.fileTemplate}*/}
				{/*		file={this.inputData}*/}
				{/*		upload={this.importUsers}*/}
				{/*		usersResponse={this.state.usersResponse}*/}
				{/*		closeImport={this.cleanImportOperation}*/}
				{/*	/>*/}
				{/*)}*/}

				{this.props.openVGModal && (
					<VehicleGroupModal
						onClose={this.cleanVGOperation}
						open={this.props.openVGModal}
						selectedVehicleGroup={this.state.vehicleGroupsFE}
						vehicleGroupOptions={this.state.vehicleGroups}
						onVehicleGroupUpdate={this.onVehicleGroupUpdate}
						onCancelClick={this.cleanVGOperation}
						onUpdateButtonClick={() => this.updateVehicleGroup(this.user_id)}
						updateButtonLoading={this.props.updating}
					/>
				)}

				{this.props.openVehicleModal && (
					<VehicleModal
						onClose={this.cleanVehicleOperation}
						open={this.props.openVehicleModal}
						selectedVehicle={this.state.vehicleFE}
						vehicleOptions={this.state.vehicles}
						onVehicleUpdate={this.onVehicleUpdate}
						onCancelClick={this.cleanVehicleOperation}
						onUpdateButtonClick={() => this.updateVehicle(this.user_id)}
						updateButtonLoading={this.props.updating}
					/>
				)}

				{this.props.openRemoveVehicleModal && (
					<RemoveVehicleModal
						onClose={this.cleanRemoveVehicleOperation}
						open={this.props.openRemoveVehicleModal}
						onCancelClick={this.cleanRemoveVehicleOperation}
						onRemoveButtonClick={() => {
							this.removeVehicle(this.user_id);
						}}
						updateButtonLoading={this.props.updating}
					/>
				)}

				{this.props.passwordModalOpen && (
					<PasswordModal
						onClose={this.cleanResetPasswordOperation}
						open={this.props.passwordModalOpen}
						passwordVisible={this.props.passwordVisible}
						onPasswordSubmit={this.onPasswordSubmit}
						onCancelClick={this.cleanResetPasswordOperation}
						onSubmitButtonClick={() => {
							this.resetUserPassword(this.state.resetPasswordID);
						}}
						submitButtonLoading={this.props.submiting}
					/>
				)}

				{this.props.deleteModalOpen && (
					<DeleteModal
						onClose={this.cleanDeleteOperation}
						open={this.props.deleteModalOpen}
						onCancelClick={this.cleanDeleteOperation}
						onDeleteButtonClick={() => {
							this.deleteUser(this.user_id);
						}}
						deleteButtonLoading={this.props.deleting}
					/>
				)}

				{this.props.openSendVerificationModal && (
					<SendVerificationModal
						onClose={this.cleanSendVerificationOperation}
						open={this.props.openSendVerificationModal}
						onCancelClick={this.cleanSendVerificationOperation}
						onSendButtonClick={this.sendVerificationLink}
						sendButtonLoading={this.props.sending}
					/>
				)}

				{this.props.restoreModal && (
					<RestoreModal
						onClose={this.cleanRestoreOperation}
						open={this.props.restoreModal}
						onCancelClick={this.cleanRestoreOperation}
						onRestoreButtonClick={() => {
							this.restoreUser(this.user_id);
						}}
						restoreButtonLoading={this.props.restoring}
					/>
				)}

				{/*Filter Modal*/}
				{this.props.filterModalOpen && (
					<FilterModal
						onClose={() => {
							this.setState({
								filters: {
									organisations: this.activeFilters.organisations,
									types: this.activeFilters.types,
									roles: this.activeFilters.roles,
									email: this.activeFilters.email,
									phone: this.activeFilters.phone,
								},
							});
							closeFilterModal();
							dispatch('ACTIVE_FILTER', {
								...this.props.filters,
								organisations: [],
								email: null,
								phone: null,
							});
						}}
						open={this.props.filterModalOpen}
						organisationsOptions={this.state.organisations}
						// organisations={this.state.filters.organisations}
						organisations={this.props.filters.organisations}
						onOrganisationFilterChange={this.onOrganisationFilterChange}
						typesOptions={this.state.userTypes}
						types={this.state.filters.types}
						onTypesFilterChange={this.onTypeFilterChange}
						rolesOptions={this.state.roles}
						roles={this.state.filters.roles}
						email={this.props.filters.email}
						phone={this.props.filters.phone}
						onRolesFilterChange={this.onRoleFilterChange}
						onEmailFilterChange={this.onEmailFilterChange}
						onPhoneFilterChange={this.onPhoneFilterChange}
						onCancelClick={() => {
							this.setState({
								filters: {
									organisations: [],
									types: [],
									roles: [],
									email: null,
									phone: null,
								},
							});
							closeFilterModal();
							dispatch('ACTIVE_FILTER', {
								...this.props.filters,
								organisations: [],
								email: null,
								phone: null,
							});
						}}
						onResetClick={() => {
							this.activeFilters = {
								organisations: [],
								types: [],
								roles: [],
								email: null,
								phone: null,
							};
							this.setState({
								filters: {
									organisations: [],
									types: [],
									roles: [],
									email: null,
									phone: null,
								},
							});
							dispatch('ACTIVE_FILTER', {
								...this.props.filters,
								organisations: [],
								email: null,
								phone: null,
							});
							// closeFilterModal();
							this.getFilteredUsers();
						}}
						onApplyClick={() => {
							trackMixpanelEvent('Users', { Action: 'Filter' });
							this.activeFilters = this.state.filters;
							this.activeFilters.organisations = this.props.filters.organisations;
							this.activeFilters.email = this.props.filters.email;
							this.activeFilters.phone = this.props.filters.phone;
							this.getFilteredUsers();
							closeFilterModal();
						}}
					/>
				)}

				{this.props.openExportModal && (
					<ExportModal
						open={this.props.openExportModal}
						fileName={this.state.fileName}
						fileNameChange={this.fileNameChange}
						fileExtension={this.state.fileExtension}
						fileExtensionChange={this.fileExtensionChange}
						onDownloadClick={() => exportData(this.props.exportData, this.state.fileName, this.state.fileExtension)}
					/>
				)}
			</CategoryContentMembrane>
		);
	}
}

// Connect to Store
const mapStateToProps = (state) => ({
	users: state.users.users,
	loading: state.users.loading,
	activeIndex: state.users.activeIndex,
	count: state.users.count,
	pageNumber: state.users.pageNumber,
	dataSizeLimit: state.ui.dataSizeLimit,

	adding: state.users.adding,
	addModalOpen: state.users.addModalOpen,

	editMode: state.users.editMode,
	updating: state.users.updating,

	deleteModalOpen: state.users.deleteModalOpen,
	deleting: state.users.deleting,

	passwordModalOpen: state.users.passwordModalOpen,
	submiting: state.users.submiting,
	passwordVisible: state.users.passwordVisible,

	openVGModal: state.users.vehicleGroupModal,
	openVehicleModal: state.users.vehicleModal,
	openRemoveVehicleModal: state.users.removeVehicleModal,
	openSendVerificationModal: state.users.openSendVerificationModal,
	sending: state.users.sending,
	filterModalOpen: state.users.filterModalOpen,

	activeSort: state.users.activeSort,
	sortOrder: state.users.sortOrder,

	rowsSelected: state.users.anyRowSelected,
	selectedRows: state.users.selectedRows,

	restoreModal: state.users.restoreModal,
	restoring: state.users.restoring,
	importModalOpen: state.users.importModalOpen,

	openExportModal: state.users.openExportModal,
	exportData: state.users.exportData,
	exportingData: state.users.exportingData,
	searchQuery: state.vehicles.searchQuery,
	filters: state.vehicles.filters,
});

export default connect(mapStateToProps)(Users);
