import React from 'react';
import { ORGANISATIONS_API, VERIFIED_ASSETS_API } from '../../../../../logic/configs/api';
import BasicLoader from '../../../blocks/molecules/loader/BasicLoader';
import SearchBox from '../../../blocks/atoms/search-box/SearchBox';
import FooterPagination from '../../../blocks/molecules/pagination/Pagination';
import ToggleSwitch from '../../../blocks/molecules/toggle-switch/ToggleSwitch';
import RestoreModal from '../../../blocks/organisms/modals/organisations/RestoreModal';
import DeactivateModal from '../../../blocks/organisms/modals/organisations/DeactivateModal';
import AddModal from '../../../blocks/organisms/modals/organisations/AddModal';
import SaasPlanUpdateModal from '../../../blocks/organisms/modals/organisations/SaasPlanUpdateModal';
import ExportModal from '../../../blocks/organisms/modals/organisations/ExportModal';
import { CategoryContentMembrane } from '../../../styles/containers/CategoryContentMembrane';
import { CategoryHeader } from '../../../styles/containers/CategoryHeader';
import { Expander } from '../../../styles/place-holders/Expander';
import { CategoryMain } from '../../../styles/containers/CategoryMain';
import { AccordionHeader } from '../../../styles/containers/AccordionHeader';
import { AccordionMain } from '../../../styles/containers/AccordionMain';
import { CategoryFooter } from '../../../styles/containers/CategoryFooter';
import { AccordionMainContent } from '../../../styles/containers/AccordionMainContent';
import PlaceHolder from '../../../blocks/molecules/place-holder/PlaceHolder';
import DeactivateConfirmationModal from '../../../blocks/organisms/modals/organisations/DeactivateConfirmationModal';
import SendVerificationModal from '../../../blocks/organisms/modals/organisations/SendVerificationModal';
import FilterModal from '../../../blocks/organisms/modals/organisations/FilterModal';
import TransitionLoader from '../../../blocks/molecules/loader/TransitionLoader';
import { STATES_AND_CITIES } from '../../../../../data/constants/state-cities';
import {
	closeFilterModal,
	closeSendVerificationModal,
	exportAll,
	exportData,
	getCityForDropdown,
	getStateForDropdown,
	openFilterModal,
	openSendVerificationModal,
	refreshOrganisations,
	searchOrganisations,
	toggleAccordion,
} from '../../../../../logic/middleware/organisations';
import { dispatch, dispatchNoPayload } from '../../../../../logic/middleware/middleware';
import {
	capitalizeWord,
	validateEmail,
	validateMobileNumber,
	validateWebsite,
} from '../../../../../logic/utilities/validator';
import { generateSuccessMessage, handleError } from '../../../../../logic/middleware/messaging';

// import axios from 'axios';
import { connect } from 'react-redux';
import { Actions, HeaderElement, Row, RowElement, UnVerifiedContent } from '../../../styles/Accordion';
import { Accordion, Button, Dropdown, Form, Icon, Input, Label } from 'semantic-ui-react';
import moment from 'moment';
import accessSetter from '../../../../../logic/utilities/accessSetter';
import { trackMixpanelEvent } from '../../../../../logic/middleware/mixpanel';

import { FILE_TYPE_EXTENSIONS } from '../../../../../data/constants/file-types';
import { isoToHumanReadableDay } from '../../../../../logic/utilities/date';
import { deleteData, patchData, postData } from '../../../../../logic/middleware/services/apiRequest';
/*import { datadogLogs } from '@datadog/browser-logs';*/

class Organisations extends React.Component {
	organisationID = '';
	orgname = '';
	// searchQuery = '';
	timeoutId;
	deletedMode = false;
	selectAllActive = false;

	sortOrder = '';
	activeSort = '';

	asset = '';
	assetType = null;

	activeDateFilter = false;
	isSuperAdminAccess = localStorage.getItem('isSuperAdmin');
	activeFilters = {
		state: [],
		city: [],
		phone: null,
		email: null,
		// industry: [],
		// solutions: [],
		// platformAwareness: [],
		// preferredCommunicationChannel: [],
		startDate: '',
		endDate: '',
	};

	constructor(props) {
		super(props);

		this.state = {
			initialLoading: true,
			manageAccess: false,
			readAccess: false,
			createAccess: false,
			updateAccess: false,
			deleteAccess: false,

			registeredNameFE: null,
			brandNameFE: null,
			addressFE: null,
			GSTFE: null,
			PANFE: null,
			CINFE: null,
			websiteFE: null,
			emailFE: null,
			phoneFE: null,
			billingPeriodFE: null,
			showCustomizedPlans: false,
			disableCustomizedPlans: false,
			stateFE: null,
			stateOptionFE: [],
			cityFE: null,
			cityOptionsFE: [],
			// industryFE: null,
			// useCaseFE: null,
			// gpsPotentialFE: null,
			// fmsPotentialFE: null,
			// platformAwarenessFE: null,
			// preferredCommunicationChannelFE: null,

			registeredNameFA: null,
			nameFA: null,
			sameRegistarionBrandName: false,
			addressFA: null,
			GSTFA: null,
			PANFA: null,
			CINFA: null,
			websiteFA: null,
			emailFA: null,
			phoneFA: null,
			billingPeriodFA: null,
			saasPlansFA: null,
			selectedSaasPlans: [],
			showCustomizedPlansFA: false,
			stateFA: null,
			stateOptionFA: [],
			cityFA: null,
			cityOptionsFA: [],
			// industryFA: null,
			// useCaseFA: null,
			// gpsPotentialFA: null,
			// fmsPotentialFA: null,
			// platformAwarenessFA: null,
			// preferredCommunicationChannelFA: null,

			// Deactivate
			users: [],
			vehicles: [],
			vehicleGroups: [],
			reason: null,
			deleteSims: false,

			anyRowSelected: false,
			selectedRows: new Set(),

			deletedMode: false,

			filters: {
				state: [],
				city: [],
				phone: null,
				email: null,
				// industry: [],
				// solutions: [],
				// platformAwareness: [],
				// preferredCommunicationChannel: [],
				startDate: '',
				endDate: '',
			},
			cityOptions: [],

			fileName: 'Organisations',
			fileExtension: 'pdf',
			searchQuery: '',
		};

		this.toggleDeletedMode = this.toggleDeletedMode.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.getFilteredOrganisations = this.getFilteredOrganisations.bind(this);
		this.add = this.add.bind(this);
		this.cleanAddOperation = this.cleanAddOperation.bind(this);
		this.export = this.export.bind(this);
		this.update = this.update.bind(this);
		this.restoreOrganisation = this.restoreOrganisation.bind(this);
		this.cleanEditOperation = this.cleanEditOperation.bind(this);
		// this.cleanDeleteOperation = this.cleanDeleteOperation.bind(this);
		this.cleanRestoreOperation = this.cleanRestoreOperation.bind(this);
		this.handleDataSizeLimitChange = this.handleDataSizeLimitChange.bind(this);
		this.handleExportSelection = this.handleExportSelection.bind(this);
		this.handleSelectAll = this.handleSelectAll.bind(this);
		this.sort = this.sort.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.cleanDeactivateOperation = this.cleanDeactivateOperation.bind(this);
		this.cleanSelectedRows = this.cleanSelectedRows.bind(this);
		this.cleanSendVerificationOperation = this.cleanSendVerificationOperation.bind(this);
		this.sendVerificationLink = this.sendVerificationLink.bind(this);

		this.onReasonChoose = this.onReasonChoose.bind(this);
		this.onCheckDeleteSims = this.onCheckDeleteSims.bind(this);
		this.onNameChangeFE = this.onNameChangeFE.bind(this);
		this.onRegisteredNameChangeFE = this.onRegisteredNameChangeFE.bind(this);

		// this.onIndustryChangeFA = this.onIndustryChangeFA.bind(this);
		// this.onUseCaseChangeFA = this.onUseCaseChangeFA.bind(this);
		// this.onGpsPotentialChangeFA = this.onGpsPotentialChangeFA.bind(this);
		// this.onFmsPotentialChangeFA = this.onFmsPotentialChangeFA.bind(this);
		// this.onPlatformAwarenessChangeFA = this.onPlatformAwarenessChangeFA.bind(this);
		// this.onPreferredCommunicationChannelChangeFA = this.onPreferredCommunicationChannelChangeFA.bind(this);
		// this.onIndustryChangeFE = this.onIndustryChangeFE.bind(this);
		// this.onUseCaseChangeFE = this.onUseCaseChangeFE.bind(this);
		// this.onGpsPotentialChangeFE = this.onGpsPotentialChangeFE.bind(this);
		// this.onFmsPotentialChangeFE = this.onFmsPotentialChangeFE.bind(this);
		// this.onPlatformAwarenessChangeFE = this.onPlatformAwarenessChangeFE.bind(this);
		// this.onPreferredCommunicationChannelChangeFE = this.onPreferredCommunicationChannelChangeFE.bind(this);

		// this.onIndustryChange = this.onIndustryChange.bind(this);
		// this.onSolutionsChange = this.onSolutionsChange.bind(this);
		// this.onPlatformAwarenessChange = this.onPlatformAwarenessChange.bind(this);
		// this.onPreferredCommunicationChannelChange = this.onPreferredCommunicationChannelChange.bind(this);

		this.onRegistrationNameChange = this.onRegistrationNameChange.bind(this);
		this.onBrandNameChange = this.onBrandNameChange.bind(this);
		this.onAddressChange = this.onAddressChange.bind(this);
		this.onGSTChange = this.onGSTChange.bind(this);
		this.onPANChange = this.onPANChange.bind(this);
		this.onCINChange = this.onCINChange.bind(this);
		// this.onBillingPeriodChange = this.onBillingPeriodChange.bind(this);
		this.onEmailChange = this.onEmailChange.bind(this);
		this.onPhoneChange = this.onPhoneChange.bind(this);
		this.onWebsiteChange = this.onWebsiteChange.bind(this);
		this.onSaasPlansChange = this.onSaasPlansChange.bind(this);
		this.onSaasPlanUpdate = this.onSaasPlanUpdate.bind(this);
		this.onCheckedCustomizedPlan = this.onCheckedCustomizedPlan.bind(this);
		this.onChangeCustomizedPlan = this.onChangeCustomizedPlan.bind(this);
		this.onStateChangeFA = this.onStateChangeFA.bind(this);
		this.onCityChangeFA = this.onCityChangeFA.bind(this);
		this.onStateChangeFE = this.onStateChangeFE.bind(this);
		this.onCityChangeFE = this.onCityChangeFE.bind(this);
		this.onStateChange = this.onStateChange.bind(this);
		this.onCityChange = this.onCityChange.bind(this);
		this.setFilterChange = this.setFilterChange.bind(this);

		this.fileExtensionChange = this.fileExtensionChange.bind(this);
		this.fileNameChange = this.fileNameChange.bind(this);
		this.manageAccessSetter = this.manageAccessSetter.bind(this);
		this.onPhoneFilterChange = this.onPhoneFilterChange.bind(this);
		this.onEmailFilterChange = this.onEmailFilterChange.bind(this);
		this.handleSearchQuery = this.handleSearchQuery.bind(this);
		this.handleClearDate = this.handleClearDate.bind(this);
	}

	manageAccessSetter(rule, action) {
		this.setState({ [action]: rule });
	}

	componentDidMount() {
		let path = window.location.pathname.split('/');
		if (path.length > 3) {
			this.orgname = decodeURIComponent(path[3]);

			if (this.orgname) {
				this.setState({ searchQuery: this.orgname });
				this.handleSearch({ target: { value: this.orgname } });
				return;
			}
		}

		if (this.props.searchQuery) {
			dispatch('UPDATE_SEARCH_QUERY', this.props.searchQuery);
			this.handleSearch({ target: { value: this.props.searchQuery } });
			return;
		}
		trackMixpanelEvent('Organisation', { Action: 'View' });
		const permissions = JSON.parse(localStorage.getItem('permissions'));

		const organisationPermissions = permissions?.filter((permission) => permission.subject === 'Organisation');
		if (organisationPermissions) {
			for (const op of organisationPermissions) {
				accessSetter(op?.action, this.manageAccessSetter);
			}
		}

		if (this.props.location.state) {
			let { startDate, endDate } = this.props.location.state;
			let query = '';
			if (startDate && endDate) {
				query += `&createdStartDate=${moment(startDate, 'DD-MM-YYYY').toISOString()}&createdEndDate=${moment()
					.endOf('day')
					.toISOString()}`;
				this.setState({
					filters: { ...this.state.filters, startDate: startDate, endDate: endDate },
				});
				this.activeFilters = {
					...this.activeFilters,
					startDate: startDate,
					endDate: endDate,
				};
			}

			refreshOrganisations(query);
		}

		if (Object.values(this.props.filters).every((value) => value?.length > 0)) {
			this.activeFilters = this.state.filters;
			this.activeFilters.email = this.props.filters.email;
			this.activeFilters.phone = this.props.filters.phone;
			this.getFilteredOrganisations();
			return;
		}
		refreshOrganisations();
	}

	// Fetch
	getFilteredOrganisations(activePage = 1, resetAccordion = true) {
		if (resetAccordion) {
			dispatch('ORGANISATIONS_TOGGLE_ACCORDION', -1);
		}

		let query = '';
		if (this.state.searchQuery !== '') {
			query = query + searchOrganisations(this.state.searchQuery);
		}

		if (this.activeSort !== '' && this.sortOrder !== '') {
			if (this.sortOrder === 'asc') {
				query = query + `&sortBy=${this.activeSort}&orderBy=${this.sortOrder}`;
			} else {
				query = query + `&sortBy=${this.activeSort}&orderBy=${this.sortOrder}`;
			}
		}

		if (this.activeFilters.state?.length) {
			query = query + `&state=${this.activeFilters.state.join(',')}`;
			//query = query + this.activeFilters.state.map((state) => `&state[]=${state}`).join('');
		}

		if (this.activeFilters.city?.length) {
			query = query + `&city=${this.activeFilters.city.join(',')}`;
			//query =  query + this.activeFilters.city.map((city)=>`&city[]=${city}`).join('');
		}

		if (this.activeFilters.email) {
			if (this.activeFilters.email.includes(1)) {
				query = query + `&emailVerified=true`;
			} else {
				query = query + `&emailVerified`;
			}
		}

		if (this.activeFilters.phone) {
			if (this.activeFilters.phone.includes(1)) {
				query = query + `&phoneVerified=true`;
			} else {
				query = query + `&phoneVerified`;
			}
		}

		// if (this.activeFilters.industry.length) {
		// 	query = query + `&industry=${this.activeFilters.industry.join(',')}`;
		// }

		// if (this.activeFilters.solutions.length) {
		// 	query = query + `&solutions=${this.activeFilters.solutions.join(',')}`;
		// }
		//
		// if (this.activeFilters.platformAwareness.length) {
		// 	query = query + `&platformAwareness=${this.activeFilters.platformAwareness.join(',')}`;
		// }
		//
		// if (this.activeFilters.preferredCommunicationChannel.length) {
		// 	query = query + `&preferredCommunicationChannel=${this.activeFilters.preferredCommunicationChannel.join(',')}`;
		// }

		if (this.activeFilters?.startDate) {
			query =
				query +
				`&createdStartDate=${moment(this.state.filters.startDate, 'DD-MM-YYYY').toISOString()}&createdEndDate=${moment(
					this.state.filters.endDate,
					'DD-MM-YYYY'
				)
					.endOf('day')
					.toISOString()}`;
		}

		if (this.deletedMode) {
			query = query + `&deleted=1`;
		}

		refreshOrganisations(query, activePage);
	}

	handleSearch(e) {
		if (this.orgname) {
			this.setState({ searchQuery: this.orgname });
			dispatch('UPDATE_SEARCH_QUERY', this.orgname);
		} else {
			this.setState({ searchQuery: e.target.value });
			dispatch('UPDATE_SEARCH_QUERY', e.target.value);
		}

		if (this.timeoutId) {
			clearTimeout(this.timeoutId);
		}

		this.timeoutId = setTimeout(() => {
			this.getFilteredOrganisations();
			this.cleanSelectedRows();
		}, 500);
	}

	toggleDeletedMode() {
		this.deletedMode = !this.deletedMode;
		this.setState({
			deletedMode: !this.state.deletedMode,
		});

		this.getFilteredOrganisations();
		this.cleanSelectedRows();
	}

	// Export
	fileNameChange(e) {
		this.setState({ fileName: e.target.value });
	}

	fileExtensionChange(e, { value }) {
		this.setState({ fileExtension: value });
	}

	handleExportSelection(checkedData, id) {
		let selectedRows = this.state.selectedRows;

		if (selectedRows.has(id)) {
			selectedRows.delete(id);
		} else selectedRows.add(id);

		this.setState({
			selectedRows: selectedRows,
			anyRowSelected: !!selectedRows.size,
		});
	}

	handleSelectAll(checked) {
		let selectedRows = new Set();

		if (checked) {
			this.selectAllActive = true;
			this.props.organisations.forEach((org) => selectedRows.add(org.id));
			this.setState({
				selectedRows: selectedRows,
				anyRowSelected: !!selectedRows.size,
			});
		} else {
			this.selectAllActive = false;
			this.setState({
				selectedRows: new Set(),
				anyRowSelected: !!selectedRows.size,
			});
		}
	}

	export() {
		const { organisations } = this.props;

		let organisationsToExport = [];
		organisations.forEach((org) => {
			if (this.state.selectedRows.has(org.id)) {
				organisationsToExport.push(org);
			}
		});

		return organisationsToExport;
	}

	getFilterListQuery() {
		let query = '';

		query = query + `?fileType=${this.state.fileExtension}`;

		if (this.state.searchQuery !== '') {
			query = query + searchOrganisations(this.state.searchQuery);
		}

		if (this.activeSort !== '' && this.sortOrder !== '') {
			if (this.sortOrder === 'asc') {
				query = query + `&sortBy=${this.activeSort}&orderBy=${this.sortOrder}`;
			} else {
				query = query + `&sortBy=${this.activeSort}&orderBy=${this.sortOrder}`;
			}
		}

		if (this.activeFilters.state.length) {
			// query = query + `&state=${this.activeFilters.state.join(',')}`;
			query = query + this.activeStatus.state.map((state) => `&state[]=${state}`).join('');
		}

		if (this.activeFilters.city.length) {
			// query = query + `&city=${this.activeFilters.city.join(',')}`;
			query = query + this.activeFilters.city.map((city) => `&city[]=${city}`).join('');
		}

		if (this.activeFilters.email) {
			if (this.activeFilters.email.includes(1)) {
				query = query + `&emailVerified=true`;
			} else {
				query = query + `&emailVerified`;
			}
		}

		if (this.activeFilters.phone) {
			if (this.activeFilters.phone.includes(1)) {
				query = query + `&phoneVerified=true`;
			} else {
				query = query + `&phoneVerified`;
			}
		}

		if (this.state.filters.startDate) {
			query =
				query +
				`&createdStartDate=${moment(this.state.filters.startDate, 'DD-MM-YYYY').toISOString()}&createdEndDate=${moment(
					this.state.filters.endDate,
					'DD-MM-YYYY'
				)
					.endOf('day')
					.toISOString()}`;
		}

		if (this.deletedMode) {
			query = query + `&deleted=1`;
		}

		return query;
	}

	// onBillingPeriodChange(e, {value}) {
	//   this.setState({
	//     billingPeriodFA: value,
	//   });
	// }

	// Add
	add() {
		const {
			registeredNameFA,
			nameFA,
			addressFA,
			GSTFA,
			PANFA,
			CINFA,
			websiteFA,
			emailFA,
			phoneFA,
			// billingPeriodFA,
			stateFA,
			cityFA,
			// industryFA,
			// useCaseFA,
			// gpsPotentialFA,
			// fmsPotentialFA,
			// platformAwarenessFA,
			// preferredCommunicationChannelFA,
		} = this.state;
		let queryObj = {};

		if (registeredNameFA) queryObj = { ...queryObj, registeredName: registeredNameFA.trim() };

		if (nameFA) queryObj = { ...queryObj, name: nameFA.trim() };

		if (addressFA) queryObj = { ...queryObj, address: addressFA.trim() };

		if (GSTFA) queryObj = { ...queryObj, gst: GSTFA.trim() };

		if (PANFA) queryObj = { ...queryObj, pan: PANFA.trim() };

		if (CINFA) queryObj = { ...queryObj, cin: CINFA.trim() };

		if (websiteFA) queryObj = { ...queryObj, website: websiteFA.trim() };

		if (emailFA) queryObj = { ...queryObj, email: emailFA.trim() };

		if (phoneFA) queryObj = { ...queryObj, phone: phoneFA.trim() };

		// if (billingPeriodFA) queryObj = {...queryObj, billingPeriod: billingPeriodFA};

		if (stateFA) queryObj = { ...queryObj, state: stateFA, city: cityFA };

		// if (industryFA) queryObj = { ...queryObj, industry: industryFA };

		// if (useCaseFA) queryObj = { ...queryObj, useCase: useCaseFA.trim() };
		//
		// if (gpsPotentialFA) queryObj = { ...queryObj, gpsPotential: parseInt(gpsPotentialFA) };
		//
		// if (fmsPotentialFA) queryObj = { ...queryObj, fmsPotential: parseInt(fmsPotentialFA) };
		//
		// if (platformAwarenessFA) queryObj = { ...queryObj, platformAwareness: platformAwarenessFA };
		//
		// if (preferredCommunicationChannelFA)
		// 	queryObj = { ...queryObj, preferredCommunicationChannel: preferredCommunicationChannelFA };

		// if (selectedSaasPlans) {
		//   queryObj = {
		//     ...queryObj,
		//     saasPlans: selectedSaasPlans.map((plan) => {
		//       return {id: plan};
		//     }),
		//   };
		// }

		dispatchNoPayload('ORGANISATIONS_ADDING');

		// axios
		// 	.post(ORGANISATIONS_API, queryObj, {
		// 		headers: {
		// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 			'Content-Type': 'application/json',
		// 		},
		// 	})
		postData(ORGANISATIONS_API, '', queryObj)
			.then((response) => {
				dispatchNoPayload('ORGANISATIONS_ADDED');
				generateSuccessMessage(response);
				this.getFilteredOrganisations(this.props.pageNumber);
				this.cleanAddOperation();
			})
			.catch((error) => {
				dispatchNoPayload('ORGANISATIONS_ADDED');
				/*datadogLogs.logger.error('Organisation add api', {}, error);*/
				handleError(error);
			});
	}

	cleanAddOperation() {
		dispatchNoPayload('ORGANISATIONS_ADD_MODAL_CLOSE');

		this.setState({
			registeredNameFA: null,
			nameFA: null,
			addressFA: null,
			GSTFA: null,
			PANFA: null,
			CINFA: null,
			websiteFA: null,
			emailFA: null,
			phoneFA: null,
			saasPlansFA: null,
			selectedSaasPlans: [],
			showCustomizedPlansFA: false,
			stateFA: null,
			stateOptionFA: [],
			cityFA: null,
			cityOptionsFA: [],
			// industryFA: null,
			// useCaseFA: null,
			// gpsPotentialFA: null,
			// fmsPotentialFA: null,
			// platformAwarenessFA: null,
			// preferredCommunicationChannelFA: null,
		});
	}

	onRegistrationNameChange(e, { value }) {
		const capitalizedValue = capitalizeWord(value);

		if (this.state.nameFA === capitalizedValue && capitalizedValue !== '') {
			this.setState({
				sameRegistarionBrandName: true,
			});
		} else {
			this.setState({
				registeredNameFA: capitalizedValue,
				sameRegistarionBrandName: false,
			});
		}
	}

	onBrandNameChange(e, { value }) {
		const capitalizedValue = capitalizeWord(value);

		if (this.state.registeredNameFA === capitalizedValue && capitalizedValue !== '') {
			this.setState({
				sameRegistarionBrandName: true,
			});
		} else {
			this.setState({
				nameFA: capitalizedValue,
				sameRegistarionBrandName: false,
			});
		}
	}

	onAddressChange(e, { value }) {
		this.setState({
			addressFA: value,
		});
	}

	onGSTChange(e, { value }) {
		this.setState({
			GSTFA: value,
		});
	}

	onPANChange(e, { value }) {
		this.setState({
			PANFA: value,
		});
	}

	onCINChange(e, { value }) {
		this.setState({
			CINFA: value,
		});
	}

	onEmailChange(e) {
		this.setState({
			emailFA: e.target.value,
		});
	}

	onPhoneChange(e) {
		this.setState({
			phoneFA: e.target.value,
		});
	}

	onWebsiteChange(e) {
		this.setState({
			websiteFA: e.target.value,
		});
	}

	onSaasPlansChange(e, { value }) {
		this.setState({
			selectedSaasPlans: value,
		});
	}

	onCheckedCustomizedPlan(e, { checked }) {
		this.setState({ showCustomizedPlansFA: checked, selectedSaasPlans: [] });
		// getSaasPlansForDropdown(`?pagination=0${!checked ? '&status=1' : ''}`)
		//   .then((response) => this.setState({saasPlansFA: response}))
		//   .catch((error) => handleError(error));
	}

	onStateChangeFA(e, { value }) {
		this.setState({
			stateFA: value,
			cityOptionsFA: getCityForDropdown(STATES_AND_CITIES[value]),
			cityFA: null,
		});
	}

	onCityChangeFA(e, { value }) {
		this.setState({
			cityFA: value,
		});
	}

	// onIndustryChangeFA(e, { value }) {
	// 	this.setState({
	// 		industryFA: value,
	// 	});
	// }

	// onUseCaseChangeFA(e) {
	// 	this.setState({
	// 		useCaseFA: e.target.value,
	// 	});
	// }
	//
	// onGpsPotentialChangeFA(e, { value }) {
	// 	this.setState({
	// 		gpsPotentialFA: value,
	// 	});
	// }
	//
	// onFmsPotentialChangeFA(e, { value }) {
	// 	this.setState({
	// 		fmsPotentialFA: value,
	// 	});
	// }
	//
	// onPlatformAwarenessChangeFA(e, { value }) {
	// 	this.setState({
	// 		platformAwarenessFA: value,
	// 	});
	// }
	//
	// onPreferredCommunicationChannelChangeFA(e, { value }) {
	// 	this.setState({
	// 		preferredCommunicationChannelFA: value,
	// 	});
	// }

	// Update
	update(id) {
		const {
			registeredNameFE,
			brandNameFE,
			addressFE,
			GSTFE,
			PANFE,
			CINFE,
			websiteFE,
			emailFE,
			phoneFE,
			// billingPeriodFE,
			selectedSaasPlans,
			stateFE,
			cityFE,
			// industryFE,
			// useCaseFE,
			// gpsPotentialFE,
			// fmsPotentialFE,
			// platformAwarenessFE,
			// preferredCommunicationChannelFE,
		} = this.state;
		let queryObj = {};

		if (registeredNameFE) queryObj = { ...queryObj, registeredName: registeredNameFE.trim() };

		if (brandNameFE) queryObj = { ...queryObj, name: brandNameFE.trim() };

		queryObj = addressFE === null ? queryObj : { ...queryObj, address: addressFE.trim() };

		queryObj = GSTFE === null ? queryObj : { ...queryObj, gst: GSTFE.trim() };

		queryObj = PANFE === null ? queryObj : { ...queryObj, pan: PANFE.trim() };

		queryObj = CINFE === null ? queryObj : { ...queryObj, cin: CINFE.trim() };

		queryObj = websiteFE === null ? queryObj : { ...queryObj, website: websiteFE.trim() };

		if (emailFE) queryObj = { ...queryObj, email: emailFE.trim() };

		if (phoneFE) queryObj = { ...queryObj, phone: phoneFE.trim() };

		// if (billingPeriodFE) queryObj = {...queryObj, billingPeriod: billingPeriodFE};

		if (stateFE) {
			if (cityFE) {
				queryObj = { ...queryObj, state: stateFE, city: cityFE };
			} else {
				queryObj = { ...queryObj, state: stateFE, city: '' };
			}
		}

		if (cityFE) queryObj = { ...queryObj, city: cityFE };

		// if (industryFE) queryObj = { ...queryObj, industry: industryFE };
		//
		// if (useCaseFE) queryObj = { ...queryObj, useCase: useCaseFE.trim() };
		//
		// if (gpsPotentialFE) queryObj = { ...queryObj, gpsPotential: parseInt(gpsPotentialFE) };
		//
		// if (fmsPotentialFE) queryObj = { ...queryObj, fmsPotential: parseInt(fmsPotentialFE) };
		//
		// if (platformAwarenessFE) queryObj = { ...queryObj, platformAwareness: platformAwarenessFE };
		//
		// if (preferredCommunicationChannelFE)
		// 	queryObj = { ...queryObj, preferredCommunicationChannel: preferredCommunicationChannelFE };

		if (selectedSaasPlans.length) {
			queryObj = {
				...queryObj,
				saasPlans: selectedSaasPlans.map((plan) => {
					return { id: plan };
				}),
			};
		}
		dispatchNoPayload('ORGANISATIONS_UPDATING');
		//
		// axios
		// 	.patch(ORGANISATIONS_API + `/${id}`, queryObj, {
		// 		headers: {
		// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 		},
		// 	})
		patchData(ORGANISATIONS_API + `/${id}`, queryObj)
			.then((response) => {
				dispatchNoPayload('ORGANISATIONS_UPDATED');
				generateSuccessMessage(response);
				this.getFilteredOrganisations(this.props.pageNumber, false);

				this.cleanEditOperation();
			})
			.catch((error) => {
				dispatchNoPayload('ORGANISATIONS_UPDATED');

				handleError(error);
			});
	}

	cleanEditOperation() {
		dispatchNoPayload('ORGANISATIONS_EDIT_MODAL_CLOSE');
		dispatchNoPayload('ORGANISATIONS_SAAS_PLANS_MODAL_CLOSE');

		this.setState({
			registeredNameFE: null,
			brandNameFE: null,
			addressFE: null,
			GSTFE: null,
			PANFE: null,
			CINFE: null,
			websiteFE: null,
			emailFE: null,
			phoneFE: null,
			saasPlansFA: null,
			selectedSaasPlans: [],
			showCustomizedPlans: false,
			disableCustomizedPlans: false,
			stateFE: null,
			cityFE: null,
			// industryFE: null,
			// useCaseFE: null,
			// gpsPotentialFE: null,
			// fmsPotentialFE: null,
			// platformAwarenessFE: null,
			// preferredCommunicationChannelFE: null,
		});

		this.organisationID = '';
	}

	onSaasPlanUpdate(e, { value }) {
		this.setState({
			selectedSaasPlans: value,
		});
	}

	onChangeCustomizedPlan(e, { checked }) {
		this.setState({ showCustomizedPlans: checked, selectedSaasPlans: [] });
		// getSaasPlansForDropdown(`?pagination=0${!checked ? '&status=1' : ''}`)
		//   .then((response) => this.setState({saasPlansFA: response}))
		//   .catch((error) => handleError(error));
	}

	onStateChangeFE(e, { value }) {
		this.setState({
			stateFE: value,
			cityOptionsFE: getCityForDropdown(STATES_AND_CITIES[value]),
			cityFE: '',
		});
	}

	onCityChangeFE(e, { value }) {
		this.setState({
			cityFE: value,
		});
	}

	onRegisteredNameChangeFE(e, { value }) {
		const capitalizedValue = capitalizeWord(value);

		if (this.state.brandNameFE === capitalizedValue) {
			this.setState({
				sameRegistarionBrandName: true,
			});
		} else {
			this.setState({
				registeredNameFE: capitalizedValue,
				sameRegistarionBrandName: false,
			});
		}
	}

	onNameChangeFE(e, { value }) {
		const capitalizedValue = capitalizeWord(value);

		if (this.state.registeredNameFE === capitalizedValue) {
			this.setState({
				sameRegistarionBrandName: true,
			});
		} else {
			this.setState({
				brandNameFE: capitalizedValue,
				sameRegistarionBrandName: false,
			});
		}

		// this.setState({
		// 	brandNameFE: capitalizedValue,
		// });
	}

	// onIndustryChangeFE(e, { value }) {
	// 	this.setState({
	// 		industryFE: value,
	// 	});
	// }
	//
	// onUseCaseChangeFE(e) {
	// 	this.setState({
	// 		useCaseFE: e.target.value,
	// 	});
	// }
	//
	// onGpsPotentialChangeFE(e, { value }) {
	// 	this.setState({
	// 		gpsPotentialFE: value,
	// 	});
	// }
	//
	// onFmsPotentialChangeFE(e, { value }) {
	// 	this.setState({
	// 		fmsPotentialFE: value,
	// 	});
	// }
	//
	// onPlatformAwarenessChangeFE(e, { value }) {
	// 	this.setState({
	// 		platformAwarenessFE: value,
	// 	});
	// }
	//
	// onPreferredCommunicationChannelChangeFE(e, { value }) {
	// 	this.setState({
	// 		preferredCommunicationChannelFE: value,
	// 	});
	// }

	// Restore
	restoreOrganisation(id) {
		dispatchNoPayload('ORGANISATIONS_RESTORING');
		// axios
		// 	.post(
		// 		ORGANISATIONS_API + `/${id}/restore`,
		// 		{},
		// 		{
		// 			headers: {
		// 				Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 			},
		// 		}
		// 	)
		postData(ORGANISATIONS_API, `/${id}/restore`)
			.then((response) => {
				dispatchNoPayload('ORGANISATIONS_RESTORED');
				generateSuccessMessage(response, 'Organsation Restored');
				this.getFilteredOrganisations(this.props.pageNumber);
				this.cleanRestoreOperation();
			})
			.catch((error) => {
				dispatchNoPayload('ORGANISATIONS_RESTORED');
				/*datadogLogs.logger.error('Organisation restore api', {}, error);*/
				handleError(error);
			});
	}

	cleanRestoreOperation() {
		dispatchNoPayload('ORGANISATIONS_RESTORE_MODAL_CLOSE');

		this.organisationID = '';
	}

	// Deactivate
	deactivateOrganisation(id) {
		dispatchNoPayload('ORGANISATIONS_DEACTIVATE');
		// axios
		// 	.delete(ORGANISATIONS_API + `/${id}?reason=${this.state.reason}&deleteSims=${this.state.deleteSims ? 1 : 0}`, {
		// 		headers: {
		// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 		},
		// 	})
		deleteData(ORGANISATIONS_API, `/${id}?reason=${this.state.reason}&deleteSims=${this.state.deleteSims ? 1 : 0}`)
			.then((response) => {
				dispatchNoPayload('ORGANISATIONS_DEACTIVATED');
				generateSuccessMessage(response);
				this.getFilteredOrganisations(this.props.pageNumber);
				this.cleanDeactivateOperation();
			})
			.catch((error) => {
				dispatchNoPayload('ORGANISATIONS_DEACTIVATED');
				/*datadogLogs.logger.error('Deactivate organisation api', {}, error);*/
				handleError(error);
			});
	}

	cleanDeactivateOperation() {
		dispatchNoPayload('ORGANISATIONS_DEACTIVATE_MODAL_CLOSE');
		dispatchNoPayload('ORGANISATIONS_DEACTIVATE_CONFIRM_MODAL_CLOSE');

		this.organisationID = '';
		this.setState({
			users: [],
			vehicles: [],
			vehicleGroups: [],
			reason: null,
			deleteSims: false,
		});
	}

	closeDeactivateConfirmationModal() {
		dispatchNoPayload('ORGANISATIONS_DEACTIVATE_CONFIRM_MODAL_CLOSE');
	}

	onReasonChoose(e, { value }) {
		this.setState({ reason: value });
	}

	onCheckDeleteSims(e, { checked }) {
		this.setState({ deleteSims: checked });
	}

	sort(param, activeSort, sortOrder) {
		if (!param) {
			this.activeSort = param;
			this.sortOrder = '';
		} else {
			if (activeSort !== param) {
				this.sortOrder = 'asc';
				this.activeSort = param;
			} else {
				if (sortOrder === 'asc') {
					this.sortOrder = 'desc';
				} else {
					this.sortOrder = 'asc';
				}
			}
		}

		dispatch('ORGANISATIONS_SORT_ORDER', this.sortOrder);
		dispatch('ORGANISATIONS_ACTIVE_ORDER', this.activeSort);
		this.getFilteredOrganisations(this.props.pageNumber);
	}

	sendVerificationLink() {
		dispatchNoPayload('ORGANISATIONS_VERIFICATION_LINK_SENDING');
		// axios
		// 	.post(
		// 		VERIFIED_ASSETS_API,
		// 		{ asset: this.asset, assetType: this.assetType },
		// 		{
		// 			headers: {
		// 				Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 			},
		// 		}
		// 	)
		postData(VERIFIED_ASSETS_API, '', { asset: this.asset, assetType: this.assetType })
			.then((response) => {
				dispatchNoPayload('ORGANISATIONS_VERIFICATION_LINK_SENDED');
				generateSuccessMessage(response, 'SUCCESS');
				dispatch('ORGANISATIONS_TOGGLE_ACCORDION', -1);
				// this.getFilteredOrganisations(this.props.pageNumber, false);
				this.cleanSendVerificationOperation();
			})
			.catch((error) => {
				dispatchNoPayload('ORGANISATIONS_VERIFICATION_LINK_SENDED');
				/*datadogLogs.logger.error('Organisation verification api', {}, error);*/
				handleError(error);
			});
	}

	cleanSendVerificationOperation() {
		closeSendVerificationModal();

		this.asset = '';
		this.assetType = null;
	}

	// Pagination
	handleDataSizeLimitChange() {
		this.getFilteredOrganisations();
	}

	handlePageChange(activePage) {
		this.getFilteredOrganisations(activePage);
		this.cleanSelectedRows();
	}

	cleanSelectedRows() {
		this.setState({
			anyRowSelected: false,
			selectedRows: new Set(),
		});
	}

	componentDidUpdate(prevProps) {
		if (prevProps.organisations !== this.props.organisations) {
			if (this.selectAllActive) this.handleSelectAll(true);
		}

		if (prevProps.loading !== this.props.loading) {
			if (!this.props.loading) {
				this.setState({
					initialLoading: false,
				});
			}
		}

		if (prevProps.pageNumber !== this.props.pageNumber) {
			document.getElementById('AccordionMain').scrollTo(0, 0);
		}
	}

	componentWillUnmount() {
		dispatchNoPayload('ORGANISATIONS_UNMOUNT');
	}

	onStateChange(e, { value }) {
		let options = [];

		if (value.length) {
			value.forEach((state) => {
				options = options.concat(getCityForDropdown(STATES_AND_CITIES[state]));
			});
		}

		this.setState({
			filters: { ...this.state.filters, state: value, city: [] },
			cityOptions: options,
		});
	}

	onCityChange(e, { value }) {
		this.setState({
			filters: { ...this.state.filters, city: value },
		});
	}

	// onIndustryChange(e, { value }) {
	// 	this.setState({
	// 		filters: { ...this.state.filters, industry: value },
	// 	});
	// }
	//
	// onSolutionsChange(e, { value }) {
	// 	this.setState({
	// 		filters: { ...this.state.filters, solutions: value },
	// 	});
	// }
	//
	// onPlatformAwarenessChange(e, { value }) {
	// 	this.setState({
	// 		filters: { ...this.state.filters, platformAwareness: value },
	// 	});
	// }
	//
	// onPreferredCommunicationChannelChange(e, { value }) {
	// 	this.setState({
	// 		filters: { ...this.state.filters, preferredCommunicationChannel: value },
	// 	});
	// }

	onEmailFilterChange(e, { value }) {
		// this.setState({
		// 	filters: { ...this.state.filters, email: value },
		// });
		dispatch('ACTIVE_FILTER', { ...this.props.filters, email: value });
	}

	onPhoneFilterChange(e, { value }) {
		// this.setState({
		// 	filters: { ...this.state.filters, phone: value },
		// });
		dispatch('ACTIVE_FILTER', { ...this.props.filters, phone: value });
	}

	handleChangeDate = (event, { name, value }) => {
		if (name === 'startDate') {
			this.setState({
				filters: { ...this.state.filters, startDate: value },
			});
		}

		if (name === 'endDate') {
			this.setState({
				filters: { ...this.state.filters, endDate: value },
			});
		}

		// this.activeDateFilter = true;
	};

	handleClearDate = () => {
		this.setState({
			filters: { endDate: '' },
		});
	};

	setFilterChange(
		state,
		city,
		// industry,
		// solutions,
		// platformAwareness,
		// preferredCommunicationChannel,
		email,
		phone,
		startDate,
		endDate
	) {
		this.setState({
			filters: {
				state: state,
				city: city,
				// industry: industry,
				// solutions: solutions,
				// platformAwareness: platformAwareness,
				// preferredCommunicationChannel: preferredCommunicationChannel,
				email: email,
				phone: phone,
				startDate: startDate,
				endDate: endDate,
			},
		});
	}

	isFilterActive() {
		return (
			this.activeFilters.state.length ||
			this.activeFilters.city.length ||
			// this.activeFilters.industry.length ||
			// this.activeFilters.solutions.length ||
			// this.activeFilters.platformAwareness.length ||
			// this.activeFilters.preferredCommunicationChannel.length ||
			this.activeFilters.email ||
			this.activeFilters.phone ||
			this.activeFilters.startDate ||
			this.activeFilters.endDate
		);
	}

	handleSearchQuery(value) {
		dispatch('UPDATE_SEARCH_QUERY', value);
		this.setState({ searchQuery: value });
	}

	render() {
		return (
			<CategoryContentMembrane>
				<CategoryHeader>
					<SearchBox
						placeholder="Search in organisations"
						searchQuery={this.state.searchQuery}
						clearSearchIconClick={() => {
							this.setState({ searchQuery: '' }, () => {
								this.orgname = '';
								this.getFilteredOrganisations(this.props.pageNumber);
							});
							dispatch('UPDATE_SEARCH_QUERY', '');
						}}
						value={this.props.searchQuery}
						handleSearchQuery={this.handleSearchQuery}
						handleSearch={this.handleSearch}
						// disabled={!this.state.searchQuery && (this.props.loading || this.props.exportingData)}
					/>
					<Button
						icon
						primary
						disabled={this.props.loading || this.props.exportingData}
						title="Refresh Organisations"
						onClick={() => this.getFilteredOrganisations(this.props.pageNumber)}>
						<Icon name="refresh" />
					</Button>
					<Expander />

					{this.isSuperAdminAccess === 'true' && (
						<Button
							primary={!this.isFilterActive()}
							icon
							disabled={this.props.loading || this.props.exportingData}
							color={this.isFilterActive() ? 'orange' : null}
							title="Filter Organisations"
							onClick={openFilterModal}>
							<Icon name="filter" />
						</Button>
					)}

					{/* Add */}
					{!this.state.deletedMode && this.isSuperAdminAccess !== 'false' && (
						<Button
							primary
							disabled={this.props.loading || this.props.exportingData || this.isSuperAdminAccess === 'false'}
							title="Add Organisation"
							onClick={() => {
								trackMixpanelEvent('Organisation', { Action: 'Add' });
								dispatchNoPayload('ORGANISATIONS_ADD_MODAL_OPEN');
								// getSaasPlansForDropdown(`?pagination=0${!this.state.showCustomizedPlansFA ? '&status=1' : ''}`)
								//   .then((response) => this.setState({saasPlansFA: response}))
								//   .catch((error) => handleError(error));

								this.setState({
									stateOptionFA: getStateForDropdown(),
								});
							}}>
							Add
						</Button>
					)}

					{/*Export*/}
					{/*<Button*/}
					{/*	primary*/}
					{/*	disabled={*/}
					{/*		!this.state.anyRowSelected ||*/}
					{/*		this.props.loading ||*/}
					{/*		!this.props.organisations.length ||*/}
					{/*		this.props.exportingData*/}
					{/*	}*/}
					{/*	title={*/}
					{/*		!this.state.anyRowSelected ? 'Please select atleast one organisation to export' : 'Export Organisations'*/}
					{/*	}*/}
					{/*	onClick={() => {*/}
					{/*		openExportModal();*/}
					{/*		dispatch('SET_ORGANISATIONS_EXPORT_DATA', this.export());*/}
					{/*	}}>*/}
					{/*	Export*/}
					{/*</Button>*/}

					{/*Export All*/}

					{this.isSuperAdminAccess === 'true' && (
						<Button.Group>
							<Button
								primary
								title="Export All Organisations"
								disabled={this.props.loading || !this.props.organisations.length || this.props.exportingData}
								onClick={() => exportAll(this.getFilterListQuery())}>
								Export
							</Button>
							<Dropdown
								style={{ background: '#ffffff', color: '#1b3c71', borderColor: '#1b3c71' }}
								className="button icon"
								floating
								value={this.state.fileExtension}
								options={FILE_TYPE_EXTENSIONS}
								onChange={this.fileExtensionChange}
								trigger={<></>}
							/>
						</Button.Group>
					)}

					{this.isSuperAdminAccess === 'true' && (
						<ToggleSwitch
							title={this.deletedMode ? 'Hide Deleted Organisations' : 'Show Deleted Organisations'}
							label="Deleted Mode"
							checked={this.deletedMode}
							action={this.toggleDeletedMode}
							disabled={this.props.loading || this.props.exportingData}
						/>
					)}
				</CategoryHeader>

				<CategoryMain>
					<AccordionHeader background={this.deletedMode}>
						{/*<HeaderElement style={{ marginRight: 0 }} width="3%">*/}
						{/*	<CheckboxContainer>*/}
						{/*		<Checkbox*/}
						{/*			checked={*/}
						{/*				this.props.organisations.length !== 0 &&*/}
						{/*				this.state.selectedRows.size === this.props.organisations.length*/}
						{/*			}*/}
						{/*			indeterminate={*/}
						{/*				this.state.selectedRows.size !== 0 &&*/}
						{/*				this.state.selectedRows.size !== this.props.organisations.length*/}
						{/*			}*/}
						{/*			onClick={(e, data) => {*/}
						{/*				this.handleSelectAll(data.checked);*/}
						{/*			}}*/}
						{/*		/>*/}
						{/*	</CheckboxContainer>*/}
						{/*</HeaderElement>*/}
						<HeaderElement
							style={{ marginRight: 0 }}
							width="5%"
							onClick={() => {
								this.sort('', '', '');
							}}
							disabled={this.props.loading || this.props.exportingData || !this.props.organisations.length}>
							No.
						</HeaderElement>
						<HeaderElement
							style={{ marginRight: 0 }}
							width="15%"
							onClick={() => {
								this.sort('registeredName', this.props.activeSort, this.props.sortOrder);
							}}
							disabled={this.props.loading || this.props.exportingData || !this.props.organisations.length}
							activeSort={this.activeSort === 'registeredName'}>
							{this.props.activeSort === 'registeredName' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Registered Name
						</HeaderElement>
						<HeaderElement
							style={{ marginRight: 0 }}
							width="15%"
							onClick={() => {
								this.sort('name', this.props.activeSort, this.props.sortOrder);
							}}
							disabled={this.props.loading || this.props.exportingData || !this.props.organisations.length}
							activeSort={this.activeSort === 'name'}>
							{this.props.activeSort === 'name' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Brand Name
						</HeaderElement>
						<HeaderElement
							style={{ marginRight: 0 }}
							width="15%"
							// onClick={() => {
							//   this.sort('email', this.props.activeSort, this.props.sortOrder);
							// }}
							disabled={this.props.loading || this.props.exportingData || !this.props.organisations.length}>
							{/*{this.props.activeSort === 'email' ? (*/}
							{/*  this.props.sortOrder === 'asc' ? (*/}
							{/*    <Icon name="sort alphabet up" />*/}
							{/*  ) : (*/}
							{/*    <Icon name="sort alphabet down" />*/}
							{/*  )*/}
							{/*) : (*/}
							{/*  <Icon name="sort" />*/}
							{/*)}*/}
							Email
						</HeaderElement>
						<HeaderElement
							style={{ marginRight: 0 }}
							width="15%"
							// onClick={() => {
							//   this.sort('phone', this.props.activeSort, this.props.sortOrder);
							// }}
							disabled={this.props.loading || this.props.exportingData || !this.props.organisations.length}>
							{/*{this.props.activeSort === 'phone' ? (*/}
							{/*  this.props.sortOrder === 'asc' ? (*/}
							{/*    <Icon name="sort alphabet up" />*/}
							{/*  ) : (*/}
							{/*    <Icon name="sort alphabet down" />*/}
							{/*  )*/}
							{/*) : (*/}
							{/*  <Icon name="sort" />*/}
							{/*)}*/}
							Phone
						</HeaderElement>
						{/*<HeaderElement*/}
						{/*  width="10%"*/}
						{/*  onClick={() => {*/}
						{/*    this.sort('solutions', this.props.activeSort, this.props.sortOrder);*/}
						{/*  }}*/}
						{/*  disabled={this.props.loading || this.props.exportingData || !this.props.organisations.length}*/}
						{/*  activeSort={this.activeSort === 'solutions'}>*/}
						{/*  {this.props.activeSort === 'solutions' ? (*/}
						{/*    this.props.sortOrder === 'asc' ? (*/}
						{/*      <Icon name="sort alphabet down" />*/}
						{/*    ) : (*/}
						{/*      <Icon name="sort alphabet up" />*/}
						{/*    )*/}
						{/*  ) : (*/}
						{/*    <Icon name="sort" />*/}
						{/*  )}*/}
						{/*  Solutions*/}
						{/*</HeaderElement>*/}

						<HeaderElement
							style={{ marginRight: 0 }}
							width="15%"
							onClick={() => {
								this.sort('state', this.props.activeSort, this.props.sortOrder);
							}}
							disabled={this.props.loading || this.props.exportingData || !this.props.organisations.length}
							activeSort={this.activeSort === 'state'}>
							{this.props.activeSort === 'state' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							State
						</HeaderElement>
						<HeaderElement
							style={{ marginRight: 0 }}
							width="15%"
							onClick={() => {
								this.sort('city', this.props.activeSort, this.props.sortOrder);
							}}
							disabled={this.props.loading || this.props.exportingData || !this.props.organisations.length}
							activeSort={this.activeSort === 'city'}>
							{this.props.activeSort === 'city' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							City
						</HeaderElement>

						<HeaderElement
							style={{ marginRight: 0 }}
							width="15%"
							onClick={() => {
								this.sort('createdDate', this.props.activeSort, this.props.sortOrder);
							}}
							disabled={this.props.loading || this.props.exportingData || !this.props.organisations.length}
							activeSort={this.activeSort === 'createdDate'}>
							{this.props.activeSort === 'createdDate' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Onboarding Date
						</HeaderElement>
						{this.deletedMode && (
							<HeaderElement
								style={{ marginRight: 0 }}
								width="10%"
								disabled={this.props.loading || this.props.exportingData || !this.props.organisations.length}>
								Deleted Date
							</HeaderElement>
						)}

						{/*<HeaderElement*/}
						{/*  width="10%"*/}
						{/*  onClick={() => {*/}
						{/*    this.sort('gpsCount', this.props.activeSort, this.props.sortOrder);*/}
						{/*  }}*/}
						{/*  disabled={this.props.loading || this.props.exportingData || !this.props.organisations.length}*/}
						{/*  activeSort={this.activeSort === 'gpsCount'}>*/}
						{/*  {this.props.activeSort === 'gpsCount' ? (*/}
						{/*    this.props.sortOrder === 'asc' ? (*/}
						{/*      <Icon name="sort alphabet down" />*/}
						{/*    ) : (*/}
						{/*      <Icon name="sort alphabet up" />*/}
						{/*    )*/}
						{/*  ) : (*/}
						{/*    <Icon name="sort" />*/}
						{/*  )}*/}
						{/*  GPS Count*/}
						{/*</HeaderElement>*/}
						{/*<HeaderElement*/}
						{/*  width="10%"*/}
						{/*  onClick={() => {*/}
						{/*    this.sort('fmsCount', this.props.activeSort, this.props.sortOrder);*/}
						{/*  }}*/}
						{/*  disabled={this.props.loading || this.props.exportingData || !this.props.organisations.length}*/}
						{/*  activeSort={this.activeSort === 'fmsCount'}>*/}
						{/*  {this.props.activeSort === 'fmsCount' ? (*/}
						{/*    this.props.sortOrder === 'asc' ? (*/}
						{/*      <Icon name="sort alphabet down" />*/}
						{/*    ) : (*/}
						{/*      <Icon name="sort alphabet up" />*/}
						{/*    )*/}
						{/*  ) : (*/}
						{/*    <Icon name="sort" />*/}
						{/*  )}*/}
						{/*  FMS Count*/}
						{/*</HeaderElement>*/}
					</AccordionHeader>

					<AccordionMain id="AccordionMain">
						{this.state.initialLoading ? (
							<BasicLoader />
						) : this.props.organisations.length ? (
							<Accordion fluid>
								{this.props.organisations.map((organisation, index) => {
									return (
										<section key={index}>
											<Accordion.Title
												active={this.props.activeIndex === index}
												index={index}
												onClick={(event, data) => {
													toggleAccordion(event, data, this.props.activeIndex);
													this.cleanEditOperation();
													setTimeout(
														() =>
															this.setState({
																stateOptionFE: getStateForDropdown(),
																cityOptionsFE: getCityForDropdown(STATES_AND_CITIES[organisation.state]),
															}),
														1
													);
												}}
												style={{ padding: 0 }}>
												{/*Email*/}
												<Row>
													{/*<RowElement width="3%" style={{ marginRight: 0 }}>*/}
													{/*	<CheckboxContainer>*/}
													{/*		<Checkbox*/}
													{/*			checked={this.state.selectedRows.has(organisation.id)}*/}
													{/*			onChange={(e, data) => {*/}
													{/*				e.stopPropagation();*/}

													{/*				this.handleExportSelection(data.checked, organisation.id);*/}
													{/*			}}*/}
													{/*		/>*/}
													{/*	</CheckboxContainer>*/}
													{/*</RowElement>*/}
													<RowElement width="5%" style={{ marginRight: 0 }}>
														{1 + index + (this.props.pageNumber - 1) * this.props.dataSizeLimit}
													</RowElement>
													<RowElement
														width="16%"
														style={{
															marginRight: 0,
															textAlign: 'left',
															color: `${organisation.name ? 'black' : 'grey'}`,
														}}
														title={organisation.registeredName ? organisation.registeredName : 'Not Available'}>
														{organisation.registeredName ? organisation.registeredName : 'Not Available'}
													</RowElement>
													<RowElement
														width="16%"
														style={{
															marginRight: 0,
															textAlign: 'left',
															color: `${organisation.name ? 'black' : 'grey'}`,
														}}
														title={organisation.name ? organisation.name : 'Not Available'}>
														{organisation.name ? organisation.name : 'Not Available'}
													</RowElement>
													{organisation.email && !organisation.emailVerified ? (
														<RowElement
															width="15%"
															style={{
																textAlign: 'left',
																textOverflow: 'ellipsis',
																overflow: 'hidden',
																whiteSpace: 'nowrap',
																marginRight: 0,
															}}
															title="This email is not verified. Please click here to verify.">
															<UnVerifiedContent
																style={{
																	textAlign: 'left',
																	textOverflow: 'ellipsis',
																	overflow: 'hidden',
																	whiteSpace: 'nowrap',
																	color: 'darkgrey',
																}}>
																<button
																	style={{
																		textAlign: 'left',
																		width: '71%',
																		textOverflow: 'ellipsis',
																		overflow: 'hidden',
																		whiteSpace: 'nowrap',
																	}}
																	onClick={(e) => {
																		e.stopPropagation();
																		this.asset = organisation.email;
																		this.assetType = 2;
																		openSendVerificationModal();
																	}}>
																	{organisation.email ? organisation.email : 'Not Available'}
																</button>
															</UnVerifiedContent>
														</RowElement>
													) : (
														<RowElement
															width="15%"
															title={organisation.email ? organisation.email : 'Not Available'}
															style={{
																marginRight: 0,
																fontWeight: 'bold',
																color: `${organisation.email ? 'mediumseagreen' : 'darkgrey'}`,
																textOverflow: 'ellipsis',
																overflow: 'hidden',
																whiteSpace: 'nowrap',
																textAlign: 'left',
															}}>
															{organisation.email ? organisation.email : 'Not Available'}
														</RowElement>
													)}

													{!organisation.phoneVerified ? (
														<RowElement width="15%" title="This phone is not verified. Please click here to verify.">
															<UnVerifiedContent
																style={{
																	textOverflow: 'ellipsis',
																	overflow: 'hidden',
																	whiteSpace: 'nowrap',
																	textAlign: 'left',
																}}>
																<button
																	onClick={(e) => {
																		e.stopPropagation();
																		this.asset = organisation.phone;
																		this.assetType = 1;
																		openSendVerificationModal();
																	}}>
																	{organisation.phone ? organisation.phone : 'Not Available'}
																</button>
															</UnVerifiedContent>
														</RowElement>
													) : (
														<RowElement
															width="15%"
															title={organisation.phone ? organisation.phone : 'Not Available'}
															style={{
																fontWeight: 'bold',
																textAlign: 'left',
																color: `${organisation.phone ? 'mediumseagreen' : 'darkgrey'}`,
															}}>
															{organisation.phone ? organisation.phone : 'Not Available'}
														</RowElement>
													)}
													{/*<RowElement*/}
													{/*  width="10%"*/}
													{/*  // align="center"*/}
													{/*  title={*/}
													{/*    organisation.solutions === ORGANISATIONS_SOLUTIONS.GPS*/}
													{/*      ? 'GPS'*/}
													{/*      : organisation.solutions === ORGANISATIONS_SOLUTIONS.FMS*/}
													{/*      ? 'FMS'*/}
													{/*      : organisation.solutions === ORGANISATIONS_SOLUTIONS['GPS/FMS']*/}
													{/*      ? 'GPS/FMS'*/}
													{/*      : ''*/}
													{/*  }>*/}
													{/*  {organisation.solutions === ORGANISATIONS_SOLUTIONS.GPS && (*/}
													{/*    <Label size="small" color="blue">*/}
													{/*      GPS*/}
													{/*    </Label>*/}
													{/*  )}*/}
													{/*  {organisation.solutions === ORGANISATIONS_SOLUTIONS.FMS && (*/}
													{/*    <Label size="small" color="orange">*/}
													{/*      FMS*/}
													{/*    </Label>*/}
													{/*  )}*/}
													{/*  {organisation.solutions === ORGANISATIONS_SOLUTIONS['GPS/FMS'] && (*/}
													{/*    <Label size="small" color="green">*/}
													{/*      GPS/FMS*/}
													{/*    </Label>*/}
													{/*  )}*/}
													{/*</RowElement>*/}
													<RowElement
														width="15%"
														style={{
															marginRight: 0,
															textAlign: 'left',
															color: `${organisation.state ? 'black' : 'grey'}`,
														}}
														title={organisation.state ? organisation.state : 'Not Available'}>
														{organisation.state ? organisation.state : 'Not Available'}
													</RowElement>
													<RowElement
														width="15%"
														style={{
															marginRight: 0,
															textAlign: 'left',
															color: `${organisation.city ? 'black' : 'grey'}`,
														}}
														title={organisation.city ? organisation.city : 'Not Available'}>
														{organisation.city ? organisation.city : 'Not Available'}
													</RowElement>
													{/*<RowElement width="10%" title={organisation.gpsCount}>*/}
													{/*  {organisation.gpsCount}*/}
													{/*</RowElement>*/}
													<RowElement
														width="15%"
														style={{
															textAlign: 'left',
															color: `${organisation.createdDate ? 'black' : 'grey'}`,
														}}
														title={organisation.createdDate}>
														{isoToHumanReadableDay(organisation.createdDate)
															? isoToHumanReadableDay(organisation.createdDate)
															: 'Not Available'}
													</RowElement>
													{this.deletedMode && (
														<RowElement
															width="10%"
															style={{
																marginRight: 0,
																textAlign: 'left',
																color: `${organisation.deletedDate ? 'black' : 'grey'}`,
															}}
															title={organisation.deletedDate ? organisation.deletedDate : 'Not Available'}>
															{organisation.deletedDate ? organisation.deletedDate : 'Not Available'}
														</RowElement>
													)}
												</Row>
											</Accordion.Title>
											{this.props.activeIndex === index && (
												<Accordion.Content active={this.props.activeIndex === index}>
													<AccordionMainContent>
														<Form>
															<Form.Group widths="equal">
																<Form.Field required>
																	<label>Registered Name</label>
																	<Input
																		required
																		fluid
																		disabled={!this.props.editMode}
																		value={
																			this.state.registeredNameFE !== null
																				? this.state.registeredNameFE
																				: organisation.registeredName === null
																				? ''
																				: organisation.registeredName
																		}
																		// onChange={(e) => this.setState({ registeredNameFE: e.target.value })}
																		onChange={this.onRegisteredNameChangeFE}
																	/>
																</Form.Field>
																<Form.Field required>
																	<label>Brand Name</label>
																	<Form.Input
																		required
																		fluid
																		disabled={!this.props.editMode}
																		error={
																			this.state.sameRegistarionBrandName ||
																			organisation.registeredName == this.state.brandNameFE ||
																			this.state.brandNameFE == ''
																		}
																		value={
																			this.state.brandNameFE !== null
																				? this.state.brandNameFE
																				: organisation.name === null
																				? ''
																				: organisation.name
																		}
																		// onChange={(e) => this.setState({ brandNameFE: e.target.value })}
																		onChange={this.onNameChangeFE}
																	/>
																	{this.state.sameRegistarionBrandName ||
																		(organisation.registeredName == this.state.brandNameFE && (
																			<Label style={{ zIndex: '5' }} basic color="red" pointing>
																				Enter only if your registration name is different from brand name
																			</Label>
																		))}
																	{this.state.brandNameFE == '' && (
																		<Label style={{ zIndex: '5' }} basic color="red" pointing>
																			Brand Name cannot be empty
																		</Label>
																	)}
																</Form.Field>
																<Form.Field
																	required
																	control={Input}
																	label="Email"
																	error={
																		!validateEmail(this.state.emailFE) && {
																			content: 'Email should be valid.',
																		}
																	}>
																	<Input
																		fluid
																		disabled={!this.props.editMode}
																		value={
																			this.state.emailFE !== null
																				? this.state.emailFE
																				: organisation.email === null
																				? ''
																				: organisation.email
																		}
																		onChange={(e) => this.setState({ emailFE: e.target.value })}
																	/>
																</Form.Field>
																{/*<Form.Field>*/}
																{/*  <label>Email</label>*/}
																{/*  <Input*/}
																{/*    fluid*/}
																{/*    disabled={!this.props.editMode}*/}
																{/*    value={*/}
																{/*      this.state.emailFE !== null*/}
																{/*        ? this.state.emailFE*/}
																{/*        : organisation.email === null*/}
																{/*        ? ''*/}
																{/*        : organisation.email*/}
																{/*    }*/}
																{/*    onChange={(e) => this.setState({emailFE: e.target.value})}*/}
																{/*  />*/}
																{/*</Form.Field>*/}
																<Form.Field
																	control={Input}
																	label="Phone"
																	error={
																		!validateMobileNumber(this.state.phoneFE) && {
																			content: 'Phone number should be valid.',
																		}
																	}>
																	<Input
																		fluid
																		disabled={!this.props.editMode}
																		value={
																			this.state.phoneFE !== null
																				? this.state.phoneFE
																				: organisation.phone === null
																				? ''
																				: organisation.phone
																		}
																		onChange={(e) => this.setState({ phoneFE: e.target.value })}
																	/>
																</Form.Field>
																{/*<Form.Field>*/}
																{/*  <label>Phone</label>*/}
																{/*  <Input*/}
																{/*    fluid*/}
																{/*    disabled={!this.props.editMode}*/}
																{/*    value={*/}
																{/*      this.state.phoneFE !== null*/}
																{/*        ? this.state.phoneFE*/}
																{/*        : organisation.phone === null*/}
																{/*        ? ''*/}
																{/*        : organisation.phone*/}
																{/*    }*/}
																{/*    onChange={(e) => this.setState({phoneFE: e.target.value})}*/}
																{/*  />*/}
																{/*</Form.Field>*/}
															</Form.Group>
															<Form.Group widths="equal">
																<Form.Field
																	control={Input}
																	label="Website"
																	error={
																		!validateWebsite(this.state.websiteFE) && {
																			content: 'Website should be valid.',
																		}
																	}>
																	<Input
																		fluid
																		disabled={!this.props.editMode}
																		value={
																			this.state.websiteFE !== null
																				? this.state.websiteFE
																				: organisation.website === null
																				? ''
																				: organisation.website
																		}
																		onChange={(e) => this.setState({ websiteFE: e.target.value })}
																	/>
																</Form.Field>
																<Form.Field required>
																	<label>State</label>
																	<Dropdown
																		placeholder="State"
																		fluid
																		search
																		clearable
																		disabled={!this.props.editMode}
																		value={
																			this.state.stateFE !== null
																				? this.state.stateFE
																				: organisation.state === null
																				? ''
																				: organisation.state
																		}
																		selection
																		options={this.state.stateOptionFE}
																		onChange={this.onStateChangeFE}
																	/>
																</Form.Field>
																<Form.Field required>
																	<label>City</label>
																	<Dropdown
																		placeholder="City"
																		fluid
																		search
																		clearable
																		disabled={!this.props.editMode}
																		value={
																			this.state.cityFE !== null
																				? this.state.cityFE
																				: organisation.city === null
																				? ''
																				: organisation.city
																		}
																		selection
																		options={this.state.cityOptionsFE}
																		onChange={this.onCityChangeFE}
																	/>
																</Form.Field>

																{/*<Form.Field>*/}
																{/*  <label>Website</label>*/}
																{/*  <Input*/}
																{/*    fluid*/}
																{/*    disabled={!this.props.editMode}*/}
																{/*    value={*/}
																{/*      this.state.websiteFE !== null*/}
																{/*        ? this.state.websiteFE*/}
																{/*        : organisation.website === null*/}
																{/*        ? ''*/}
																{/*        : organisation.website*/}
																{/*    }*/}
																{/*    onChange={(e) => this.setState({websiteFE: e.target.value})}*/}
																{/*  />*/}
																{/*</Form.Field>*/}
															</Form.Group>
															<Form.Group widths="equal">
																<Form.Field>
																	<label>GST</label>
																	<Input
																		fluid
																		disabled={!this.props.editMode}
																		value={
																			this.state.GSTFE !== null
																				? this.state.GSTFE
																				: organisation.gst === null
																				? ''
																				: organisation.gst
																		}
																		onChange={(e) => this.setState({ GSTFE: e.target.value })}
																	/>
																</Form.Field>
																<Form.Field>
																	<label>PAN</label>
																	<Input
																		fluid
																		disabled={!this.props.editMode}
																		value={
																			this.state.PANFE !== null
																				? this.state.PANFE
																				: organisation.pan === null
																				? ''
																				: organisation.pan
																		}
																		onChange={(e) => this.setState({ PANFE: e.target.value })}
																	/>
																</Form.Field>
																<Form.Field>
																	<label>CIN</label>
																	<Input
																		fluid
																		disabled={!this.props.editMode}
																		value={
																			this.state.CINFE !== null
																				? this.state.CINFE
																				: organisation.cin === null
																				? ''
																				: organisation.cin
																		}
																		onChange={(e) => this.setState({ CINFE: e.target.value })}
																	/>
																</Form.Field>
															</Form.Group>
															{/*<Form.Group widths="equal">*/}
															{/*	<Form.Field>*/}
															{/*		<label>Industry</label>*/}
															{/*		<Dropdown*/}
															{/*			placeholder="Choose..."*/}
															{/*			fluid*/}
															{/*			search*/}
															{/*			selection*/}
															{/*			clearable*/}
															{/*			disabled={!this.props.editMode}*/}
															{/*			value={*/}
															{/*				this.state.industryFE !== null*/}
															{/*					? this.state.industryFE*/}
															{/*					: organisation.industry === null*/}
															{/*					? ''*/}
															{/*					: organisation.industry*/}
															{/*			}*/}
															{/*			options={INDUSTRY_OPTIONS}*/}
															{/*			onChange={this.onIndustryChangeFE}*/}
															{/*		/>*/}
															{/*	</Form.Field>*/}
															{/*	<Form.Field>*/}
															{/*		<label>Use Case</label>*/}
															{/*		<Input*/}
															{/*			fluid*/}
															{/*			disabled={!this.props.editMode}*/}
															{/*			value={*/}
															{/*				this.state.useCaseFE !== null*/}
															{/*					? this.state.useCaseFE*/}
															{/*					: organisation.useCase === null*/}
															{/*					? ''*/}
															{/*					: organisation.useCase*/}
															{/*			}*/}
															{/*			onChange={this.onUseCaseChangeFE}*/}
															{/*		/>*/}
															{/*	</Form.Field>*/}
															{/*<Form.Field>*/}
															{/*	<label>Platform Awareness</label>*/}
															{/*	<Dropdown*/}
															{/*		placeholder="Optional"*/}
															{/*		fluid*/}
															{/*		search*/}
															{/*		selection*/}
															{/*		clearable*/}
															{/*		disabled={!this.props.editMode}*/}
															{/*		value={*/}
															{/*			this.state.platformAwarenessFE !== null*/}
															{/*				? this.state.platformAwarenessFE*/}
															{/*				: organisation.platformAwareness === null*/}
															{/*				? ''*/}
															{/*				: organisation.platformAwareness*/}
															{/*		}*/}
															{/*		options={PLATFORM_AWARENESS_OPTIONS}*/}
															{/*		onChange={this.onPlatformAwarenessChangeFE}*/}
															{/*	/>*/}
															{/*</Form.Field>*/}
															{/*</Form.Group>*/}
															{/*<Form.Group widths="equal">*/}
															{/*<Form.Field>*/}
															{/*	<label>GPS Potential</label>*/}
															{/*	<Input*/}
															{/*		placeholder="Optional"*/}
															{/*		fluid*/}
															{/*		type="number"*/}
															{/*		disabled={!this.props.editMode}*/}
															{/*		value={*/}
															{/*			this.state.gpsPotentialFE !== null*/}
															{/*				? this.state.gpsPotentialFE*/}
															{/*				: organisation.gpsPotential === null*/}
															{/*				? ''*/}
															{/*				: organisation.gpsPotential*/}
															{/*		}*/}
															{/*		onChange={this.onGpsPotentialChangeFE}*/}
															{/*	/>*/}
															{/*</Form.Field>*/}
															{/*<Form.Field>*/}
															{/*	<label>FMS Potential</label>*/}
															{/*	<Input*/}
															{/*		placeholder="Optional"*/}
															{/*		fluid*/}
															{/*		type="number"*/}
															{/*		disabled={!this.props.editMode}*/}
															{/*		value={*/}
															{/*			this.state.fmsPotentialFE !== null*/}
															{/*				? this.state.fmsPotentialFE*/}
															{/*				: organisation.fmsPotential === null*/}
															{/*				? ''*/}
															{/*				: organisation.fmsPotential*/}
															{/*		}*/}
															{/*		onChange={this.onFmsPotentialChangeFE}*/}
															{/*	/>*/}
															{/*</Form.Field>*/}
															{/*<Form.Field>*/}
															{/*	<label>Preferred Communication Channel</label>*/}
															{/*	<Dropdown*/}
															{/*		placeholder="Optional"*/}
															{/*		fluid*/}
															{/*		search*/}
															{/*		selection*/}
															{/*		clearable*/}
															{/*		disabled={!this.props.editMode}*/}
															{/*		value={*/}
															{/*			this.state.preferredCommunicationChannelFE !== null*/}
															{/*				? this.state.preferredCommunicationChannelFE*/}
															{/*				: organisation.preferredCommunicationChannel === null*/}
															{/*				? ''*/}
															{/*				: organisation.preferredCommunicationChannel*/}
															{/*		}*/}
															{/*		options={PREFERRED_COMMUNICATION_CHANNEL_OPTIONS}*/}
															{/*		onChange={this.onPreferredCommunicationChannelChangeFE}*/}
															{/*	/>*/}
															{/*</Form.Field>*/}
															{/*</Form.Group>*/}
															<Form.Group widths="equal">
																<Form.Field required>
																	<label>Address</label>
																	<Input
																		fluid
																		disabled={!this.props.editMode}
																		value={
																			this.state.addressFE !== null
																				? this.state.addressFE
																				: organisation.address === null
																				? ''
																				: organisation.address
																		}
																		onChange={(e) => this.setState({ addressFE: e.target.value })}
																	/>
																</Form.Field>

																{/*<Form.Field>*/}
																{/*  <label>Billing Period</label>*/}
																{/*  <Dropdown*/}
																{/*    placeholder="Billing Period"*/}
																{/*    fluid*/}
																{/*    search*/}
																{/*    disabled={!this.props.editMode}*/}
																{/*    value={*/}
																{/*      this.state.billingPeriodFE !== null*/}
																{/*        ? this.state.billingPeriodFE*/}
																{/*        : organisation.billingPeriod === null*/}
																{/*        ? ''*/}
																{/*        : organisation.billingPeriod*/}
																{/*    }*/}
																{/*    selection*/}
																{/*    options={BillingPeriod}*/}
																{/*    onChange={(e, {value}) =>*/}
																{/*      this.setState({*/}
																{/*        billingPeriodFE: value,*/}
																{/*      })*/}
																{/*    }*/}
																{/*  />*/}
																{/*</Form.Field>*/}
															</Form.Group>
														</Form>
														{this.state.deletedMode ? (
															<Actions>
																<Button
																	primary
																	disabled={!(this.state.manageAccess || this.state.createAccess)}
																	onClick={() => {
																		trackMixpanelEvent('Organisation', { Action: 'Restore' });
																		dispatchNoPayload('ORGANISATIONS_RESTORE_MODAL_OPEN');
																		this.organisationID = organisation.id;
																	}}>
																	Restore
																</Button>
															</Actions>
														) : this.props.editMode ? (
															<Actions>
																<Button secondary onClick={this.cleanEditOperation}>
																	Cancel
																</Button>
																<Button
																	positive
																	// disabled={
																	// 	!(this.state.manageAccess || this.state.updateAccess || this.state.createAccess)
																	// }
																	loading={this.props.updating}
																	onClick={() => {
																		this.update(organisation.id);
																	}}>
																	Update
																</Button>
															</Actions>
														) : (
															<Actions>
																{this.isSuperAdminAccess === 'true' && (
																	<Button
																		primary
																		// disabled={
																		// 	!(this.state.manageAccess || this.state.updateAccess || this.state.createAccess)
																		// }
																		onClick={() => {
																			trackMixpanelEvent('Organisation', { Action: 'Edit' });
																			dispatchNoPayload('ORGANISATIONS_EDIT_MODAL_OPEN');
																		}}>
																		Edit
																	</Button>
																)}

																{/*<Button*/}
																{/*  color="teal"*/}
																{/*  title="Edit Saas Plan Organisation"*/}
																{/*  onClick={() => {*/}
																{/*    organisation.saasPlans.forEach((plan) => {*/}
																{/*      if (plan.status === 2) {*/}
																{/*        return this.setState({showCustomizedPlans: true, disableCustomizedPlans: true});*/}
																{/*      }*/}
																{/*    });*/}
																{/*    setTimeout(*/}
																{/*      () =>*/}
																{/*        getSaasPlansForDropdown(*/}
																{/*          `?pagination=0${!this.state.showCustomizedPlans ? '&status=1' : ''}`*/}
																{/*        )*/}
																{/*          .then((response) => this.setState({saasPlansFA: response}))*/}
																{/*          .catch((error) => handleError(error)),*/}
																{/*      250*/}
																{/*    );*/}
																{/*    this.setState({selectedSaasPlans: organisation.saasPlans.map((plan) => plan.id)});*/}
																{/*    dispatchNoPayload('ORGANISATIONS_SAAS_PLANS_MODAL_OPEN');*/}
																{/*    this.organisationID = organisation.id;*/}
																{/*  }}>*/}
																{/*  Edit Saas Plans*/}
																{/*</Button>*/}
																{this.isSuperAdminAccess === 'true' && (
																	<Button
																		negative
																		onClick={() => {
																			/* fetchVehicleGroups(`?organisation=${organisation.id}&pagenumber=1&pagecount=1000`)
                                                                                    .then((response) => {
                                                                                        this.setState({
                                                                                            vehicleGroups: createVehicleGroupDTO(response?.data?.data),
                                                                                        });
                                                                                    })
                                                                                    .catch((error) => {
                                                                                        handleError(error);
                                                                                    });
                                                                                fetchVehicles(`?organisation=${organisation.id}&pagenumber=1&pagecount=1000`)
                                                                                    .then((response) => {
                                                                                        this.setState({
                                                                                            vehicles: createVehicleDTO(response?.data?.data),
                                                                                        });
                                                                                    })
                                                                                    .catch((error) => {
                                                                                        handleError(error);
                                                                                    });
                                                                                fetchUsers(`?organisation=${organisation.id}&pagenumber=1&pagecount=1000`)
                                                                                    .then((response) => {
                                                                                        this.setState({
                                                                                            users: createUsersDTO(response?.data?.data),
                                                                                        });
                                                                                    })
                                                                                    .catch((error) => {
                                                                                        handleError(error);
                                                                                    });
                                                                                dispatchNoPayload('ORGANISATIONS_DEACTIVATE_MODAL_OPEN');*/
																			trackMixpanelEvent('Organisation', { Action: 'Deactivate' });
																			this.organisationID = organisation.id;
																			dispatchNoPayload('ORGANISATIONS_DEACTIVATE_CONFIRM_MODAL_OPEN');
																		}}>
																		Delete
																	</Button>
																)}
															</Actions>
														)}
													</AccordionMainContent>
												</Accordion.Content>
											)}
										</section>
									);
								})}
							</Accordion>
						) : (
							<PlaceHolder />
						)}
					</AccordionMain>
				</CategoryMain>

				<CategoryFooter background={this.deletedMode}>
					<FooterPagination
						handleDataSizeLimitChange={this.handleDataSizeLimitChange}
						pageNumber={this.props.pageNumber}
						handlePageChange={this.handlePageChange}
						count={this.props.count}
						disabled={this.props.loading || !this.props.organisations.length || this.props.exportingData}
					/>
				</CategoryFooter>

				{this.props.loading && !this.state.initialLoading && <TransitionLoader />}

				{this.props.addModalOpen && (
					<AddModal
						onClose={this.cleanAddOperation}
						open={this.props.addModalOpen}
						onRegistrationNameChange={this.onRegistrationNameChange}
						onBrandNameChange={this.onBrandNameChange}
						RegistrationName={this.state.registeredNameFA}
						BrandName={this.state.nameFA}
						sameRegistarionBrandName={this.state.sameRegistarionBrandName}
						state={this.state.stateFA}
						stateOptions={this.state.stateOptionFA}
						onStateChangeFA={this.onStateChangeFA}
						city={this.state.cityFA}
						cityOptions={this.state.cityOptionsFA}
						onCityChangeFA={this.onCityChangeFA}
						onAddressChange={this.onAddressChange}
						onGSTChange={this.onGSTChange}
						onPANChange={this.onPANChange}
						onCINChange={this.onCINChange}
						// onBillingPeriodChange={this.onBillingPeriodChange}
						email={this.state.emailFA}
						onEmailChange={this.onEmailChange}
						phone={this.state.phoneFA}
						onPhoneChange={this.onPhoneChange}
						website={this.state.websiteFA}
						onWebsiteChange={this.onWebsiteChange}
						saasPlansOptions={this.state.saasPlansFA}
						selectedSaasPlans={this.state.selectedSaasPlans}
						onSaasPlansChange={this.onSaasPlansChange}
						onCheckedCustomizedPlan={this.onCheckedCustomizedPlan}
						// industry={this.state.industryFA}
						// onIndustryChange={this.onIndustryChangeFA}
						// onUseCaseChange={this.onUseCaseChangeFA}
						// platformAwareness={this.state.platformAwarenessFA}
						// onPlatformAwarenessChange={this.onPlatformAwarenessChangeFA}
						// onGpsPotentialChange={this.onGpsPotentialChangeFA}
						// onFmsPotentialChange={this.onFmsPotentialChangeFA}
						// preferredCommunicationChannel={this.state.preferredCommunicationChannelFA}
						// onPreferredCommunicationChannelChange={this.onPreferredCommunicationChannelChangeFA}
						onCancelClick={this.cleanAddOperation}
						disabled={
							!this.state.registeredNameFA || !this.state.stateFA || !this.state.cityFA || !this.state.addressFA
						}
						onAddButtonClick={this.add}
						addButtonLoading={this.props.adding}
					/>
				)}

				{this.props.restoreModal && (
					<RestoreModal
						onClose={this.cleanRestoreOperation}
						open={this.props.restoreModal}
						onCancelClick={this.cleanRestoreOperation}
						onRestoreButtonClick={() => {
							this.restoreOrganisation(this.organisationID);
						}}
						restoreButtonLoading={this.props.restoring}
					/>
				)}

				{this.props.deactivateModal && (
					<DeactivateModal
						onClose={this.cleanDeactivateOperation}
						open={this.props.deactivateModal}
						users={this.state.users}
						vehicles={this.state.vehicles}
						vehicleGroups={this.state.vehicleGroups}
						reason={this.state.reason}
						onReasonChoose={this.onReasonChoose}
						deleteSims={this.state.deleteSims}
						onCheckDeleteSims={this.onCheckDeleteSims}
						onCancelClick={this.cleanDeactivateOperation}
						onDeactivateButtonClick={() => {
							dispatchNoPayload('ORGANISATIONS_DEACTIVATE_CONFIRM_MODAL_OPEN');
						}}
					/>
				)}

				{this.props.deactivateConfirmModal && (
					<DeactivateConfirmationModal
						onClose={this.closeDeactivateConfirmationModal}
						open={this.props.deactivateConfirmModal}
						onCancelClick={this.closeDeactivateConfirmationModal}
						onDeleteButtonClick={() => {
							this.deactivateOrganisation(this.organisationID);
						}}
						deleteButtonLoading={this.props.deactivate}
					/>
				)}

				{this.props.saasPlansModal && (
					<SaasPlanUpdateModal
						onClose={this.cleanEditOperation}
						open={this.props.saasPlansModal}
						saasPlansFA={this.state.saasPlansFA}
						selectedSaasPlans={this.state.selectedSaasPlans}
						onSaasPlanUpdate={this.onSaasPlanUpdate}
						disabled={this.state.disableCustomizedPlans}
						showCustomizedPlans={this.state.showCustomizedPlans}
						onChangeCustomizedPlan={this.onChangeCustomizedPlan}
						onCancelClick={this.cleanEditOperation}
						onUpdateButtonClick={() => {
							this.update(this.organisationID);
						}}
						updateButtonLoading={this.props.updating}
					/>
				)}

				{this.props.openSendVerificationModal && (
					<SendVerificationModal
						onClose={this.cleanSendVerificationOperation}
						open={this.props.openSendVerificationModal}
						onCancelClick={this.cleanSendVerificationOperation}
						onSendButtonClick={this.sendVerificationLink}
						sendButtonLoading={this.props.sending}
					/>
				)}

				{this.props.openExportModal && (
					<ExportModal
						open={this.props.openExportModal}
						fileName={this.state.fileName}
						fileNameChange={this.fileNameChange}
						fileExtension={this.state.fileExtension}
						fileExtensionChange={this.fileExtensionChange}
						onDownloadClick={() => exportData(this.props.exportData, this.state.fileName, this.state.fileExtension)}
					/>
				)}

				{/*Filter Modal*/}
				{this.props.filterModalOpen && (
					<FilterModal
						onClose={() => {
							this.setFilterChange(
								this.activeFilters.state,
								this.activeFilters.city,
								this.activeFilters.email,
								this.activeFilters.phone,
								// this.activeFilters.industry,
								// this.activeFilters.solutions,
								// this.activeFilters.platformAwareness,
								// this.activeFilters.preferredCommunicationChannel,
								this.activeFilters.startDate,
								this.activeFilters.endDate
							);
							closeFilterModal();
							dispatch('ACTIVE_FILTER', {
								...this.props.filters,
								organisations: [],
								email: null,
								phone: null,
							});
						}}
						open={this.props.filterModalOpen}
						state={this.state.filters.state}
						onStateChange={this.onStateChange}
						// industry={this.state.filters.industry}
						// onIndustryChange={this.onIndustryChange}
						// solutions={this.state.filters.solutions}
						// onSolutionsChange={this.onSolutionsChange}
						// platformAwareness={this.state.filters.platformAwareness}
						// onPlatformAwarenessChange={this.onPlatformAwarenessChange}
						// preferredCommunicationChannel={this.state.filters.preferredCommunicationChannel}
						// onPreferredCommunicationChannelChange={this.onPreferredCommunicationChannelChange}
						city={this.state.filters.city}
						cityOptions={this.state.cityOptions}
						onCityChange={this.onCityChange}
						startDate={this.state.filters.startDate}
						endDate={this.state.filters.endDate}
						handleChangeDate={this.handleChangeDate}
						handleClearDate={this.handleClearDate}
						email={this.props.filters.email}
						phone={this.props.filters.phone}
						onEmailFilterChange={this.onEmailFilterChange}
						onPhoneFilterChange={this.onPhoneFilterChange}
						onCancelClick={() => {
							this.setFilterChange(
								this.activeFilters.state,
								this.activeFilters.city,
								this.activeFilters.email,
								this.activeFilters.phone,
								this.activeFilters.preferredCommunicationChannel,
								this.activeFilters.startDate,
								this.activeFilters.endDate
							);
							closeFilterModal();
							dispatch('ACTIVE_FILTER', {
								...this.props.filters,
								organisations: [],
								email: null,
								phone: null,
							});
						}}
						onResetClick={() => {
							this.activeFilters = {
								state: [],
								city: [],
								// industry: [],
								// solutions: [],
								// platformAwareness: [],
								// preferredCommunicationChannel: [],
								email: null,
								phone: null,
								startDate: '',
								endDate: '',
							};
							// this.activeDateFilter = false;
							this.setFilterChange([], [], null, null, '', '');
							dispatch('ACTIVE_FILTER', {
								...this.props.filters,
								organisations: [],
								email: null,
								phone: null,
							});
							// closeFilterModal();
							this.getFilteredOrganisations();
						}}
						onApplyClick={() => {
							trackMixpanelEvent('Organisation', { Action: 'Filter' });
							this.activeFilters = this.state.filters;
							this.activeFilters.email = this.props.filters.email;
							this.activeFilters.phone = this.props.filters.phone;
							this.getFilteredOrganisations();
							closeFilterModal();
						}}
					/>
				)}
			</CategoryContentMembrane>
		);
	}
}

// Connect to Store
const mapStateToProps = (state) => ({
	organisations: state.organisations.organisations,
	loading: state.organisations.loading,
	count: state.organisations.count,
	pageNumber: state.organisations.pageNumber,
	activeIndex: state.organisations.activeIndex,
	dataSizeLimit: state.ui.dataSizeLimit,

	adding: state.organisations.adding,
	addModalOpen: state.organisations.addModalOpen,

	editMode: state.organisations.organisationsEditMode,
	updating: state.organisations.organisationsUpdating,

	activeSort: state.organisations.activeSort,
	sortOrder: state.organisations.sortOrder,

	restoreModal: state.organisations.restoreModal,
	restoring: state.organisations.restoring,

	deactivateModal: state.organisations.deactivateModal,
	deactivateConfirmModal: state.organisations.deactivateConfirmModal,
	deactivate: state.organisations.deactivate,

	saasPlansModal: state.organisations.saasPlansModal,
	saasPlanUpdating: state.organisations.saasPlanUpdating,

	openSendVerificationModal: state.organisations.openSendVerificationModal,
	sending: state.organisations.sending,
	filterModalOpen: state.organisations.filterModalOpen,

	openExportModal: state.organisations.openExportModal,
	exportData: state.organisations.exportData,
	exportingData: state.organisations.exportingData,
	searchQuery: state.vehicles.searchQuery,
	filters: state.vehicles.filters,
});

export default connect(mapStateToProps)(Organisations);
