import { DEVICES_API, EXPORT_API, LOGS_API } from '../configs/api';

import { VEHICLE_LOGS_FILE_HEADER } from '../../data/constants/general';

import { createLogsDTO } from '../dto/vehicleLogs';
import Logo from '../../ui/assets/images/logo.png';
import { dispatch, dispatchNoPayload } from './middleware';

import Store from '../../data/store/store';

import { handleError, handleSuccess } from './messaging';
import { exportToExcelWithLogo } from '../utilities/excel';

import axios from 'axios';
import { jsPDF } from 'jspdf';
import moment from 'moment/moment';
import { timeDifference } from '../utilities/date';
import fileDownload from 'js-file-download';
import { exportApi } from './services/apiRequest';
/*import { datadogLogs } from '@datadog/browser-logs';*/

export function refreshVehicleLogs(id, param = '', activePage = 1, resetOffset = false) {
	dispatchNoPayload('LOGS_LOADING');
	axios(
		LOGS_API +
			`/${id}/logs` +
			'?pagecount=' +
			getDataFromStore().dataSizeLimit +
			'&pagenumber=' +
			(resetOffset ? 1 : activePage) +
			'&startTime=' +
			getDataFromStore().initialDate +
			'&endTime=' +
			getDataFromStore().finalDate +
			param,
		{
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
				'Content-Type': 'application/json',
			},
		}
	)
		.then((response) => {
			dispatch('FEED_LOGS', createLogsDTO(response?.data?.data));
			dispatch('LOGS_SET_PAGE_COUNT', response?.data?.count || 0);
			dispatch('LOGS_SET_PAGE_NUMBER', activePage);
			handleSuccess(response);
		})
		.catch((error) => {
			dispatchNoPayload('LOGS_LOADED');
			handleError(error);
		});
}

export function refreshDeviceLogs(
	id,
	param = '',
	activePage = 1,
	resetOffset = false,
	isLiveReq = false,
	currentDate,
	endDate,
	prev
) {
	dispatchNoPayload('LOGS_LOADING');
	let URL =
		DEVICES_API +
		`/${id}/logs` +
		'?pagecount=' +
		getDataFromStore().dataSizeLimit +
		'&pagenumber=' +
		(resetOffset ? 1 : activePage) +
		param;

	if (isLiveReq) {
		URL += `&startTime=${currentDate}&endTime=${endDate}`;
	} else {
		URL += '&startTime=' + getDataFromStore().initialDate + '&endTime=' + getDataFromStore().finalDate;
	}
	axios(
		URL,

		{
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
				'Content-Type': 'application/json',
			},
		}
	)
		.then((response) => {
			let DTO = createLogsDTO(response?.data?.data);
			if (prev?.id && DTO[0]?.id && prev?.id === DTO[0]?.id) return;
			let actionType = isLiveReq ? 'APPEND_LOGS' : 'FEED_LOGS';
			dispatch(actionType, DTO);
			dispatch('LOGS_SET_PAGE_COUNT', response?.data?.count || 0);
			dispatch('LOGS_SET_PAGE_NUMBER', activePage);
			handleSuccess(response);
		})
		.catch((error) => {
			dispatchNoPayload('LOGS_LOADED');
			/*datadogLogs.logger.error('Refresh Vehicle logs api', {}, error);*/
			handleError(error);
		});
}

// Export
export function openExportModal() {
	dispatchNoPayload('LOGS_EXPORT_MODAL_OPEN');
}

export function closeExportModal() {
	dispatchNoPayload('LOGS_EXPORT_MODAL_CLOSE');
}

export function formatExcelExport(response) {
	return response.map((log) => {
		return {
			Time: `${log.timestamp} (${timeDifference(moment(log.createdDate).diff(moment(log.timestamp), 'seconds'))}})`,
			'Coordinates (Lat, Lng)': `${log.latitude}, ${log.longitude}`,
			Ignition: log.ignitionStatus ? 'On' : 'Off',
			GPS: `${
				log.gnssStatus === 0
					? 'Off'
					: log.gnssStatus === 1
					? 'On'
					: log.gnssStatus === 2
					? 'No Fix'
					: log.gnssStatus === 3
					? 'Sleep State'
					: log.gnssStatus === 4
					? 'Manual GNSS'
					: 'N/A'
			}`,
			Immobilize: log.relay ? 'Immobilized' : 'Mobilized',
			Altitude: log.altitude,
			Angle: log.angle,
			Satellites: log.satellites,
			Power:
				log.powerSupplyData.externalPowerVoltage !== 0
					? (log.powerSupplyData.externalPowerVoltage / 1000).toFixed(2) + 'Volts'
					: (log.powerSupplyData.internalPowerVoltage / 1000).toFixed(2) + 'Volts',
			'External Power': log.externalPowerStatus,

			Speed: `${log.speed}`,
			Distance: log.distance,
			'Total Odometer': log.totalOdometer,
			'Raw Fuel': log.rawFuel,
			// 'GSM Signal': log.gsmSignal,
		};
	});
}

export function exportData(data, fileName, extension) {
	if (extension === 'excel') {
		exportToExcelWithLogo(formatExcelExport(data), `${!fileName ? 'Logs' : fileName}`, VEHICLE_LOGS_FILE_HEADER);
	} else {
		convertToPdf(formatExcelExport(data), fileName);
	}

	closeExportModal();
}

export function convertToPdf(data, fileName) {
	const doc = new jsPDF({ orientation: 'l', unit: 'mm', format: 'a3' });

	let col = [
		'Time',
		'Coordinates (Lat, Lng)',
		'Ignition',
		'GPS',
		'Immobilize',
		'Altitude',
		'Angle',
		'Satellites',
		'Power',
		'External Power',
		'Speed',
		'Distance',
		'Total Odometer',
		'Raw Fuel',
		'GSM Signal',
	];
	let rows = [];

	data.forEach((log) => {
		let temp = [];
		Object.keys(log).forEach((key, index) => {
			if (col[index] === key) temp[index] = log[key];
		});

		rows.push(temp);
	});

	let header = function (data) {
		doc.addImage(Logo, 'png', data.settings.margin.left, 10, 40, 14);
	};

	doc.autoTable(col, rows, {
		margin: { top: 25 },
		beforePageContent: header,
		theme: 'grid',
	});

	doc.save(`${!fileName ? 'Logs' : fileName}`);
}

export function exportAllForVehicles(param, imei) {
	dispatchNoPayload('EXPORTING_LOGS_EXPORT_DATA');
	//
	// axios(
	// 	EXPORT_API +
	// 		'/vehicleLogs' +
	// 		`/${id}/logs` +
	// 		'?pagecount=' +
	// 		getDataFromStore().dataSizeLimit +
	// 		'&pagenumber=' +
	// 		getDataFromStore().pageNumber +
	// 		'&startTime=' +
	// 		getDataFromStore().initialDate +
	// 		'&endTime=' +
	// 		getDataFromStore().finalDate,
	// 	//param,
	//
	// 	{
	// 		headers: {
	// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 			'Content-Type': 'application/json',
	// 		},
	// 	}
	// );
	exportApi(
		EXPORT_API + `/device/${imei}/logs`,
		'?startTime=' + getDataFromStore().initialDate + '&endTime=' + getDataFromStore().finalDate + param
	)
		.then((response) => {
			const contentDisposition = response.headers['content-disposition'];
			const matches = /filename="([^"]+)"/.exec(contentDisposition);

			if (matches) {
				const fileName = matches[1];
				dispatchNoPayload('EXPORTED_LOGS_EXPORT_DATA');
				fileDownload(response?.data, fileName);
			}
			// openExportModal();
			// dispatch('SET_LOGS_EXPORT_DATA', createLogsDTO(response?.data?.data));
		})
		.catch((error) => {
			handleError(error);
			/*datadogLogs.logger.error('Vehicle logs export api', {}, error);*/
			dispatchNoPayload('EXPORTED_LOGS_EXPORT_DATA');
		});
}

// export function exportAllForDevices(id, fileExtension) {
// 	dispatchNoPayload('EXPORTING_LOGS_EXPORT_DATA');
// 	axios(
// 		EXPORT_API +
// 			'/vehicleLogs' +
// 			`/${id}/logs` +
// 			'?pagecount=' +
// 			getDataFromStore().dataSizeLimit +
// 			'&pagenumber=' +
// 			getDataFromStore().pageNumber +
// 			'&startTime=' +
// 			getDataFromStore().initialDate +
// 			'&endTime=' +
// 			getDataFromStore().finalDate,
// 		//param,
//
// 		{
// 			headers: {
// 				Authorization: 'Bearer ' + localStorage.getItem('token'),
// 				'Content-Type': 'application/json',
// 			},
// 		}
// 	)
// 		.then((response) => {
// 			// openExportModal();
// 			// dispatch('SET_LOGS_EXPORT_DATA', createLogsDTO(response?.data?.data));
// 			// dispatchNoPayload('EXPORTED_LOGS_EXPORT_DATA');
// 			fileDownload(response?.data, fileExtension);
// 		})
// 		.catch((error) => {
// 			handleError(error);
// 			dispatchNoPayload('EXPORTED_LOGS_EXPORT_DATA');
// 		});
// }

export function openFilterModal() {
	dispatchNoPayload('LOGS_FILTER_MODAL_OPEN');
}

export function closeFilterModal() {
	dispatchNoPayload('LOGS_FILTER_MODAL_CLOSE');
}

// Connect to Store

function getDataFromStore() {
	return {
		pageNumber: Store.getState().logs.pageNumber,
		dataSizeLimit: Store.getState().ui.dataSizeLimit,
		initialDate: Store.getState().dates.initialDate,
		finalDate: Store.getState().dates.finalDate,
	};
}
