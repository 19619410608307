import React, { useEffect, useState } from 'react';
import {
	exportData,
	refreshDeviceCommands,
	searchDeviceCommands,
	toggleAccordion,
} from '../../../../../logic/middleware/deviceCommands';
import { dispatch, dispatchNoPayload } from '../../../../../logic/middleware/middleware';
import axios from 'axios';
import { DEVICE_COMMANDS_API } from '../../../../../logic/configs/api';
import { generateSuccessMessage, handleError } from '../../../../../logic/middleware/messaging';
import { CategoryHeader } from '../../../styles/containers/CategoryHeader';
import { Accordion, Button, Dropdown, Icon, Label } from 'semantic-ui-react';
import { CategoryMain } from '../../../styles/containers/CategoryMain';
import { AccordionHeader } from '../../../styles/containers/AccordionHeader';
import { HeaderElement, Row, RowElement } from '../../../styles/Accordion';
import { AccordionMain } from '../../../styles/containers/AccordionMain';
// import BasicLoader from '../../blocks/molecules/loader/BasicLoader';
import PlaceHolder from '../../../blocks/molecules/place-holder/PlaceHolder';
import { CategoryFooter } from '../../../styles/containers/CategoryFooter';
import { CategoryContentMembrane } from '../../../styles/containers/CategoryContentMembrane';
import FooterPagination from '../../../blocks/molecules/pagination/Pagination';
import DeleteModal from '../../../blocks/organisms/modals/pobs/DeleteModal';
import RestoreModal from '../../../blocks/organisms/modals/pobs/RestoreModal';
import ExportModal from '../../../blocks/organisms/modals/device-commands/ExportModal';

import { connect } from 'react-redux';
// import Calendar from '../../../common/calender/standard/Calender';
import { isoToHumanReadable } from '../../../../../logic/utilities/date';
import Calendar from '../../../../common/calender/standard/Calender';
import { postData } from '../../../../../logic/middleware/services/apiRequest';
import { Container } from '../../../blocks/molecules/place-holder/PlaceHolderStyles';
/*import { datadogLogs } from '@datadog/browser-logs';*/

const BASE_URL = process.env.REACT_APP_BASE_URL;

const DeviceCommands = (props) => {
	let deviceCommandID = '';

	let searchQuery = '';

	let deletedMod = false;

	let sortOrder = '';
	let activeSort = '';

	const [initialLoading, setInitialLoading] = useState(true);
	const [responseReceived, setResponseReceived] = useState([]);
	const [fileName, setFileName] = useState('Device Commands');
	const [fileExtension, setFileExtension] = useState('pdf');
	const [initialDate, setInitialDate] = useState(null);
	const [finalDate, setFinalDate] = useState(null);
	const [pageNumber, setPageNumber] = useState(null);
	const [loading, setLoading] = useState(null);

	useEffect(() => {
		if (props.dropdownValue) {
			refreshDeviceCommands(props.dropdownValue);
		}
	}, []);

	useEffect(() => {
		setInitialDate(props.initialDate);
		setFinalDate(props.finalDate);
		setPageNumber(props.pageNumber);
		setLoading(props.loading);

		if (props.initialDate !== initialDate || props.finalDate !== finalDate) {
			getFilteredDeviceCommands();
		}

		if (loading !== props.loading) {
			if (!props.loading) {
				setInitialLoading(false);
			}
		}

		if (pageNumber !== props.pageNumber) {
			document.getElementById('AccordionMain').scrollTo(0, 0);
		}
	}, [props.initialDate, props.finalDate, props.loading, props.pageNumber]);

	useEffect(() => {
		return () => {
			dispatchNoPayload('DEVICE_COMMANDS_UNMOUNT');
		};
	}, []);

	// Fetch
	const getFilteredDeviceCommands = (activePage = 1, resetAccordion = true) => {
		if (resetAccordion) {
			dispatch('DEVICE_COMMANDS_TOGGLE_ACCORDION', -1);
		}

		let query = '';
		if (searchQuery !== '') {
			query = query + searchDeviceCommands(searchQuery);
		}

		if (activeSort !== '' && sortOrder !== '') {
			if (sortOrder === 'asc') {
				query = query + `&sortBy=${activeSort}&orderBy=${sortOrder}`;
			} else {
				query = query + `&sortBy=${activeSort}&orderBy=${sortOrder}`;
			}
		}

		if (deletedMod) {
			query = query + `&deleted=1`;
		}

		if (props.dropdownValue) {
			refreshDeviceCommands(props.dropdownValue, query, activePage);
		}
	};

	const getDevicesCommand = (e, { value }) => {
		if (value) refreshDeviceCommands(value);

		props.onChangeValue(value);
	};

	// Export
	const fileNameChange = (e) => {
		setFileName(e.target.value);
	};

	const fileExtensionChange = (e, { value }) => {
		setFileExtension(value);
	};

	/*const getFilterListQuery = () => {
        let query = '';

        query = query + `&fileType=${fileExtension}`;

        if (activeSort !== '' && sortOrder !== '') {
            if (sortOrder === 'asc') {
                query = query + `&sortBy=${activeSort}&orderBy=${sortOrder}`;
            } else {
                query = query + `&sortBy=${activeSort}&orderBy=${sortOrder}`;
            }
        }

        return query;
    };
*/
	const sort = (param, activeSort, sortOrder) => {
		if (!param) {
			activeSort = param;
			sortOrder = '';
		} else {
			if (activeSort !== param) {
				sortOrder = 'asc';
				activeSort = param;
			} else {
				if (sortOrder === 'asc') {
					sortOrder = 'desc';
				} else {
					sortOrder = 'asc';
				}
			}
		}

		dispatch('DEVICE_COMMANDS_SORT_ORDER', sortOrder);
		dispatch('DEVICE_COMMANDS_ACTIVE_ORDER', activeSort);
		getFilteredDeviceCommands(props.pageNumber);
	};

	// Pagination
	const handleDataSizeLimitChange = () => {
		getFilteredDeviceCommands();
	};

	const handlePageChange = (activePage) => {
		getFilteredDeviceCommands(activePage);
		// cleanSelectedRows();
	};

	const handlePostCommand = (command, imei, index) => {
		if (responseReceived?.length) return;

		postData(BASE_URL + `v1/device/commands`, '', {
			imei: imei,
			protocol: 5500,
			command: command,
		})
			.then((response) => {
				const updatedResponseReceived = responseReceived;
				updatedResponseReceived[imei] = response.status === 201;
				setResponseReceived((prev) => [...prev, index]);
				generateSuccessMessage(response, 'Command Sent');
			})
			.catch((error) => {
				handleError(error);
				/*datadogLogs.logger.error('Device command handle command api', {}, error);*/
			});
	};

	const cleanEditOperation = () => {
		dispatchNoPayload('DEVICE_COMMANDS_EDIT_MODAL_CLOSE');
	};

	// Delete
	const deleteDeviceCommand = () => {
		dispatchNoPayload('DEVICE_COMMANDS_DELETING');
		axios
			.delete(DEVICE_COMMANDS_API + `/${deviceCommandID}`, {
				headers: {
					Authorization: 'Bearer ' + localStorage.getItem('token'),
				},
			})
			.then((response) => {
				dispatchNoPayload('DEVICE_COMMANDS_DELETED');
				generateSuccessMessage(response);
				getFilteredDeviceCommands(props.pageNumber);
				cleanDeleteOperation();
			})
			.catch((error) => {
				dispatchNoPayload('DEVICE_COMMANDS_DELETED');
				handleError(error);
			});
	};

	const cleanDeleteOperation = () => {
		dispatchNoPayload('DEVICE_COMMANDS_DELETE_MODAL_CLOSE');

		deviceCommandID = '';
	};

	// Restore
	const restoreDeviceCommand = () => {
		dispatchNoPayload('DEVICE_COMMANDS_RESTORING');
		axios
			.post(
				DEVICE_COMMANDS_API + `/${deviceCommandID}/restore`,
				{},
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token'),
					},
				}
			)
			.then((response) => {
				dispatchNoPayload('DEVICE_COMMANDS_RESTORED');
				generateSuccessMessage(response);
				getFilteredDeviceCommands(props.pageNumber);
				cleanRestoreOperation();
			})
			.catch((error) => {
				dispatchNoPayload('DEVICE_COMMANDS_RESTORED');
				handleError(error);
			});
	};

	const cleanRestoreOperation = () => {
		dispatchNoPayload('DEVICE_COMMANDS_RESTORE_MODAL_CLOSE');

		deviceCommandID = '';
	};

	return (
		<CategoryContentMembrane>
			<CategoryHeader style={{ justifyContent: 'space-between' }}>
				<div style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
					<Calendar disabled={props.loading || props.exportingData} />
					<Dropdown
						style={{ minWidth: '23em', marginLeft: '.50em' }}
						fluid
						selection
						search
						clearable
						value={props.dropdownValue}
						options={props.deviceDropdownOptions}
						onChange={getDevicesCommand}
						placeholder="Select Device Commands"
					/>
				</div>

				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
					<Button
						icon
						primary
						title="Refresh Device Commands"
						disabled={props.loading || props.exportingData}
						onClick={() => getFilteredDeviceCommands(props.pageNumber)}>
						<Icon name="refresh" />
					</Button>
					{/*<Button.Group>*/}
					{/*	<Button*/}
					{/*		primary*/}
					{/*		title="Export All Device Commands"*/}
					{/*		loading={props.exportingData}*/}
					{/*		disabled={props.loading || !props.deviceCommands.length || props.exportingData}*/}
					{/*		onClick={() => exportAll(getFilterListQuery(), props.dropdownValue, fileExtension)}>*/}
					{/*		Export*/}
					{/*	</Button>*/}
					{/*	<Dropdown*/}
					{/*		className="button icon"*/}
					{/*		style={{ background: '#ffffff', color: '#1b3c71', borderColor: '#1b3c71' }}*/}
					{/*		floating*/}
					{/*		value={fileExtension}*/}
					{/*		options={FILE_TYPE_EXTENSIONS}*/}
					{/*		onChange={fileExtensionChange}*/}
					{/*		trigger={<></>}*/}
					{/*	/>*/}
					{/*</Button.Group>*/}
				</div>
			</CategoryHeader>

			<CategoryMain>
				{props.deviceCommands.length ? (
					<AccordionHeader background={deletedMod}>
						<HeaderElement
							width="7%"
							disabled={props.loading || props.exportingData || !props.deviceCommands.length}
							onClick={() => {
								sort('', '', '');
							}}>
							No.
						</HeaderElement>

						<HeaderElement
							width="20%"
							disabled={props.loading || props.exportingData || !props.deviceCommands.length}
							onClick={() => {
								sort('createdDate', props.activeSort, props.sortOrder);
							}}
							activeSort={activeSort === 'createdDate'}>
							{props.activeSort === 'createdDate' ? (
								props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Timestamp
						</HeaderElement>

						<HeaderElement width="20%" disabled={props.loading || props.exportingData || !props.deviceCommands.length}>
							Initiated At
						</HeaderElement>

						<HeaderElement
							width="30%"
							disabled={props.loading || props.exportingData || !props.deviceCommands.length}
							onClick={() => {
								sort('asciiCommand', props.activeSort, props.sortOrder);
							}}
							activeSort={activeSort === 'asciiCommand'}>
							{props.activeSort === 'asciiCommand' ? (
								props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Commands
						</HeaderElement>

						<HeaderElement
							width="15%"
							disabled={props.loading || props.exportingData || !props.deviceCommands.length}
							onClick={() => {
								sort('status', props.activeSort, props.sortOrder);
							}}
							activeSort={activeSort === 'status'}>
							{props.activeSort === 'status' ? (
								props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Status
						</HeaderElement>

						<HeaderElement width="30%" disabled={props.loading || props.exportingData || !props.deviceCommands.length}>
							Executed At
						</HeaderElement>

						<HeaderElement width="30%" disabled={props.loading || props.exportingData || !props.deviceCommands.length}>
							Created By
						</HeaderElement>
						<HeaderElement width="20%"></HeaderElement>
					</AccordionHeader>
				) : (
					''
				)}
				<AccordionMain id="AccordionMain">
					{initialLoading ? (
						<Container>
							<h4>Please Select Device</h4>
						</Container>
					) : props.deviceCommands.length ? (
						<Accordion fluid>
							{props.deviceCommands.map((command, index) => {
								return (
									<section key={index}>
										<Accordion.Title
											active={props.activeIndex === index}
											index={index}
											onClick={(event, data) => {
												toggleAccordion(event, data, props.activeIndex);
												cleanEditOperation();
											}}
											style={{ padding: 0 }}>
											<Row>
												<RowElement width="7%">{1 + index + (props.pageNumber - 1) * props.dataSizeLimit}</RowElement>

												<RowElement
													style={{ fontStyle: `${command?.createdDate ? 'inherit' : 'italic'}` }}
													title={isoToHumanReadable(command.createdDate) || 'N/A'}
													width="20%">
													{command.createdDate ? isoToHumanReadable(command.createdDate) : 'N/A'}
												</RowElement>
												<RowElement title={isoToHumanReadable(command.createdDate) || 'N/A'} width="20%">
													{command.createdDate ? isoToHumanReadable(command.createdDate) : 'N/A'}
												</RowElement>

												<RowElement
													width="30%"
													title={command?.asciiCommand ? command.asciiCommand : 'N/A'}
													style={{
														marginLeft: '.5rem',
														fontStyle: `${command?.asciiCommand ? 'inherit' : 'italic'}`,
														color: `${command?.asciiCommand ? 'black' : 'grey'}`,
													}}>
													{command?.asciiCommand ? command.asciiCommand : 'N/A'}
												</RowElement>

												<RowElement
													width="15%"
													title={command?.status ? command.status : 'N/A'}
													style={{
														fontStyle: `${command?.status ? 'inherit' : 'italic'}`,
														color: `${command?.status ? 'black' : 'grey'}`,
													}}>
													{(() => {
														switch (command?.status) {
															case 1:
																return 'Pipeline';
															case 2:
																return 'Sent';
															case 3:
																return 'Received';
															case 4:
																return 'Queue';
															case 5:
																return 'Failed';
															case 6:
																return 'Success';
															default:
																return 'Not Available';
														}
													})()}
												</RowElement>

												<RowElement
													width="30%"
													title={command?.modifiedDate ? isoToHumanReadable(command.modifiedDate) : 'N/A'}
													style={{
														marginLeft: '.5rem',
														color: `${command?.modifiedDate ? 'black' : 'grey'}`,
														fontStyle: `${command?.modifiedDate ? 'inherit' : 'italic'}`,
													}}>
													{command?.modifiedDate ? isoToHumanReadable(command.modifiedDate) : 'N/A'}
												</RowElement>

												<RowElement
													width="30%"
													title={command?.createdBy ? command.createdBy : 'N/A'}
													style={{
														marginLeft: '.5rem',
														color: `${command?.createdBy?.name ? 'black' : 'grey'}`,
														fontStyle: `${command?.createdBy?.name ? 'inherit' : 'italic'}`,
													}}>
													{command?.createdBy?.name ? command.createdBy?.name : 'N/A'}
												</RowElement>

												{command?.status === 2 || command?.status === 1 ? (
													<RowElement width="20%">
														<Label
															// disabled={responseReceived.includes(index)}
															size={'medium'}
															style={{ textAlign: 'center' }}
															onClick={() => {
																handlePostCommand(command.asciiCommand, command.imei, index);
															}}
															color={`${responseReceived.includes(index) ? 'green' : 'red'}`}>
															{responseReceived.includes(index) ? 'Sent' : 'Resend'}
														</Label>
													</RowElement>
												) : (
													<RowElement width="20%"></RowElement>
												)}
												{deletedMod && (
													<RowElement
														width="30%"
														title={command.deletedDate ? command.deletedDate : 'N/A'}
														style={{
															color: `${command.deletedDate ? 'black' : 'grey'}`,
															fontStyle: `${command?.deletedDate ? 'inherit' : 'italic'}`,
														}}>
														{command?.deletedDate ? command.deletedDate : 'N/A'}
													</RowElement>
												)}
											</Row>
										</Accordion.Title>
									</section>
								);
							})}
						</Accordion>
					) : (
						<PlaceHolder />
					)}
				</AccordionMain>
			</CategoryMain>

			<CategoryFooter background={deletedMod}>
				<FooterPagination
					handleDataSizeLimitChange={handleDataSizeLimitChange}
					pageNumber={props.pageNumber}
					handlePageChange={handlePageChange}
					count={props.count}
					disabled={props.loading || !props.deviceCommands.length || props.exportingData}
				/>
			</CategoryFooter>

			{props.deleteModalOpen && (
				<DeleteModal
					onClose={cleanDeleteOperation}
					open={props.deleteModalOpen}
					onCancelClick={cleanDeleteOperation}
					onDeleteButtonClick={() => {
						deleteDeviceCommand(deviceCommandID);
					}}
					deleteButtonLoading={props.deleting}
				/>
			)}

			{props.restoreModal && (
				<RestoreModal
					onClose={cleanRestoreOperation}
					open={props.restoreModal}
					onCancelClick={cleanRestoreOperation}
					onRestoreButtonClick={() => {
						restoreDeviceCommand(deviceCommandID);
					}}
					restoreButtonLoading={props.restoring}
				/>
			)}

			{props.openExportModal && (
				<ExportModal
					open={props.openExportModal}
					fileName={fileName}
					fileNameChange={fileNameChange}
					fileExtension={fileExtension}
					fileExtensionChange={fileExtensionChange}
					onDownloadClick={() => exportData(props.exportData, fileName, fileExtension)}
				/>
			)}
		</CategoryContentMembrane>
	);
};

// Connect to Store
const mapStateToProps = (state) => ({
	deviceCommands: state.deviceCommands.deviceCommands,
	loading: state.deviceCommands.loading,
	activeIndex: state.deviceCommands.activeIndex,
	count: state.deviceCommands.count,
	pageNumber: state.deviceCommands.pageNumber,
	dataSizeLimit: state.ui.dataSizeLimit,
	initialDate: state.dates.initialDate,
	finalDate: state.dates.finalDate,

	adding: state.deviceCommands.adding,
	addModalOpen: state.deviceCommands.addModalOpen,

	editMode: state.deviceCommands.editMode,
	updating: state.deviceCommands.updating,

	deleteModalOpen: state.deviceCommands.deleteModalOpen,
	deleting: state.deviceCommands.deleting,

	activeSort: state.deviceCommands.activeSort,
	sortOrder: state.deviceCommands.sortOrder,

	restoreModal: state.deviceCommands.restoreModal,
	restoring: state.deviceCommands.restoring,

	openExportModal: state.deviceCommands.openExportModal,
	exportData: state.deviceCommands.exportData,
	exportingData: state.deviceCommands.exportingData,
});

export default connect(mapStateToProps)(DeviceCommands);
