import React, { useEffect, useRef, useState } from 'react';
import { CategoryHeader } from '../../../styles/containers/CategoryHeader';
import { Accordion, Button, Confirm, Dropdown, Form, Header, Icon, Input, Segment } from 'semantic-ui-react';
import { trackMixpanelEvent } from '../../../../../logic/middleware/mixpanel';
import { dispatch, dispatchNoPayload } from '../../../../../logic/middleware/middleware';
import { CategoryContentMembrane } from '../../../styles/containers/CategoryContentMembrane';
import {
	downloadDocumentFile,
	exportAll,
	fetchDocumentFile,
	refreshFuelLogs,
	toggleAccordion,
} from '../../../../../logic/middleware/fuelLogs';
import { connect, useSelector } from 'react-redux';
import AddModal from '../../../blocks/organisms/modals/fuelLogs/AddModal';
import { getOrganisationsForDropdown, getVehicleForDropdown } from '../../../../../logic/middleware/vehicles';
import moment from 'moment';
// import axios from "axios";
import { FUEL_LOGS_API } from '../../../../../logic/configs/api';
import { generateSuccessMessage, handleError } from '../../../../../logic/middleware/messaging';

import { AccordionHeader } from '../../../styles/containers/AccordionHeader';
import { AccordionMain } from '../../../styles/containers/AccordionMain';
import { AccordionMainContent } from '../../../styles/containers/AccordionMainContent';
import { CategoryMain } from '../../../styles/containers/CategoryMain';
import { Actions, HeaderElement, Row, RowElement } from '../../../styles/Accordion';
import { CategoryFooter } from '../../../../components/styles/containers/CategoryFooter';
import FooterPagination from '../../../blocks/molecules/pagination/Pagination';
import DeleteModal from '../../../blocks/organisms/modals/fuelLogs/DeletedModal';
import PlaceHolder from '../../../blocks/molecules/place-holder/PlaceHolder';
import BasicLoader from '../../../blocks/molecules/loader/BasicLoader';
import { COLORS } from '../../../../styles/colors';
import { DateInput } from 'semantic-ui-calendar-react';
import { deleteData, patchData, postData } from '../../../../../logic/middleware/services/apiRequest';
import ExportModal from '../../../blocks/organisms/modals/devices/ExportModal';
import { exportData } from '../../../../../logic/middleware/userDocuments';
import { FILE_TYPE_EXTENSIONS } from '../../../../../data/constants/file-types';
import accessSetter from '../../../../../logic/utilities/accessSetter';
/*import { datadogLogs } from '@datadog/browser-logs';*/
import FilterModal from '../../../blocks/organisms/modals/fuelLogs/FilterModal';
import { getVehicleGroupsForDropdown, getVehiclesForDropdown } from '../../../../../logic/middleware/users';
import PdfView from '../../../../common/fileView/PDFView';
import ImageView from '../../../../common/fileView/ImageView';

import VehicleDocument from '../../../../assets/images/vehicleDocument.png';
import { Buffer } from 'buffer';
import Download from '../../../../assets/images/download.png';
import fileDownload from 'js-file-download';
import SearchBox from '../../../blocks/atoms/search-box/SearchBox';

const FuelLog = (props) => {
	let vehicleId = '';
	const updatePoint = 0;
	const calibrationValue = [];
	let newRow = false;
	const deletedMode = false;
	let debounceTimer;

	const searchQuery = useSelector((state) => state.vehicles.searchQuery);
	const [deleteId, setDeleteId] = useState('');
	const [activeFilters, setActiveFilters] = useState({ organisation: props.filters.organisations });
	const [dropdownOrganisations, setDropdownOrganisations] = useState([]);
	const [filterModalOpen, setFilterModalOpen] = useState(false);
	const [vehicleGroupDropdown, setVehicleGroupDropdown] = useState([]);
	const [activeSort, setActiveSort] = useState('timestamp');
	const [sortOrder, setSortOrder] = useState('desc');
	const [allFuelLogs] = useState(null);

	const [file, setFile] = useState(null);
	const [fileSizeError, setFileSizeError] = useState(false);
	const [organisations, setOrganisations] = useState([]);
	const [organisationsFA, setOrganisationsFA] = useState(null);
	const [openExportModal, setOpenExportModal] = useState(false);
	const [exportFileName, setExportFileName] = useState('FuelLogs');
	const [exportFileExtension, setExportFileExtension] = useState('pdf');

	const [openPreview, setOpenPreview] = useState(false);
	const [fileSource, setFileSource] = useState(null);
	const [fileType, setFileType] = useState(null);
	const [documentFileType, setDocumentFileType] = useState('');

	const [vehicle, setVehicle] = useState([]);
	const [vehicleFA, setVehicleFA] = useState([]);

	const [DateFA, setDateFA] = useState(null);
	const [DateFE, setDateFE] = useState(null);

	const [refuelQuantityFA, setRefuelQuantityFA] = useState(null);
	const [refuelQuantityFE, setRefuelQuantityFE] = useState(null);

	const [odometerFA, setOdometerFA] = useState(null);
	const [odometerFE, setOdometerFE] = useState(null);

	const [billAmountFA, setBillAmountFA] = useState(null);
	const [billAmountFE, setBillAmountFE] = useState(null);

	const [fuelTypeFA, setfuelTypeFA] = useState(null);

	const [countOfFuelSensor, setCountOfFuelSensor] = useState('');
	const [pingState, setPingState] = useState(false);

	const [openConfirmForFL, setOpenConfirmForFL] = useState(false);
	const [managePermission, setManagePermission] = useState({
		readAccess: false,
		createAccess: false,
		updateAccess: false,
		deleteAccess: false,
		manageAccess: false,
	});
	const [searchValue, setSearchValue] = useState(searchQuery);

	const fileInputRef = useRef(null);
	const MAX_FILE_SIZE = 5 * 1024 * 1024;

	// const [initialLoading, setInitialLoading] = useState(true);

	const getFilteredLogs = (activePage = 1, resetAccordion = true) => {
		if (resetAccordion) {
			dispatch('FUEL_LOGS_TOGGLE_ACCORDION', -1);
		}

		let query = '';
		if (searchQuery) {
			query = query + `&search=${searchQuery}`;
		}
		if (activeSort !== '' && sortOrder !== '') {
			if (sortOrder === 'asc') {
				query = query + `&sortBy=${activeSort}&orderBy=${sortOrder}`;
			} else {
				query = query + `&sortBy=${activeSort}&orderBy=${sortOrder}`;
			}
		}
		if (activeFilters?.organisation?.length)
			query += `&organisations[]=${activeFilters.organisation.join('&organisations[]=')}`;
		if (activeFilters?.vehicle?.length) query += `&vehicles[]=${activeFilters.vehicle.join('&vehicles[]=')}`;
		if (activeFilters?.vehicleGroup?.length)
			query += `&vehicleGroups[]=${activeFilters.vehicleGroup.join('&vehicleGroups[]=')}`;
		if (activeFilters?.startDate && activeFilters?.endDate) {
			query += `&startDate=${moment(activeFilters.startDate, 'DD-MM-YYYY').toISOString()}&endDate=${moment(
				activeFilters.endDate,
				'DD-MM-YYYY'
			)
				.endOf('day')
				.toISOString()}`;
		}

		if (vehicleId) {
			refreshFuelLogs(vehicleId, query, activePage);
		} else {
			refreshFuelLogs(allFuelLogs, query, activePage);
		}
	};

	const fetchVehiclesDropdown = ({ value }) => {
		let query;
		if (typeof value === 'number') query = `?organisations[]=${value}`;
		else query = `?organisations[]=${value && value.join('&organisations[]=')}`;
		query += '&dropdown=true';
		getVehiclesForDropdown(query).then((response) => setVehicle(response));
	};

	const manageAccessSetter = (rule, action) => {
		setManagePermission((prev) => ({ ...prev, [action]: rule }));
	};

	useEffect(() => {
		if (searchQuery) {
			dispatch('UPDATE_SEARCH_QUERY', searchQuery);
			handleSearch({ target: { value: searchQuery } });
		}
	}, []);

	useEffect(() => {
		const permissions = JSON.parse(localStorage.getItem('permissions'));

		const fuelLogsPermission = permissions?.filter((permission) => permission.subject === 'Service And Maintenance');

		if (fuelLogsPermission) {
			for (const fuelLogsPrm of fuelLogsPermission) {
				accessSetter(fuelLogsPrm?.action, manageAccessSetter);
			}
		}
		if (props.initialDate || props.finalDate) getFilteredLogs(props.pageNumber);
	}, [activeFilters, props.pageNumber, props.initialDate, props.finalDate]);

	const getFilterListQuery = () => {
		let query = '';

		query = query + `?fileType=${exportFileExtension}`;

		if (searchQuery) {
			query = query + `&search=${searchQuery}`;
		}

		if (activeSort !== '' && sortOrder !== '') {
			if (sortOrder === 'asc') {
				query = query + `&sortBy=${activeSort}&orderBy=${sortOrder}`;
			} else {
				query = query + `&sortBy=${activeSort}&orderBy=${sortOrder}`;
			}
		}
		if (props.initialDate && props.finalDate) {
			query = query + `&startDate=${props.initialDate}&endDate=${props.finalDate}`;
		}

		if (activeFilters?.organisation?.length)
			query += `&organisations[]=${activeFilters.organisation.join('&organisations[]=')}`;
		if (activeFilters?.vehicle?.length) query += `&vehicles[]=${activeFilters.vehicle.join('&vehicles[]=')}`;

		if (activeFilters?.vehicleGroup?.length)
			query += `&vehicleGroups[]=${activeFilters.vehicleGroup.join('&vehicleGroups[]=')}`;

		return query;
	};

	const sort = (param) => {
		let activeSortTemp;
		let sortOrderTemp;
		if (activeSort !== param) {
			activeSortTemp = param;
			sortOrderTemp = 'asc';
		} else {
			if (sortOrder === 'desc') {
				activeSortTemp = param;
				sortOrderTemp = 'asc';
			} else {
				activeSortTemp = param;
				sortOrderTemp = 'desc';
			}
		}

		setActiveSort(activeSortTemp);
		setSortOrder(sortOrderTemp);
		getFilteredLogs(props.pageNumber);
	};

	const handleDataSizeLimitChange = () => {
		getFilteredLogs();
	};

	const handleFileChange = (event) => {
		let value = event.target.files[0];
		if (value && value.size > MAX_FILE_SIZE) {
			setFileSizeError(true);
		} else {
			setFileSizeError(false);
			setFile(value);
		}
	};

	const handleFileReset = () => {
		fileInputRef.current.inputRef.current.value = '';
		setFile(null);
		setFileSizeError(false);
	};
	const handlePageChange = (activePage) => {
		getFilteredLogs(activePage);
	};
	const add = () => {
		let formData = new FormData();
		// let query = {};

		if (organisationsFA) {
			formData.append('organisation[id]', parseInt(organisationsFA));
		}

		if (vehicleFA) {
			formData.append('vehicle[id]', vehicleFA);
		}
		if (DateFA) {
			formData.append('timestamp', moment(DateFA, 'DD-MM-YYYY').toISOString());
		}

		if (refuelQuantityFA) {
			formData.append('refuelQuantity', refuelQuantityFA);
		}
		if (odometerFA) {
			formData.append('odometer', odometerFA);
		}

		if (billAmountFA) {
			formData.append('billAmount', billAmountFA);
		}

		if (fuelTypeFA) {
			formData.append('fuelType', fuelTypeFA);
		}

		if (file) {
			formData.append('file', file);
		}

		dispatchNoPayload('FUEL_LOGS_ADDING');
		postData(FUEL_LOGS_API, '', formData)
			.then((response) => {
				dispatchNoPayload('FUEL_LOGS_ADDED');
				generateSuccessMessage(response);
				getFilteredLogs(props.pageNumber);
				cleanAddOperation();
			})
			.catch((error) => {
				dispatchNoPayload('FUEL_LOGS_ADDED');
				/*datadogLogs.logger.error('Fuel logs add api', {}, error);*/
				handleError(error);
			});
	};

	const cleanAddOperation = () => {
		dispatchNoPayload('FUEL_LOGS_ADD_MODAL_CLOSE');

		setOrganisations([]);
		setVehicle([]);

		setDateFA(null);
		setOrganisationsFA(null);
		setVehicleFA([]);
		setRefuelQuantityFA(null);
		setOdometerFA(null);
		setBillAmountFA(null);
		setfuelTypeFA(null);
		fileInputRef.current.inputRef.current.value = '';
		setFile(null);
		setFileSizeError(false);
		setCountOfFuelSensor('');
	};

	const updateFuelLogs = (id) => {
		// let fuelPayload = {};
		let formData = new FormData();

		if (DateFE) {
			// fuelPayload = { ...fuelPayload, timestamp: moment(DateFE, 'DD-MM-YYYY').toISOString() };
			formData.append('timestamp', moment(DateFE, 'DD-MM-YYYY').toISOString());
		}

		if (refuelQuantityFE) {
			formData.append('refuelQuantity', parseFloat(refuelQuantityFE));
		}

		if (odometerFE) {
			formData.append('odometer', parseFloat(odometerFE));
		}

		if (billAmountFE) {
			formData.append('billAmount', parseFloat(billAmountFE));
		}
		if (file) {
			formData.append('file', file);
		}

		dispatchNoPayload('FUEL_LOGS_UPDATING');

		patchData(FUEL_LOGS_API + `/${id}`, formData)
			.then((response) => {
				dispatchNoPayload('FUEL_LOGS_UPDATED');
				getFilteredLogs(props.pageNumber);
				generateSuccessMessage(response);
			})
			.catch((error) => {
				dispatchNoPayload('FUEL_LOGS_UPDATED');
				/*datadogLogs.logger.error('Fuel logs update api', {}, error);*/
				handleError(error);
			});
	};
	const cleanEditOperation = () => {
		dispatchNoPayload('FUEL_LOGS_EDIT_MODAL_CLOSE');

		setDateFE(null);
		setRefuelQuantityFE(null);
		setBillAmountFE(null);
		setOdometerFE(null);
	};

	const onOrgChange = (_e, { value }) => {
		setOrganisationsFA(value);
		// setVehicleFA([]);

		getVehicleForDropdown(`?organisations[]=${value}&dropdown=true`).then((response) => {
			setVehicle(response);
		});
	};

	const onVehicleChange = (e, { value }) => {
		setVehicleFA(value);
	};

	const onDateChange = (e, { value }) => {
		setDateFA(value);
	};

	const onRefuelQuantity = (e) => {
		setRefuelQuantityFA(e.target.value);
	};

	const onOdometer = (e) => {
		setOdometerFA(e.target.value);
	};

	const onBillAmount = (e) => {
		setBillAmountFA(e.target.value);
	};

	const onFuelType = (e, { value }) => {
		setfuelTypeFA(value);
	};

	const addRow = () => {
		calibrationValue.push(new Array(countOfFuelSensor + 1).fill(''));
		pingStates();
	};

	const pingStates = () => {
		setPingState(!pingState);
	};

	const getRows = (row, index) => {
		let rows = [];
		newRow = false;
		for (let i = 0; i <= countOfFuelSensor; i++) {
			rows.push(
				<input
					key={`${index}, ${i}, ${row[i]}`}
					disabled={!props.addModalOpen}
					style={{ margin: '0 1em' }}
					defaultValue={row[i]}
					onBlur={(e) => {
						calibrationValue[index][i] = e.target.value;

						if (!newRow && calibrationValue[calibrationValue.length - 1][i]) {
							addRow();
							newRow = true;
						}
					}}
				/>
			);
		}
		return rows;
	};

	const deleteFuelLogs = (id) => {
		dispatchNoPayload('FUEL_LOGS_DELETING');
		deleteData(FUEL_LOGS_API, `/${id}`)
			.then(() => {
				dispatchNoPayload('FUEL_LOGS_DELETED');
				getFilteredLogs(props.pageNumber);
				cleanDeleteOperation();
			})
			.catch((error) => {
				dispatchNoPayload('FUEL_LOGS_DELETED');
				handleError(error);
			});
	};

	const cleanDeleteOperation = () => {
		dispatchNoPayload('FUEL_LOGS_DELETE_MODAL_CLOSE');

		setDeleteId('');
	};

	const shouldRowHighlight = (index) => {
		if (activeSort === 'iotData.Date') {
			if (sortOrder === 'desc' && index < updatePoint) {
				return true;
			}
		}
		return false;
	};

	const handleSearch = (e) => {
		setSearchValue(e.target.value);
		dispatch('UPDATE_SEARCH_QUERY', e.target.value);

		if (debounceTimer) {
			clearTimeout(debounceTimer);
		}
		debounceTimer = setTimeout(() => {
			getFilteredLogs();
		}, 500);
	};

	return (
		<CategoryContentMembrane>
			<CategoryHeader style={{ justifyContent: 'space-between' }}>
				<div>
					<SearchBox
						placeholder="Search"
						searchQuery={searchQuery}
						clearSearchIconClick={() => {
							setSearchValue('');
							getFilteredLogs(props.pageNumber);
							dispatch('UPDATE_SEARCH_QUERY', '');
						}}
						handleSearch={handleSearch}
						value={searchValue}
					/>
					<Button icon primary title="Refresh Vehicles" onClick={() => getFilteredLogs()}>
						<Icon name="refresh" />
					</Button>
				</div>

				<div>
					<Button
						primary={Object.values(activeFilters).every((value) => value.length === 0)}
						icon
						// disabled={loading}
						color={Object.values(activeFilters).some((value) => value.length > 0) ? 'orange' : null}
						title="Filter Documents"
						onClick={() => {
							getOrganisationsForDropdown('?dropdown=true').then((response) => {
								setDropdownOrganisations(response);
							});
							getVehicleGroupsForDropdown('?dropdown=true').then((response) => {
								setVehicleGroupDropdown(response);
							});
							setFilterModalOpen(true);
						}}>
						<Icon name="filter" />
					</Button>

					<Button
						primary
						disabled={!(managePermission.manageAccess || managePermission.createAccess)}
						onClick={() => {
							trackMixpanelEvent('Users', { Action: 'Add' });
							dispatchNoPayload('FUEL_LOGS_ADD_MODAL_OPEN');
							getOrganisationsForDropdown('?dropdown=true').then((response) => {
								setOrganisations(response);
							});
						}}>
						Add
					</Button>
					{/*onClick={() => exportAll(this.getFilterListQuery(), this.state.fileExtension)}>*/}

					<Button.Group>
						<Button
							primary
							title="Export Fuel Logs"
							disabled={
								props.loading ||
								!props.fuelLogs?.length ||
								!(managePermission.manageAccess || managePermission.readAccess)
							}
							onClick={() => {
								exportAll(getFilterListQuery(), exportFileExtension);
							}}>
							Export
						</Button>
						<Dropdown
							className="button icon"
							style={{ background: '#ffffff', color: '#1b3c71', borderColor: '#1b3c71' }}
							floating
							value={exportFileExtension}
							options={FILE_TYPE_EXTENSIONS}
							onChange={(e, { value }) => {
								setExportFileExtension(value);
							}}
							trigger={<></>}
						/>
					</Button.Group>
				</div>
			</CategoryHeader>

			<CategoryMain>
				<AccordionHeader background={deletedMode}>
					<HeaderElement
						width="5%"
						disabled={props.loading || !props.fuelLogs.length}
						style={{
							cursor: 'pointer',
							// marginRight: 0,
							// margin:"5px",
							// padding: '2px',
							// textAlign: 'center',
							// display: 'flex',
							// flexDirection: 'row',
							// justifyContent: 'center',
						}}>
						No.
					</HeaderElement>
					<HeaderElement
						collapsing
						width="9.5%"
						style={{
							cursor: 'pointer',
							// marginRight: 0,
							// margin:"5px",
							//padding: '2px',
							// textAlign: 'center',
							// display: 'flex',
							// flexDirection: 'row',
							// justifyContent: 'center',
						}}
						disabled={props.loading || !props.fuelLogs.length}>
						Organisation
					</HeaderElement>
					<HeaderElement
						collapsing
						width="9.5%"
						disabled={props.loading || !props.fuelLogs.length}
						style={{
							cursor: 'pointer',
							// marginRight: 0,
							// margin:"5px",
							// padding: '2px',
							// textAlign: 'center',
							// display: 'flex',
							// flexDirection: 'row',
							// justifyContent: 'center',
							color: activeSort === 'vehicles' ? COLORS.activeSortColor : '',
						}}
						onClick={() => {
							sort('vehicles');
						}}
						activeSort={activeSort === 'vehicles'}>
						{activeSort === 'vehicles' ? (
							sortOrder === 'asc' ? (
								<Icon name="sort alphabet down" />
							) : (
								<Icon name="sort alphabet up" />
							)
						) : (
							<Icon name="sort" />
						)}
						Vehicle
					</HeaderElement>
					<HeaderElement
						collapsing
						width="9.5%"
						disabled={props.loading || !props.fuelLogs.length}
						onClick={() => sort('timestamp')}
						style={{
							cursor: 'pointer',
							// marginRight: 0,
							// margin:"5px",
							// padding: '2px',
							// textAlign: 'center',
							color: activeSort === 'timestamp' ? COLORS.activeSortColor : '',
						}}
						activeSort={activeSort === 'timestamp'}>
						{activeSort === 'timestamp' ? (
							sortOrder === 'asc' ? (
								<Icon name="sort alphabet up" />
							) : (
								<Icon name="sort alphabet down" />
							)
						) : (
							<Icon name="sort" />
						)}
						Date
					</HeaderElement>
					<HeaderElement
						collapsing
						width="9.5%"
						disabled={props.loading || !props.fuelLogs.length}
						style={{
							cursor: 'pointer',
							// marginRight: 0,
							// margin:"5px",
							// padding: '2px',
							// textAlign: 'center',
							// display: 'flex',
							// flexDirection: 'row',
							// justifyContent: 'center',
							color: activeSort === 'odometer' ? COLORS.activeSortColor : '',
						}}
						onClick={() => sort('odometer')}
						activeSort={activeSort === 'odometer'}>
						{activeSort === 'odometer' ? (
							sortOrder === 'asc' ? (
								<Icon name="sort alphabet up" />
							) : (
								<Icon name="sort alphabet down" />
							)
						) : (
							<Icon name="sort" />
						)}
						Odo(Km)
					</HeaderElement>
					<HeaderElement
						collapsing
						width="9.5%"
						disabled={props.loading || !props.fuelLogs.length}
						style={{
							cursor: 'pointer',
							// marginRight: 0,
							// margin:"5px",
							// padding: '2px',
							// textAlign: 'center',
							// display: 'flex',
							// flexDirection: 'row',
							// justifyContent: 'center',
							color: activeSort === 'refuelQuantity' ? COLORS.activeSortColor : '',
						}}
						onClick={() => sort('refuelQuantity')}
						activeSort={activeSort === 'refuelQuantity'}>
						{activeSort === 'refuelQuantity' ? (
							sortOrder === 'asc' ? (
								<Icon name="sort alphabet up" />
							) : (
								<Icon name="sort alphabet down" />
							)
						) : (
							<Icon name="sort" />
						)}
						Refuel (L)
					</HeaderElement>
					<HeaderElement
						collapsing
						width="9.5%"
						disabled={props.loading || !props.fuelLogs.length}
						style={{
							cursor: 'pointer',
							// marginRight: 0,
							// margin:"5px",
							// padding: '2px',
							// textAlign: 'center',
							// display: 'flex',
							// flexDirection: 'row',
							// justifyContent: 'center',
							color: activeSort === 'billAmount' ? COLORS.activeSortColor : '',
						}}
						onClick={() => sort('billAmount')}
						activeSort={activeSort === 'billAmount'}>
						{activeSort === 'billAmount' ? (
							sortOrder === 'asc' ? (
								<Icon name="sort alphabet up" />
							) : (
								<Icon name="sort alphabet down" />
							)
						) : (
							<Icon name="sort" />
						)}
						Bill(₹)
					</HeaderElement>
					<HeaderElement
						collapsing
						width="9.5%"
						disabled={props.loading || !props.fuelLogs.length}
						style={{
							cursor: 'pointer',
							// marginRight: 0,
							// margin:"5px",
							// padding: '2px',
							// textAlign: 'center',
							// display: 'flex',
							// flexDirection: 'row',
							// justifyContent: 'center',
							color: activeSort === 'mileage' ? COLORS.activeSortColor : '',
						}}
						onClick={() => sort('mileage')}
						activeSort={activeSort === 'mileage'}>
						{activeSort === 'mileage' ? (
							sortOrder === 'asc' ? (
								<Icon name="sort alphabet up" />
							) : (
								<Icon name="sort alphabet down" />
							)
						) : (
							<Icon name="sort" />
						)}
						Mileage(Km/L)
					</HeaderElement>
					<HeaderElement
						collapsing
						width="9.5%"
						disabled={props.loading || !props.fuelLogs.length}
						style={{
							cursor: 'pointer',
							// marginRight: 0,
							// margin:"5px",
							// padding: '2px',
							// textAlign: 'center',
							// display: 'flex',
							// flexDirection: 'row',
							// justifyContent: 'center',
							color: activeSort === 'fuelPrice' ? COLORS.activeSortColor : '',
						}}>
						Fuel Price(₹)
					</HeaderElement>
					<HeaderElement
						collapsing
						width="9.5%"
						disabled={props.loading || !props.fuelLogs.length}
						style={{
							cursor: 'pointer',
							// marginRight: 0,
							// margin:"5px",
							// padding: '2px',
							// textAlign: 'center',
							// display: 'flex',
							// flexDirection: 'row',
							// justifyContent: 'center',
							color: activeSort === 'odometerDistanceDifference' ? COLORS.activeSortColor : '',
						}}>
						Change In Odo
					</HeaderElement>

					<HeaderElement
						width="9.5%"
						// style={{
						// 	textAlign: 'center',
						// 	padding: '2px',
						// 	display: 'flex',
						// 	flexDirection: 'row',
						// 	justifyContent: 'center',
						// }}
						disabled={props.loading || !props.fuelLogs.length}>
						View/ Download
					</HeaderElement>
				</AccordionHeader>
				<AccordionMain id="AccordionMain">
					{props.loading ? (
						<BasicLoader />
					) : props.fuelLogs.length ? (
						<Accordion fluid>
							{props.fuelLogs &&
								props.fuelLogs.map((fuelLog, index) => {
									return (
										<section key={index}>
											<Accordion.Title
												active={props.activeIndex === index}
												index={index}
												onClick={(event, data) => {
													toggleAccordion(event, data, props.activeIndex);
													cleanEditOperation();
												}}
												style={{ padding: 0 }}>
												<Row
													key={index}
													style={
														shouldRowHighlight(index)
															? {
																	backgroundColor: '#feefe3',
																	fontWeight: 'bold',
																	color: '#f37400',
															  }
															: {}
													}>
													<RowElement
														width="5%"
														// style={{
														// 	padding: '2px',
														// 	textAlign: 'center',
														// 	display: 'flex',
														// 	flexDirection: 'row',
														// 	justifyContent: 'center',
														// }}
													>
														{1 + index + (props.pageNumber - 1) * props.dataSizeLimit}
													</RowElement>

													<RowElement
														width="9.5%"
														// style={{
														// 	padding: '2px',
														// 	textAlign: 'left',
														// 	display: 'flex',
														// 	flexDirection: 'row',
														// 	justifyContent: 'left',
														// 	textWrap:'nowrap'
														// }}
														title={fuelLog.organisation.name ? fuelLog.organisation.name : 'Not Available'}>
														{fuelLog.organisation.name ? fuelLog.organisation.name : 'Not Available'}
													</RowElement>
													<RowElement
														width="9.5%"
														// style={{
														// 	padding: '2px',
														// 	textAlign: 'center',
														// 	display: 'flex',
														// 	flexDirection: 'row',
														// 	justifyContent: 'center',
														// }}
														title={
															fuelLog.vehicle.registrationNumber ? fuelLog.vehicle.registrationNumber : 'Not Available'
														}>
														{fuelLog.vehicle.registrationNumber ? fuelLog.vehicle.registrationNumber : 'Not Available'}
													</RowElement>
													<RowElement
														width="9.5%"
														// style={{
														// 	padding: '2px',
														// 	textAlign: 'center',
														// 	display: 'flex',
														// 	flexDirection: 'row',
														// 	justifyContent: 'center',
														// }}
														title={fuelLog.timestamp ? fuelLog.timestamp : 'Not Available'}>
														{fuelLog.timestamp}
													</RowElement>
													<RowElement
														width="9.5%"
														// style={{
														// 	padding: '2px',
														// 	textAlign: 'center',
														// 	display: 'flex',
														// 	flexDirection: 'row',
														// 	justifyContent: 'center',
														// }}
														title={fuelLog.odometer ? fuelLog.odometer : 'Not Available'}>
														{fuelLog.odometer.toLocaleString('en-IN')}
													</RowElement>
													<RowElement
														width="9.5%"
														// style={{
														// 	padding: '2px',
														// 	textAlign: 'center',
														// 	display: 'flex',
														// 	flexDirection: 'row',
														// 	justifyContent: 'center',
														// }}
														title={fuelLog.refuelQuantity ? fuelLog.refuelQuantity : 'Not Available'}>
														{Number(fuelLog.refuelQuantity.toFixed(2)).toLocaleString('en-IN')}
													</RowElement>
													<RowElement
														width="9.5%"
														// style={{
														// 	padding: '2px',
														// 	textAlign: 'center',
														// 	display: 'flex',
														// 	flexDirection: 'row',
														// 	justifyContent: 'center',
														// }}
														title={fuelLog.billAmount ? fuelLog.billAmount : 'Not Available'}>
														{Number(fuelLog.billAmount.toFixed(2)).toLocaleString('en-IN')}
													</RowElement>
													<RowElement
														width="9.5%"
														// style={{
														// 	padding: '2px',
														// 	textAlign: 'center',
														// 	display: 'flex',
														// 	flexDirection: 'row',
														// 	justifyContent: 'center',
														// }}
														title={fuelLog.mileage ? fuelLog.mileage : 'Not Available'}>
														{Number(fuelLog.mileage.toFixed(2)).toLocaleString('en-IN')}
													</RowElement>
													<RowElement
														width="9.5%"
														// style={{
														// 	padding: '2px',
														// 	textAlign: 'center',
														// 	display: 'flex',
														// 	flexDirection: 'row',
														// 	justifyContent: 'center',
														// }}
														title={fuelLog.fuelPrice ? fuelLog.fuelPrice : 'Not Available'}>
														{Number(fuelLog.fuelPrice.toFixed(2)).toLocaleString('en-IN')}
													</RowElement>
													<RowElement
														width="9.5%"
														// style={{
														// 	padding: '2px',
														// 	textAlign: 'center',
														// 	display: 'flex',
														// 	flexDirection: 'row',
														// 	justifyContent: 'center',
														// }}
														title={
															fuelLog.odometerDistanceDifference ? fuelLog.odometerDistanceDifference : 'Not Available'
														}>
														{fuelLog.odometerDistanceDifference?.toLocaleString('en-IN')}
													</RowElement>

													<RowElement
														width="9.5%"
														// style={{
														// 	padding: '2px',
														// 	textAlign: 'center',
														// 	display: 'flex',
														// 	flexDirection: 'row',
														// 	justifyContent: 'center',
														// }}
													>
														{fuelLog.fileExists && (
															<>
																<img
																	alt=""
																	title={'Click to view Document'}
																	src={VehicleDocument}
																	onClick={(e) => {
																		dispatchNoPayload('FUEL_LOGS_LOADING');
																		e.stopPropagation();
																		fetchDocumentFile(fuelLog.id)
																			.then((response) => {
																				setOpenPreview(true);
																				dispatchNoPayload('LOGS_FUEL_LOADED');
																				let extension = response.headers['content-type'].split('/').at(-1);
																				let value = fuelLog.vehicle.registrationNumber
																					? `${fuelLog.vehicle.registrationNumber?.replaceAll(' ', '')}.${extension}`
																					: '';
																				setDocumentFileType(value);
																				if (response.headers['content-type'] === 'application/pdf')
																					setFileSource(response.data);
																				else setFileSource(Buffer.from(response.data, 'binary').toString('base64'));
																				setFileType(response.headers['content-type']);
																			})
																			.catch((error) => {
																				dispatchNoPayload('LOGS_FUEL_LOADED');
																				handleError(error);
																				setOpenPreview(false);
																			});
																	}}
																/>
																<img
																	alt=""
																	title="Download Document"
																	src={Download}
																	onClick={(e) => {
																		e.stopPropagation();
																		downloadDocumentFile(fuelLog.id)
																			.then((response) => {
																				let extension = response.headers['content-type'].split('/').at(-1);
																				fileDownload(
																					response.data,
																					`${fuelLog.documentType}_${fuelLog.vehicle.registrationNumber}.${extension}`
																				);
																			})
																			.catch((error) => {
																				handleError(error);
																				setOpenPreview(false);
																			});
																	}}
																/>
															</>
														)}
													</RowElement>
												</Row>
											</Accordion.Title>

											{props.activeIndex === index && (
												<Accordion.Content active={props.activeIndex === index}>
													<AccordionMainContent>
														<Header as="h3" color="black" attached="top">
															Fuel-Logs
														</Header>
														<Segment attached>
															<Form>
																<Form.Group widths="equal">
																	<Form.Field>
																		<label>Date</label>
																		<DateInput
																			fluid
																			value={
																				DateFE !== null ? DateFE : fuelLog.timestamp == null ? '' : fuelLog.timestamp
																			}
																			disabled={!props.editMode}
																			onChange={(e, data) => {
																				setDateFE(data.value);
																			}}
																		/>
																	</Form.Field>
																	<Form.Field>
																		<label>Refuel(L)</label>
																		<Input
																			type="number"
																			fluid
																			value={
																				refuelQuantityFE !== null
																					? refuelQuantityFE
																					: fuelLog.refuelQuantity === null
																					? ''
																					: fuelLog.refuelQuantity
																			}
																			disabled={!props.editMode}
																			onChange={(e) => setRefuelQuantityFE(parseFloat(e.target.value))}
																		/>
																	</Form.Field>
																	<Form.Field>
																		<label>Odometer(KM)</label>
																		<Input
																			type="number"
																			fluid
																			value={
																				odometerFE !== null
																					? odometerFE
																					: fuelLog.odometer === null
																					? ''
																					: fuelLog.odometer
																			}
																			disabled={!props.editMode}
																			onChange={(e) => setOdometerFE(parseFloat(e.target.value))}
																		/>
																	</Form.Field>
																	<Form.Field>
																		<label>Bill Amount (INR)</label>
																		<Input
																			type="number"
																			fluid
																			value={
																				billAmountFE !== null
																					? billAmountFE
																					: fuelLog.billAmount === null
																					? ''
																					: fuelLog.billAmount
																			}
																			disabled={!props.editMode}
																			onChange={(e) => setBillAmountFE(parseFloat(e.target.value))}
																		/>
																	</Form.Field>
																</Form.Group>
																<Form.Group>
																	<Form.Field style={{ width: '25%' }}>
																		<label>Upload</label>
																		<Input
																			ref={fileInputRef}
																			disabled={!props.editMode}
																			type="file"
																			error={
																				fileSizeError && {
																					content: 'Document upload size should not exceed 5MB',
																				}
																			}
																			accept=".jpg, .png, .pdf"
																			{...(props.file && {
																				icon: <Icon name="times circle outline" link onClick={handleFileReset} />,
																			})}
																			fluid
																			onChange={(e) => handleFileChange(e)}
																		/>
																	</Form.Field>
																</Form.Group>
															</Form>
														</Segment>
														<Actions>
															<section>
																{!props.editMode ? (
																	<>
																		<Button
																			primary
																			onClick={() => {
																				dispatchNoPayload('FUEL_LOGS_EDIT_MODAL_OPEN');
																			}}>
																			Edit
																		</Button>
																		<Button
																			negative
																			onClick={() => {
																				dispatchNoPayload('FUEL_LOGS_DELETE_MODAL_OPEN');
																				setDeleteId(fuelLog.id);
																			}}>
																			Delete
																		</Button>
																	</>
																) : (
																	<>
																		<Button
																			secondary
																			onClick={() => {
																				cleanEditOperation();
																			}}>
																			Cancel
																		</Button>
																		<Button
																			positive
																			loading={props.updating}
																			onClick={() => {
																				setOpenConfirmForFL(true);
																			}}>
																			Update
																		</Button>

																		<Confirm
																			content="Are you sure, you want to update the changes?"
																			open={openConfirmForFL}
																			onCancel={() => setOpenConfirmForFL(false)}
																			onConfirm={() => {
																				updateFuelLogs(fuelLog.id);
																				setOpenConfirmForFL(false);
																			}}
																			size="mini"
																		/>
																	</>
																)}
															</section>
														</Actions>
													</AccordionMainContent>
												</Accordion.Content>
											)}
										</section>
									);
								})}
						</Accordion>
					) : (
						<PlaceHolder />
					)}
				</AccordionMain>
			</CategoryMain>

			<CategoryFooter background={deletedMode}>
				<FooterPagination
					handleDataSizeLimitChange={handleDataSizeLimitChange}
					pageNumber={props.pageNumber}
					handlePageChange={(e) => handlePageChange(e)}
					count={props.count}
					disabled={props.loading || !props.fuelLogs.length}
				/>
			</CategoryFooter>
			<AddModal
				onClose={cleanAddOperation}
				open={props.addModalOpen}
				organisationsOptions={organisations}
				vehicleOptions={vehicle}
				DateFA={DateFA}
				onRefuelQuantity={onRefuelQuantity}
				onDateChnage={onDateChange}
				onCancelClick={cleanAddOperation}
				onOrgChange={onOrgChange}
				organistaionValue={organisationsFA}
				onVehicleChange={onVehicleChange}
				onOdometer={onOdometer}
				onFuelType={onFuelType}
				onBillAmount={onBillAmount}
				calibrationRows={getRows}
				onAddButtonClick={add}
				handleFileChange={handleFileChange}
				handleFileReset={handleFileReset}
				fileSizeError={fileSizeError}
				file={file}
				fileInputRef={fileInputRef}
				disabled={
					!organisationsFA || !vehicleFA || !DateFA || !refuelQuantityFA || !odometerFA || !billAmountFA || !fuelTypeFA
				}
				addButtonLoading={props.adding}
			/>
			{filterModalOpen && (
				<FilterModal
					open={filterModalOpen}
					onClose={() => setFilterModalOpen(false)}
					organisationDropdown={dropdownOrganisations}
					handleOrgChange={fetchVehiclesDropdown}
					// handleVGChange={fetchVehiclesVGDropdown}
					vehicleGroupDropdown={vehicleGroupDropdown}
					vehicleOptions={vehicle}
					activeFilters={activeFilters}
					onCancel={() => setFilterModalOpen(false)}
					handleOnApply={(filterValues) => {
						trackMixpanelEvent('Fuel Logs', { Action: 'Filter' });
						setActiveFilters(filterValues);
						setFilterModalOpen(false);
					}}
					onResetClick={() => {
						setActiveFilters({});
					}}
				/>
			)}

			{openPreview &&
				(fileType === 'application/pdf' ? (
					<PdfView
						source={fileSource}
						onClose={() => {
							setOpenPreview(false);
							setFileType(null);
							setFileSource(null);
						}}
						documentFileType={documentFileType}
					/>
				) : (
					<ImageView
						visible={openPreview}
						source={fileSource}
						onClose={() => {
							setOpenPreview(false);
							setFileType(null);
							setFileSource(null);
						}}
						documentFileType={documentFileType}
					/>
				))}

			{props.deleteModalOpen && (
				<DeleteModal
					onClose={cleanDeleteOperation}
					open={props.deleteModalOpen}
					onCancelClick={cleanDeleteOperation}
					onDeleteButtonClick={() => {
						deleteFuelLogs(deleteId);
					}}
					deleteButtonLoading={props.deleting}
				/>
			)}

			{openExportModal && (
				<ExportModal
					open={openExportModal}
					fileName={exportFileName}
					fileNameChange={(e) => setExportFileName(e.target.value)}
					fileExtension={exportFileExtension}
					fileExtensionChange={(e, { value }) => setExportFileExtension(value)}
					onDownloadClick={() => {
						exportData(exportFileName, exportFileExtension);
						setOpenExportModal(false);
					}}
					onClose={() => {
						setOpenExportModal(false);
					}}
				/>
			)}
		</CategoryContentMembrane>
	);
};
const mapStateToProps = (state) => ({
	loading: state.fuelLogs.loading,
	initialDate: state.dates.initialDate,
	finalDate: state.dates.finalDate,
	fuelLogs: state.fuelLogs.fuelLogs,
	count: state.fuelLogs.count,
	pageNumber: state.fuelLogs.pageNumber,
	activeSort: state.fuelLogs.activeSort,
	dataSizeLimit: state.ui.dataSizeLimit,
	sortOrder: state.fuelLogs.sortOrder,

	activeIndex: state.fuelLogs.activeIndex,
	updating: state.fuelLogs.updating,
	editing: state.fuelLogs.editing,

	addModalOpen: state.fuelLogs.addModalOpen,
	adding: state.fuelLogs.adding,

	deleting: state.fuelLogs.deleting,
	deleteModalOpen: state.fuelLogs.deleteModalOpen,
	vehicles: state.vehicles.vehicles,
	editMode: state.fuelLogs.editMode,
	filters: state.vehicles.filters,
});
export default connect(mapStateToProps)(FuelLog);
