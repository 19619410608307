import React from 'react';
import { RESET_PASSWORD_API, STUDENTS_API, VERIFIED_ASSETS_API } from '../../../../../logic/configs/api';
import BasicLoader from '../../../blocks/molecules/loader/BasicLoader';
import SearchBox from '../../../blocks/atoms/search-box/SearchBox';
import FooterPagination from '../../../blocks/molecules/pagination/Pagination';
import ToggleSwitch from '../../../blocks/molecules/toggle-switch/ToggleSwitch';
import RestoreModal from '../../../blocks/organisms/modals/students/RestoreModal';
import DeleteModal from '../../../blocks/organisms/modals/students/DeleteModal';
import AddModal from '../../../blocks/organisms/modals/students/AddModal';
import ExportModal from '../../../blocks/organisms/modals/students/ExportModal';
import { CategoryContentMembrane } from '../../../styles/containers/CategoryContentMembrane';
import { CategoryHeader } from '../../../styles/containers/CategoryHeader';
import { Expander } from '../../../styles/place-holders/Expander';
import { CategoryMain } from '../../../styles/containers/CategoryMain';
import { AccordionHeader } from '../../../styles/containers/AccordionHeader';
import { AccordionMain } from '../../../styles/containers/AccordionMain';
import { CategoryFooter } from '../../../styles/containers/CategoryFooter';
import { AccordionMainContent } from '../../../styles/containers/AccordionMainContent';
import PlaceHolder from '../../../blocks/molecules/place-holder/PlaceHolder';
import SendVerificationModal from '../../../blocks/organisms/modals/users/SendVerificationModal';
import TransitionLoader from '../../../blocks/molecules/loader/TransitionLoader';
import FilterModal from '../../../blocks/organisms/modals/students/FilterModal';
import {
	closeFilterModal,
	exportAll,
	exportData,
	getGeofenceForDropdown,
	getOrganisationsForDropdown,
	getUsersForDropdown,
	getVehiclesForDropdown,
	openFilterModal,
	refreshStudents,
	searchStudents,
	toggleAccordion,
} from '../../../../../logic/middleware/students';
import { dispatch, dispatchNoPayload } from '../../../../../logic/middleware/middleware';

import { generateSuccessMessage, handleError } from '../../../../../logic/middleware/messaging';
import { logout } from '../../../../../logic/middleware/auth';
import { connect } from 'react-redux';
import axios from 'axios';
import { Actions, HeaderElement, Row, RowElement } from '../../../styles/Accordion';
import { Accordion, Button, Dropdown, Form, Icon, Input } from 'semantic-ui-react';
import { TimeInput } from 'semantic-ui-calendar-react';
import accessSetter from '../../../../../logic/utilities/accessSetter';
import { trackMixpanelEvent } from '../../../../../logic/middleware/mixpanel';
import { postData } from '../../../../../logic/middleware/services/apiRequest';
import Import from '../../../../common/import/StudentImport';
import { FILE_TYPE_EXTENSIONS } from '../../../../../data/constants/file-types';
import { capitalizeWord } from '../../../../../logic/utilities/validator';
/*import { datadogLogs } from '@datadog/browser-logs';*/
import ImportInfo from '../../../../common/ImportInfo/ImportInfo';

class Students extends React.Component {
	student_id = '';
	searchQuery = '';
	timeoutId;
	isSuperAdminAccess = localStorage.getItem('isSuperAdmin');

	deletedMode = false;
	selectAllActive = false;
	asset = '';
	assetType = null;
	sortOrder = '';
	activeSort = '';

	activeFilters = {
		organisations: [],
	};

	constructor(props) {
		super(props);

		// this.fileInputRef = React.createRef();

		this.state = {
			initialLoading: true,
			organisations: [],
			vehicles: [],
			geofences: [],
			users: [],
			importInfo: false,

			manageAccess: false,
			createAccess: false,
			readAccess: false,
			updateAccess: false,
			deleteAccess: false,

			//Reset Password
			resetPasswordID: '',

			nameFA: null,
			organisationFA: null,
			pickupBusFA: null,
			pickupPointFA: null,
			pickupTimeFA: '',
			dropBusFA: null,
			dropPointFA: null,
			dropTimeFA: '',
			parentsFA: [],
			nameError: false,
			organisationError: false,
			busError: false,

			nameFE: null,
			organisationFE: null,
			pickupBusFE: null,
			pickupPointFE: null,
			pickupTimeFE: null,
			dropBusFE: null,
			dropPointFE: null,
			dropTimeFE: null,
			parentsFE: null,

			// Selection
			anyRowSelected: false,
			selectedRows: new Set(),

			filters: {
				organisations: [],
			},

			deletedMode: false,

			fileName: 'Students',
			fileExtension: 'pdf',
		};

		this.toggleDeletedMode = this.toggleDeletedMode.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.add = this.add.bind(this);
		this.cleanAddOperation = this.cleanAddOperation.bind(this);
		this.export = this.export.bind(this);
		this.getFilteredStudents = this.getFilteredStudents.bind(this);
		this.update = this.update.bind(this);
		this.deleteUser = this.deleteUser.bind(this);
		this.restoreUser = this.restoreUser.bind(this);
		this.cleanEditOperation = this.cleanEditOperation.bind(this);
		this.cleanDeleteOperation = this.cleanDeleteOperation.bind(this);
		this.cleanRestoreOperation = this.cleanRestoreOperation.bind(this);
		this.handleDataSizeLimitChange = this.handleDataSizeLimitChange.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.cleanSelectedRows = this.cleanSelectedRows.bind(this);
		this.cleanSendVerificationOperation = this.cleanSendVerificationOperation.bind(this);

		this.onOrganisationChoose = this.onOrganisationChoose.bind(this);
		this.handleExportSelection = this.handleExportSelection.bind(this);
		this.handleSelectAll = this.handleSelectAll.bind(this);
		this.resetUserPassword = this.resetUserPassword.bind(this);
		this.cleanResetPasswordOperation = this.cleanResetPasswordOperation.bind(this);
		this.sort = this.sort.bind(this);
		this.sendVerificationLink = this.sendVerificationLink.bind(this);

		this.onNameChange = this.onNameChange.bind(this);
		this.onDynamicRoleFEChange = this.onDynamicRoleFEChange.bind(this);
		this.onOrganisationFilterChange = this.onOrganisationFilterChange.bind(this);
		this.onParentsChooseFE = this.onParentsChooseFE.bind(this);

		this.onCanLoginFEChange = this.onCanLoginFEChange.bind(this);
		this.onPasswordFEChange = this.onPasswordFEChange.bind(this);
		this.onPasswordSubmit = this.onPasswordSubmit.bind(this);

		this.fileExtensionChange = this.fileExtensionChange.bind(this);
		this.fileNameChange = this.fileNameChange.bind(this);
		this.manageAccessSetter = this.manageAccessSetter.bind(this);
		this.onNameChangeFE = this.onNameChangeFE.bind(this);
		this.handleDoubleClick = this.handleDoubleClick.bind(this);
		this.handleDoubleClickName = this.handleDoubleClickName.bind(this);
		this.handleDoubleClickPickBus = this.handleDoubleClickPickBus.bind(this);

		this.handleOpenImportInfo = this.handleOpenImportInfo.bind(this);
		this.handleCloseImportInfo = this.handleCloseImportInfo.bind(this);
	}

	manageAccessSetter(rule, action) {
		this.setState({ [action]: rule });
	}

	componentDidMount() {
		const permissions = JSON.parse(localStorage.getItem('permissions'));
		const studentsPermissions = permissions?.filter((permission) => permission.subject === 'Student');
		if (studentsPermissions) {
			for (const studentPrm of studentsPermissions) {
				accessSetter(studentPrm?.action, this.manageAccessSetter);
			}
		}

		if (this.props.searchQuery) {
			dispatch('UPDATE_SEARCH_QUERY', this.props.searchQuery);
			this.handleSearch({ target: { value: this.props.searchQuery } });
			return;
		}
		trackMixpanelEvent('Students', { Action: 'View' });

		if (this.props.filters.organisations) {
			this.activeFilters = this.state.filters;
			this.activeFilters.organisations = this.props.filters.organisations;
			this.getFilteredStudents();
			return;
		}

		refreshStudents();
	}

	// Fetch
	getFilteredStudents(activePage = 1, resetAccordion = true) {
		if (resetAccordion) {
			dispatch('STUDENTS_TOGGLE_ACCORDION', -1);
		}

		let query = '';
		if (this.searchQuery !== '') {
			query = query + searchStudents(this.searchQuery);
		}

		if (this.activeSort !== '' && this.sortOrder !== '') {
			if (this.sortOrder === 'asc') {
				query = query + `&sortBy=${this.activeSort}&orderBy=${this.sortOrder}`;
			} else {
				query = query + `&sortBy=${this.activeSort}&orderBy=${this.sortOrder}`;
			}
		}

		if (this.activeFilters.organisations?.length) {
			query =
				query + this.activeFilters.organisations.map((organisation) => `&organisations[]=${organisation}`).join('');
		}

		if (this.deletedMode) {
			query = query + `&deleted=true`;
		}

		refreshStudents(query, activePage);
	}

	handleSearch(e) {
		this.searchQuery = e.target.value;

		dispatch('UPDATE_SEARCH_QUERY', this.searchQuery);

		if (this.timeoutId) {
			clearTimeout(this.timeoutId);
		}

		this.timeoutId = setTimeout(() => {
			this.getFilteredStudents();
			this.cleanSelectedRows();
		}, 500);
	}

	toggleDeletedMode() {
		this.deletedMode = !this.deletedMode;
		this.setState({
			deletedMode: !this.state.deletedMode,
		});

		this.getFilteredStudents();
		this.cleanSelectedRows();
	}

	// Export
	fileNameChange(e) {
		this.setState({ fileName: e.target.value });
	}

	fileExtensionChange(e, { value }) {
		this.setState({ fileExtension: value });
	}

	handleExportSelection(checkedData, id) {
		let selectedRows = this.state.selectedRows;

		if (selectedRows.has(id)) {
			selectedRows.delete(id);
		} else selectedRows.add(id);

		this.setState({
			selectedRows: selectedRows,
			anyRowSelected: !!selectedRows.size,
		});
	}

	handleSelectAll(checked) {
		let selectedRows = new Set();

		if (checked) {
			this.selectAllActive = true;
			this.props.students.forEach((user) => selectedRows.add(user.id));
			this.setState({
				selectedRows: selectedRows,
				anyRowSelected: !!selectedRows.size,
			});
		} else {
			this.selectAllActive = false;
			this.setState({
				selectedRows: new Set(),
				anyRowSelected: !!selectedRows.size,
			});
		}
	}

	export() {
		const { students } = this.props;

		let studentsToExport = [];
		students.forEach((student) => {
			if (this.state.selectedRows.has(student.id)) {
				studentsToExport.push(student);
			}
		});

		return studentsToExport;
	}

	getFilterListQuery() {
		let query = '';

		query = query + `?fileType=${this.state.fileExtension}`;

		if (this.searchQuery !== '') {
			query = query + searchStudents(this.searchQuery);
		}

		if (this.activeFilters.organisations) {
			query =
				query + this.activeFilters.organisations.map((organisation) => `&organisations[]=${organisation}`).join('');
		}

		if (this.deletedMode) {
			query = query + `&deleted=true`;
		}

		if (this.activeSort !== '' && this.sortOrder !== '') {
			if (this.sortOrder === 'asc') {
				query = query + `&sortBy=${this.activeSort}&orderBy=${this.sortOrder}`;
			} else {
				query = query + `&sortBy=${this.activeSort}&orderBy=${this.sortOrder}`;
			}
		}

		return query;
	}

	// Add
	add() {
		const {
			nameFA,
			organisationFA,
			pickupBusFA,
			pickupPointFA,
			pickupTimeFA,
			dropBusFA,
			dropPointFA,
			dropTimeFA,
			parentsFA,
		} = this.state;
		let queryObj = {};

		if (nameFA !== null && organisationFA !== null && pickupBusFA !== null) {
			if (nameFA) queryObj = { ...queryObj, name: nameFA.trim() };
			if (organisationFA)
				queryObj = {
					...queryObj,
					organisation: {
						id: organisationFA,
					},
				};
			if (pickupBusFA) queryObj = { ...queryObj, pickupBus: { id: pickupBusFA } };
			if (pickupPointFA) queryObj = { ...queryObj, pickupPoint: { id: pickupPointFA } };
			if (pickupTimeFA) queryObj = { ...queryObj, pickupTime: pickupTimeFA };
			if (dropBusFA) queryObj = { ...queryObj, dropBus: { id: dropBusFA } };
			if (dropPointFA) queryObj = { ...queryObj, dropPoint: { id: dropPointFA } };
			if (dropTimeFA) queryObj = { ...queryObj, dropTime: dropTimeFA };
			if (parentsFA !== null)
				queryObj = {
					...queryObj,
					parents: parentsFA.map((parent) => {
						return {
							id: parent,
						};
					}),
				};

			dispatchNoPayload('STUDENTS_ADDING');

			// axios
			// 	.post(STUDENTS_API, queryObj, {
			// 		headers: {
			// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
			// 			'Content-Type': 'application/json',
			// 		},
			// 	})
			postData(STUDENTS_API, '', queryObj)
				.then((response) => {
					dispatchNoPayload('STUDENTS_ADDED');
					generateSuccessMessage(response);
					this.getFilteredStudents(this.props.pageNumber);
					this.cleanAddOperation();
				})
				.catch((error) => {
					dispatchNoPayload('STUDENTS_ADDED');
					/*datadogLogs.logger.error('Students add api', {}, error);*/
					handleError(error);
				});
		} else if (nameFA === null) {
			this.setState({ nameError: true });
		} else if (organisationFA === null) {
			this.setState({ organisationError: true });
		} else if (pickupBusFA === null) {
			this.setState({ busError: true });
		}
	}

	cleanAddOperation() {
		dispatchNoPayload('STUDENTS_ADD_MODAL_CLOSE');
		dispatchNoPayload('STUDENTS_PASSWORD_NOT_VISIBLE');

		this.setState({
			nameFA: null,
			organisationFA: null,
			pickupBusFA: null,
			pickupPointFA: null,
			pickupTimeFA: '',
			dropBusFA: null,
			dropPointFA: null,
			dropTimeFA: '',
			parentsFA: [],
			nameError: null,
			organisationError: null,
			busError: null,
		});
		this.student_id = '';
	}

	onNameChange(e, { value }) {
		const capitalizedValue = capitalizeWord(value);
		this.setState({
			nameFA: capitalizedValue,
		});
	}

	onOrganisationChoose(e, { value }) {
		this.setState({
			organisationFA: value,
			pickupBusFA: null,
			pickupPointFA: null,
			dropBusFA: null,
			dropPointFA: null,
		});

		getVehiclesForDropdown(`?organisations[]=${value}&dropdown=true`).then((response) => {
			this.setState({
				vehicles: response,
			});
		});

		getGeofenceForDropdown(`?organisations[]=${value}&dropdown=true`).then((response) => {
			this.setState({
				geofences: response,
			});
		});

		getUsersForDropdown(`?organisations[]=${value}&dropdown=true&type[]=Parent`).then((response) => {
			this.setState({
				users: response,
			});
		});
	}

	handleActiveTimeChange = (event, { name, value }) => {
		// if (Object.hasOwn(Object, name)) {
		this.setState({ [name]: value });
		// }
	};

	onParentsChooseFE(e, { value }) {
		this.setState({
			parentsFE: value,
		});
	}

	onNameChangeFE(e, { value }) {
		const capitalizedValue = capitalizeWord(value);
		this.setState({
			nameFE: capitalizedValue,
		});
	}

	// Update
	update(id) {
		const { nameFE, pickupBusFE, pickupPointFE, pickupTimeFE, dropBusFE, dropPointFE, dropTimeFE, parentsFE } =
			this.state;
		let queryObj = {};
		if (nameFE) queryObj = { ...queryObj, name: nameFE.trim() };
		if (pickupBusFE) queryObj = { ...queryObj, pickupBus: { id: pickupBusFE } };

		if (dropBusFE) {
			queryObj = { ...queryObj, dropBus: { id: dropBusFE } };
		} else if (dropBusFE === '') {
			queryObj = { ...queryObj, dropBus: null };
		}

		if (dropPointFE) {
			queryObj = { ...queryObj, dropPoint: { id: dropPointFE } };
		} else if (dropPointFE === '') {
			queryObj = { ...queryObj, dropPoint: null };
		}
		if (pickupPointFE) {
			queryObj = { ...queryObj, pickupPoint: { id: pickupPointFE } };
		} else if (pickupPointFE === '') {
			queryObj = { ...queryObj, pickupPoint: null };
		}

		queryObj = { ...queryObj, pickupTime: pickupTimeFE ? pickupTimeFE : null };
		queryObj = { ...queryObj, dropTime: dropTimeFE ? dropTimeFE : null };

		if (parentsFE !== null)
			queryObj = {
				...queryObj,
				parents: parentsFE.map((parent) => {
					return {
						id: parent,
					};
				}),
			};
		dispatchNoPayload('STUDENTS_UPDATING');
		axios
			.patch(STUDENTS_API + `/${id}`, queryObj, {
				headers: {
					Authorization: 'Bearer ' + localStorage.getItem('token'),
				},
			})
			.then((response) => {
				dispatchNoPayload('STUDENTS_UPDATED');
				generateSuccessMessage(response);
				this.getFilteredStudents(this.props.pageNumber, false);
				this.cleanEditOperation();
			})
			.catch((error) => {
				dispatchNoPayload('STUDENTS_UPDATED');
				handleError(error);
			});
	}

	cleanEditOperation() {
		dispatchNoPayload('STUDENTS_EDIT_MODAL_CLOSE');

		this.setState({
			nameFE: null,
			organisationFE: null,
			pickupBusFE: null,
			pickupPointFE: null,
			pickupTimeFE: null,
			dropBusFE: null,
			dropPointFE: null,
			dropTimeFE: null,
			parentsFE: null,
		});
	}

	onDynamicRoleFEChange(e, { value }) {
		this.setState({
			selectedDynamicRoleFE: value,
		});
	}

	onCanLoginFEChange(e, { checked }) {
		this.setState({
			canLoginFE: checked,
		});

		if (!checked) {
			this.setState({
				selectedDynamicRoleFE: null,
				passwordFE: null,
			});
		}
	}

	onPasswordFEChange(e, { value }) {
		this.setState({
			passwordFE: value,
		});
	}

	// Delete
	deleteUser(id) {
		dispatchNoPayload('STUDENTS_DELETING');
		axios
			.delete(STUDENTS_API + `/${id}`, {
				headers: {
					Authorization: 'Bearer ' + localStorage.getItem('token'),
				},
			})
			.then((response) => {
				dispatchNoPayload('STUDENTS_DELETED');
				generateSuccessMessage(response);
				this.getFilteredStudents(this.props.pageNumber);
				this.cleanDeleteOperation();
			})
			.catch((error) => {
				dispatchNoPayload('STUDENTS_DELETED');
				handleError(error);
			});
	}

	cleanDeleteOperation() {
		dispatchNoPayload('STUDENTS_DELETE_MODAL_CLOSE');

		this.student_id = '';
	}

	sendVerificationLink() {
		dispatchNoPayload('STUDENTS_VERIFICATION_LINK_SENDING');
		axios
			.post(
				VERIFIED_ASSETS_API,
				{ asset: this.asset, assetType: this.assetType },
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token'),
					},
				}
			)
			.then((response) => {
				dispatchNoPayload('STUDENTS_VERIFICATION_LINK_SENDED');
				generateSuccessMessage(response);
				this.getFilteredStudents(this.props.pageNumber);
				this.cleanSendVerificationOperation();
			})
			.catch((error) => {
				dispatchNoPayload('STUDENTS_VERIFICATION_LINK_SENDED');
				handleError(error);
			});
	}

	cleanSendVerificationOperation() {
		dispatchNoPayload('STUDENTS_SEND_VERIFICATION_MODAL_CLOSE');

		this.asset = '';
		this.assetType = null;
	}

	// Reset Password
	resetUserPassword(id) {
		let query = {
			password: this.password,
		};
		dispatchNoPayload('STUDENTS_PASSWORD_SUBMITING');
		axios
			.post(RESET_PASSWORD_API + `/${id}/change-password`, query, {
				headers: {
					Authorization: 'Bearer ' + localStorage.getItem('token'),
					'Content-Type': 'application/json',
				},
			})
			.then((response) => {
				dispatchNoPayload('STUDENTS_PASSWORD_SUBMITED');
				generateSuccessMessage(response);
				if (localStorage.getItem('userId') === id) {
					logout();
				} else {
					this.getFilteredStudents(this.props.pageNumber, false);
					this.cleanResetPasswordOperation();
				}
			})
			.catch((error) => {
				dispatchNoPayload('STUDENTS_PASSWORD_SUBMITED');

				handleError(error);
			});
	}

	cleanResetPasswordOperation() {
		dispatchNoPayload('STUDENTS_PASSWORD_MODAL_CLOSE');
		dispatchNoPayload('STUDENTS_PASSWORD_NOT_VISIBLE');

		this.password = '';
	}

	onPasswordSubmit(e) {
		this.password = e.target.value;
	}

	// Restore
	restoreUser(id) {
		dispatchNoPayload('STUDENTS_RESTORING');
		// axios
		// 	.post(
		// 		STUDENTS_API + `/${id}/restore`,
		// 		{},
		// 		{
		// 			headers: {
		// 				Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 			},
		// 		}
		// 	)
		postData(STUDENTS_API, `/${id}/restore`)
			.then((response) => {
				dispatchNoPayload('STUDENTS_RESTORED');
				generateSuccessMessage(response, 'Student Restored');
				this.getFilteredStudents(this.props.pageNumber);

				this.cleanRestoreOperation();
			})
			.catch((error) => {
				handleError(error);
				/*datadogLogs.logger.error('Students restore api', {}, error);*/
				dispatchNoPayload('STUDENTS_RESTORED');
			});
	}

	cleanRestoreOperation() {
		dispatchNoPayload('STUDENTS_RESTORE_MODAL_CLOSE');

		this.student_id = '';
	}

	sort(param, activeSort, sortOrder) {
		if (!param) {
			this.activeSort = param;
			this.sortOrder = '';
		} else {
			if (activeSort !== param) {
				this.sortOrder = 'asc';
				this.activeSort = param;
			} else {
				if (sortOrder === 'asc') {
					this.sortOrder = 'desc';
				} else {
					this.sortOrder = 'asc';
				}
			}
		}

		dispatch('STUDENTS_SORT_ORDER', this.sortOrder);
		dispatch('STUDENTS_ACTIVE_ORDER', this.activeSort);
		this.getFilteredStudents(this.props.pageNumber);
	}

	onOrganisationFilterChange(e, { value }) {
		// this.setState({
		// 	filters: { ...this.state.filters, organisations: value },
		// });
		dispatch('ACTIVE_FILTER', { ...this.props.filters, organisations: value });
	}

	handleDoubleClick(dropBus) {
		this.props.history.push({
			pathname: '/operations/vehicles',
			state: { dropBus: dropBus },
		});
	}

	handleDoubleClickPickBus(pickupBus) {
		this.props.history.push({
			pathname: '/operations/vehicles',
			state: { pickupBus: pickupBus },
		});
	}

	handleDoubleClickName(parentName) {
		this.props.history.push({
			pathname: '/operations/users',
			state: { parentName: parentName },
		});
	}

	// Pagination
	handleDataSizeLimitChange() {
		this.getFilteredStudents();
	}

	handlePageChange(activePage) {
		this.getFilteredStudents(activePage);
		this.cleanSelectedRows();
	}

	cleanSelectedRows() {
		this.setState({
			anyRowSelected: false,
			selectedRows: new Set(),
		});
	}

	componentDidUpdate(prevProps) {
		if (prevProps.students !== this.props.students) {
			if (this.selectAllActive) this.handleSelectAll(true);
		}

		if (prevProps.pageNumber !== this.props.pageNumber) {
			document.getElementById('AccordionMain').scrollTo(0, 0);
		}

		if (prevProps.loading !== this.props.loading) {
			if (!this.props.loading) {
				this.setState({
					initialLoading: false,
				});
			}
		}
	}

	handleOpenImportInfo() {
		this.setState({
			importInfo: true,
		});
	}

	handleCloseImportInfo() {
		this.setState({
			importInfo: false,
		});
	}

	componentWillUnmount() {
		dispatchNoPayload('STUDENTS_UNMOUNT');
	}

	render() {
		return (
			<CategoryContentMembrane>
				<CategoryHeader>
					<SearchBox
						placeholder="Search in students"
						searchQuery={this.searchQuery}
						clearSearchIconClick={() => {
							this.searchQuery = '';
							this.getFilteredStudents(this.props.pageNumber);

							dispatch('UPDATE_SEARCH_QUERY', '');
						}}
						value={this.props.searchQuery}
						handleSearch={this.handleSearch}
						// disabled={!this.searchQuery && (this.props.loading || this.props.exportingData)}
					/>
					<Button
						icon
						primary
						disabled={this.props.loading || this.props.exportingData}
						title="Refresh Parents"
						onClick={() => this.getFilteredStudents(this.props.pageNumber)}>
						<Icon name="refresh" />
					</Button>
					<Expander />
					{this.isSuperAdminAccess === 'true' && (
						<Button
							icon
							primary={!this.activeFilters.organisations.length}
							disabled={this.props.loading || this.props.exportingData}
							color={this.activeFilters.organisations.length ? 'orange' : null}
							title="Filter Students"
							onClick={() => {
								getOrganisationsForDropdown().then((response) => {
									this.setState({ organisations: response });
								});
								openFilterModal();
							}}>
							<Icon name="filter" />
						</Button>
					)}

					{/*Add*/}
					{!this.state.deletedMode && (
						<Button
							primary
							disabled={
								this.props.loading || this.props.exportingData || !(this.state.manageAccess || this.state.createAccess)
							}
							title="Add Student"
							onClick={() => {
								trackMixpanelEvent('Students', { Action: 'Add' });
								dispatchNoPayload('STUDENTS_ADD_MODAL_OPEN');
								getOrganisationsForDropdown().then((response) => {
									this.setState({
										organisations: response,
									});
								});
							}}>
							Add
						</Button>
					)}

					<Button
						primary
						title="Import students from file"
						disabled={
							this.props.loading || this.props.exportingData || !(this.state.manageAccess || this.state.createAccess)
						}
						// onClick={() => this.fileInputRef.current.click()}
						onClick={this.handleOpenImportInfo}>
						Import
					</Button>

					{this.state.importInfo && (
						<ImportInfo
							students={{
								StudentimportInfo: this.state.importInfo,
								onClose: this.handleCloseImportInfo,
								fileTemplate: [
									{ name: 'Route No', requestTitle: 'routeNo', type: 'number' },
									{ name: 'Pick & Drop Bus', requestTitle: 'VehicleNo', type: 'string' },
									{ name: 'Student Name', requestTitle: 'studentName', type: 'string' },
									{ name: 'Location', requestTitle: 'location', type: 'string' },
									{ name: 'Area', requestTitle: 'area', type: 'string' },
									{ name: 'Parent email', requestTitle: 'parentEmail', type: 'string' },
									{ name: 'Parent Contact Number', requestTitle: 'contactNumber', type: 'number' },
									{ name: 'Organisation', requestTitle: 'Organisation', type: 'string' },
								],
							}}
						/>
					)}

					<Button.Group>
						<Button
							primary
							title="Export All Students"
							loading={this.props.exportingData}
							disabled={this.props.loading || !this.props.students.length || this.props.exportingData}
							onClick={() => exportAll(this.getFilterListQuery())}>
							Export
						</Button>
						<Dropdown
							style={{ background: '#ffffff', color: '#1b3c71', borderColor: '#1b3c71' }}
							className="button icon"
							floating
							value={this.state.fileExtension}
							options={FILE_TYPE_EXTENSIONS}
							// defaultValue={this.state.fileExtension}
							onChange={this.fileExtensionChange}
							trigger={<></>}
						/>
					</Button.Group>

					{this.isSuperAdminAccess === 'true' && (
						<ToggleSwitch
							title={this.deletedMode ? 'Hide Deleted Students' : 'Show Deleted Students'}
							label="Deleted Mode"
							checked={this.deletedMode}
							disabled={this.props.loading || this.props.exportingData}
							action={this.toggleDeletedMode}
						/>
					)}
				</CategoryHeader>

				<CategoryMain>
					<AccordionHeader background={this.deletedMode}>
						{/*<HeaderElement*/}
						{/*	style={{ marginRight: 0 }}*/}
						{/*	width="3%"*/}
						{/*	disabled={this.props.loading || this.props.exportingData || !this.props.students.length}>*/}
						{/*	<CheckboxContainer>*/}
						{/*		<Checkbox*/}
						{/*			checked={*/}
						{/*				this.props.students.length !== 0 && this.state.selectedRows.size === this.props.students.length*/}
						{/*			}*/}
						{/*			indeterminate={*/}
						{/*				this.state.selectedRows.size !== 0 && this.state.selectedRows.size !== this.props.students.length*/}
						{/*			}*/}
						{/*			onClick={(e, data) => {*/}
						{/*				this.handleSelectAll(data.checked);*/}
						{/*			}}*/}
						{/*		/>*/}
						{/*	</CheckboxContainer>*/}
						{/*</HeaderElement>*/}
						<HeaderElement
							width="5%"
							style={{ marginRight: 0 }}
							disabled={this.props.loading || this.props.exportingData || !this.props.students.length}>
							No.
						</HeaderElement>
						<HeaderElement
							// style={{marginRight: 0}}
							width="10%"
							onClick={() => {
								this.sort('name', this.props.activeSort, this.props.sortOrder);
							}}
							disabled={this.props.loading || this.props.exportingData || !this.props.students.length}
							activeSort={this.activeSort === 'name'}>
							{this.props.activeSort === 'name' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Name
						</HeaderElement>
						<HeaderElement
							style={{ marginRight: 0 }}
							width="14%"
							onClick={() => {
								this.sort('organisation', this.props.activeSort, this.props.sortOrder);
							}}
							disabled={this.props.loading || this.props.exportingData || !this.props.students.length}
							activeSort={this.activeSort === 'organisation'}>
							{this.props.activeSort === 'organisation' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Organisation
						</HeaderElement>
						<HeaderElement
							width="12%"
							style={{ marginRight: 0 }}
							onClick={() => {
								this.sort('pickupBus', this.props.activeSort, this.props.sortOrder);
							}}
							disabled={this.props.loading || this.props.exportingData || !this.props.students.length}
							activeSort={this.activeSort === 'pickupBus'}>
							{this.props.activeSort === 'pickupBus' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Pickup Bus
						</HeaderElement>
						<HeaderElement
							width="12%"
							style={{ marginRight: 0 }}
							disabled={this.props.loading || this.props.exportingData || !this.props.students.length}>
							Pickup Point
						</HeaderElement>
						{/*<HeaderElement*/}
						{/*  width="10%"*/}
						{/*  onClick={() => {*/}
						{/*    this.sort('pickupTime', this.props.activeSort, this.props.sortOrder);*/}
						{/*  }}*/}
						{/*  disabled={this.props.loading || this.props.exportingData || !this.props.students.length}*/}
						{/*  activeSort={this.activeSort === 'pickupTime'}>*/}
						{/*  {this.props.activeSort === 'pickupTime' ? (*/}
						{/*    this.props.sortOrder === 'asc' ? (*/}
						{/*      <Icon name="sort alphabet down" />*/}
						{/*    ) : (*/}
						{/*      <Icon name="sort alphabet up" />*/}
						{/*    )*/}
						{/*  ) : (*/}
						{/*    <Icon name="sort" />*/}
						{/*  )}*/}
						{/*  Pickup Time*/}
						{/*</HeaderElement>*/}
						<HeaderElement
							width="12%"
							style={{ marginRight: 0 }}
							onClick={() => {
								this.sort('dropBus', this.props.activeSort, this.props.sortOrder);
							}}
							disabled={this.props.loading || this.props.exportingData || !this.props.students.length}
							activeSort={this.activeSort === 'dropBus'}>
							{this.props.activeSort === 'dropBus' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Drop Bus
						</HeaderElement>
						<HeaderElement
							style={{ marginRight: 0 }}
							width="12%"
							disabled={this.props.loading || this.props.exportingData || !this.props.students.length}>
							Drop Point
						</HeaderElement>
						{/*<HeaderElement*/}
						{/*  width="10%"*/}
						{/*  onClick={() => {*/}
						{/*    this.sort('dropTime', this.props.activeSort, this.props.sortOrder);*/}
						{/*  }}*/}
						{/*  disabled={this.props.loading || this.props.exportingData || !this.props.students.length}*/}
						{/*  activeSort={this.activeSort === 'dropTime'}>*/}
						{/*  {this.props.activeSort === 'dropTime' ? (*/}
						{/*    this.props.sortOrder === 'asc' ? (*/}
						{/*      <Icon name="sort alphabet down" />*/}
						{/*    ) : (*/}
						{/*      <Icon name="sort alphabet up" />*/}
						{/*    )*/}
						{/*  ) : (*/}
						{/*    <Icon name="sort" />*/}
						{/*  )}*/}
						{/*  Drop Time*/}
						{/*</HeaderElement>*/}
						<HeaderElement
							width="12%"
							style={{ marginRight: 0 }}
							disabled={this.props.loading || this.props.exportingData || !this.props.students.length}>
							Parents
						</HeaderElement>
						{this.deletedMode && (
							<HeaderElement
								style={{ marginRight: 0 }}
								width="9%"
								disabled={this.props.loading || this.props.exportingData || !this.props.students.length}>
								Deleted date
							</HeaderElement>
						)}
					</AccordionHeader>

					<AccordionMain id="AccordionMain">
						{this.state.initialLoading ? (
							<BasicLoader />
						) : this.props.students.length ? (
							<Accordion fluid>
								{this.props.students.map((student, index) => {
									return (
										<section key={index}>
											<Accordion.Title
												active={this.props.activeIndex === index}
												index={index}
												onClick={(event, data) => {
													toggleAccordion(event, data, this.props.activeIndex);
													this.cleanEditOperation();
													this.setState({ canLoginFE: student.canLogin });
												}}
												style={{ padding: 0 }}>
												<Row>
													{/*<RowElement width="3%" style={{ marginRight: 0 }}>*/}
													{/*	<CheckboxContainer>*/}
													{/*		<Checkbox*/}
													{/*			checked={this.state.selectedRows.has(student.id)}*/}
													{/*			onChange={(e, data) => {*/}
													{/*				e.stopPropagation();*/}
													{/*				this.handleExportSelection(data.checked, student.id);*/}
													{/*			}}*/}
													{/*		/>*/}
													{/*	</CheckboxContainer>*/}
													{/*</RowElement>*/}
													<RowElement width="5%" style={{ marginRight: 0 }}>
														{1 + index + (this.props.pageNumber - 1) * this.props.dataSizeLimit}
													</RowElement>
													<RowElement
														width="10%"
														style={{ color: `${student.name ? 'black' : 'grey'}` }}
														title={student.name ? student.name : 'Not Available'}>
														{student.name ? student.name : 'Not Available'}
													</RowElement>
													<RowElement
														width="14%"
														style={{
															marginRight: 0,
															color: `${student.organisation.name ? 'black' : 'grey'}`,
														}}
														title={student.organisation.name ? student.organisation.name : 'Not Available'}>
														{student.organisation.name ? student.organisation.name : 'Not Available'}
													</RowElement>

													<div
														style={{ width: '12%' }}
														onClick={() => navigator.clipboard.writeText(student.pickupBus.registrationNumber)}
														onDoubleClick={() => this.handleDoubleClickPickBus(student.pickupBus.registrationNumber)}>
														<RowElement
															width="100%"
															style={{
																marginRight: 0,
																color: `${student?.pickupBus?.registrationNumber ? 'black' : 'grey'}`,
															}}
															title={
																student?.pickupBus?.registrationNumber
																	? student.pickupBus.registrationNumber
																	: 'Not Available'
															}>
															{student?.pickupBus?.registrationNumber
																? student.pickupBus.registrationNumber
																: 'Not Available'}
														</RowElement>
													</div>

													<RowElement
														width="12%"
														style={{
															marginRight: 0,
															color: `${student.pickupPoint.name ? 'black' : 'grey'}`,
														}}
														title={student?.pickupPoint?.name ? student.pickupPoint.name : 'Not Available'}>
														{student?.pickupPoint?.name ? student.pickupPoint.name : 'Not Available'}
													</RowElement>
													{/*<RowElement*/}
													{/*  width="10%"*/}
													{/*  style={{textAlign: 'center'}}*/}
													{/*  title={student?.pickupTime ? student.pickupTime : ''}>*/}
													{/*  {student?.pickupTime ? student.pickupTime : ''}*/}
													{/*</RowElement>*/}

													<div
														style={{ width: '12%' }}
														onClick={() => navigator.clipboard.writeText(student?.dropBus?.registrationNumber)}
														onDoubleClick={() => this.handleDoubleClick(student?.dropBus?.registrationNumber)}>
														<RowElement
															width="100%"
															style={{
																marginRight: 0,
																color: `${student?.dropBus?.registrationNumber ? 'black' : 'grey'}`,
															}}
															title={
																student?.dropBus?.registrationNumber
																	? student.dropBus.registrationNumber
																	: 'Not Available'
															}>
															{student?.dropBus?.registrationNumber
																? student.dropBus.registrationNumber
																: 'Not Available'}
														</RowElement>
													</div>

													{/*<RowElement*/}
													{/*	width="8%"*/}
													{/*	style={{ textAlign: 'center' }}*/}
													{/*	title={student?.dropPoint?.name ? student.dropPoint.name : ''}>*/}
													{/*	{student?.dropPoint?.name ? student.dropPoint.name : ''}*/}
													{/*</RowElement>*/}

													<RowElement
														width="12%"
														style={{
															marginRight: 0,
															color: `${student?.dropTime ? 'black' : 'grey'}`,
														}}
														title={student?.dropTime ? student.dropTime : 'Not Available'}>
														{student?.dropTime ? student.dropTime : 'Not Available'}
													</RowElement>

													<div
														style={{ width: '12%' }}
														onClick={() => navigator.clipboard.writeText(student.parents[0]?.name)}
														onDoubleClick={() => this.handleDoubleClickName(student.parents[0]?.name)}>
														<RowElement
															width="100%"
															title={
																student.parents.map((parent) => parent.name).join(', ')
																	? student.parents.map((parent) => parent.name).join(', ')
																	: 'Not Available'
															}
															wrap="true"
															style={{
																color: `${student?.parents[0]?.name ? 'black' : 'grey'}`,
																textOverflow: 'ellipsis',
																overflow: 'hidden',
																whiteSpace: 'nowrap',
																marginRight: 0,
															}}>
															{student.parents.map((parent) => parent.name).join(', ')
																? student.parents.map((parent) => parent.name).join(', ')
																: 'Not Available'}
														</RowElement>
													</div>
													{this.deletedMode && (
														<RowElement
															style={{
																marginRight: 0,
																color: `${student?.deletedDate ? 'black' : 'grey'}`,
															}}
															width="9%"
															title={student.deletedDate ? student.deletedDate : 'Not Available'}>
															{student.deletedDate ? student.deletedDate : 'Not Available'}
														</RowElement>
													)}
												</Row>
											</Accordion.Title>
											{this.props.activeIndex === index && (
												<Accordion.Content active={this.props.activeIndex === index}>
													<AccordionMainContent>
														<Form>
															<Form.Group widths="equal">
																<Form.Field required={true}>
																	<label>Name</label>
																	<Input
																		fluid
																		value={
																			this.state.nameFE !== null
																				? this.state.nameFE
																				: student.name === null
																				? ''
																				: student.name
																		}
																		disabled={!this.props.editMode}
																		// onChange={(e) => this.setState({ nameFE: e.target.value })}
																		onChange={this.onNameChangeFE}
																	/>
																</Form.Field>
																<Form.Field required={true}>
																	<label>Organisation</label>
																	<Input fluid defaultValue={student.organisation.name} disabled />
																</Form.Field>
																<Form.Field>
																	<label>Parents</label>
																	{this.props.editMode ? (
																		<Dropdown
																			fluid
																			search
																			selection
																			multiple
																			clearable
																			disabled={!this.props.editMode}
																			value={
																				this.state.parentsFE !== null
																					? this.state.parentsFE
																					: student.parents.map((parent) => parent.id)
																			}
																			options={this.state.users}
																			onChange={this.onParentsChooseFE}
																		/>
																	) : (
																		<Input fluid value={student.parents.map((parent) => parent.name)} disabled />
																	)}
																</Form.Field>
															</Form.Group>
															<Form.Group widths="equal">
																<Form.Field required={true}>
																	<label>Pickup Bus</label>
																	{this.props.editMode ? (
																		<Dropdown
																			placeholder="Choose ..."
																			fluid
																			search
																			selection
																			// clearable
																			options={this.state.vehicles}
																			value={
																				this.state.pickupBusFE !== null ? this.state.pickupBusFE : student.pickupBus.id
																			}
																			onChange={(e, { value }) => this.setState({ pickupBusFE: value })}
																		/>
																	) : (
																		<Input fluid value={student.pickupBus.registrationNumber} disabled />
																	)}
																</Form.Field>

																<Form.Field>
																	<label>Pickup Point</label>
																	{/*{this.props.editMode ? (*/}
																	<Dropdown
																		placeholder="Choose ..."
																		fluid
																		required
																		search
																		selection
																		clearable
																		disabled={!this.props.editMode}
																		options={this.state.geofences}
																		value={
																			this.state.pickupPointFE !== null
																				? this.state.pickupPointFE
																				: student.pickupPoint.id
																		}
																		// value={this.state.pickupPointFE}
																		onChange={(e, { value }) => {
																			this.setState({ pickupPointFE: value });
																		}}
																	/>
																	{/*// ) : (*/}
																	{/*// 	<Input fluid value={student.pickupPoint.name} disabled />*/}
																	{/*// )}*/}
																</Form.Field>

																<Form.Field>
																	<label>Pickup Time</label>
																	<TimeInput
																		name="pickupTimeFE"
																		closable
																		clearable
																		// value={this.state.pickupTimeFE}
																		value={
																			this.state.pickupTimeFE !== null ? this.state.pickupTimeFE : student.pickupTime
																		}
																		iconPosition="left"
																		onChange={(event, { name, value }) => {
																			// if (Object.hasOwn(name)) {
																			this.setState({ [name]: value });
																			// }
																		}}
																		disabled={!this.props.editMode}
																	/>
																</Form.Field>
															</Form.Group>
															<Form.Group widths="equal">
																<Form.Field>
																	<label>Drop Bus</label>
																	{/*{this.props.editMode ? (*/}
																	<Dropdown
																		placeholder="Choose ..."
																		fluid
																		search
																		selection
																		disabled={!this.props.editMode}
																		clearable
																		options={this.state.vehicles}
																		value={this.state.dropBusFE !== null ? this.state.dropBusFE : student.dropBus.id}
																		onChange={(e, { value }) => this.setState({ dropBusFE: value })}
																	/>
																	{/*// ) : (*/}
																	{/*// 	<Input fluid value={student.dropBus.registrationNumber} disabled />*/}
																	{/*// )}*/}
																</Form.Field>

																<Form.Field>
																	<label>Drop Point</label>
																	{/*{this.props.editMode ? (*/}
																	<Dropdown
																		placeholder="Choose ..."
																		fluid
																		search
																		selection
																		required
																		disabled={!this.props.editMode}
																		clearable
																		options={this.state.geofences}
																		// value={this.state.dropPointFE}
																		value={
																			this.state.dropPointFE !== null ? this.state.dropPointFE : student.dropPoint.id
																		}
																		onChange={(e, { value }) => this.setState({ dropPointFE: value })}
																	/>
																	{/*) : (*/}
																	{/* 	<Input fluid value={student.dropPoint.name} disabled />*/}
																	{/*)}*/}
																</Form.Field>

																<Form.Field>
																	<label>Drop Time</label>
																	<TimeInput
																		name="dropTimeFE"
																		closable
																		clearable
																		value={this.state.dropTimeFE !== null ? this.state.dropTimeFE : student.dropTime}
																		iconPosition="left"
																		onChange={(event, { name, value }) => {
																			// if (Object.hasOwn(name)) {
																			this.setState({ [name]: value });
																			// }
																		}}
																		disabled={!this.props.editMode}
																	/>
																</Form.Field>
															</Form.Group>
														</Form>
														{this.state.deletedMode ? (
															<Actions>
																<Button
																	primary
																	onClick={() => {
																		trackMixpanelEvent('Students', { Action: 'Restore' });
																		dispatchNoPayload('STUDENTS_RESTORE_MODAL_OPEN');
																		this.student_id = student.id;
																	}}>
																	Restore
																</Button>
															</Actions>
														) : this.props.editMode ? (
															<Actions>
																<Button
																	secondary
																	onClick={() => {
																		this.cleanEditOperation();
																	}}>
																	Cancel
																</Button>
																<Button
																	positive
																	loading={this.props.updating}
																	onClick={() => {
																		this.update(student.id);
																	}}>
																	Update
																</Button>
															</Actions>
														) : (
															<Actions>
																<Button
																	primary
																	disabled={!(this.state.manageAccess || this.state.updateAccess)}
																	onClick={() => {
																		trackMixpanelEvent('Students', { Action: 'Edit' });
																		dispatchNoPayload('STUDENTS_EDIT_MODAL_OPEN');
																		getVehiclesForDropdown(
																			`?organisations[]=${student.organisation.id}&dropdown=true`
																		).then((response) => {
																			this.setState({
																				vehicles: response,
																			});
																		});

																		getGeofenceForDropdown(
																			`?organisations[]=${student.organisation.id}&dropdown=true`
																		).then((response) => {
																			this.setState({
																				geofences: response,
																			});
																		});

																		getUsersForDropdown(
																			`?organisations[]=${student.organisation.id}&dropdown=true&type[]=Parent`
																		).then((response) => {
																			this.setState({
																				users: response,
																			});
																		});
																	}}>
																	Edit
																</Button>
																<Button
																	negative
																	disabled={!(this.state.manageAccess || this.state.deleteAccess)}
																	onClick={() => {
																		trackMixpanelEvent('Students', { Action: 'Delete' });
																		dispatchNoPayload('STUDENTS_DELETE_MODAL_OPEN');
																		this.student_id = student.id;
																	}}>
																	Delete
																</Button>
															</Actions>
														)}
													</AccordionMainContent>
												</Accordion.Content>
											)}
										</section>
									);
								})}
							</Accordion>
						) : (
							<PlaceHolder />
						)}
					</AccordionMain>
				</CategoryMain>

				<CategoryFooter background={this.deletedMode}>
					<FooterPagination
						handleDataSizeLimitChange={this.handleDataSizeLimitChange}
						pageNumber={this.props.pageNumber}
						handlePageChange={this.handlePageChange}
						count={this.props.count}
						disabled={this.props.loading || !this.props.students.length || this.props.exportingData}
					/>
				</CategoryFooter>

				{this.props.loading && !this.state.initialLoading && <TransitionLoader />}

				{this.props.addModalOpen && (
					<AddModal
						onClose={this.cleanAddOperation}
						open={this.props.addModalOpen}
						onNameChange={this.onNameChange}
						organisationOptions={this.state.organisations}
						selectedOrganisation={this.state.organisationFA}
						onOrganisationChoose={this.onOrganisationChoose}
						parentsOptions={this.state.users}
						nameError={this.state.nameError}
						organisationError={this.state.organisationError}
						busError={this.state.busError}
						selectedParent={this.state.parentsFA}
						onParentChoose={(e, { value }) => {
							this.setState({
								parentsFA: value,
							});
						}}
						pickupBusOptions={this.state.vehicles}
						selectedPickupBus={this.state.pickupBusFA}
						onPickupBusChange={(e, { value }) => {
							this.setState({
								pickupBusFA: value,
							});
						}}
						pickupPointOptions={this.state.geofences}
						selectedPickupPoint={this.state.pickupPointFA}
						onPickupPointChange={(e, { value }) => {
							this.setState({
								pickupPointFA: value,
							});
						}}
						dropBusOptions={this.state.vehicles}
						selectedDropBus={this.state.dropBusFA}
						onDropBusChange={(e, { value }) => {
							this.setState({
								dropBusFA: value,
							});
						}}
						dropPointOptions={this.state.geofences}
						selectedDropPoint={this.state.dropPointFA}
						onDropPointChange={(e, { value }) => {
							this.setState({
								dropPointFA: value,
							});
						}}
						pickupTime={this.state.pickupTimeFA}
						dropTime={this.state.dropTimeFA}
						handleActiveTimeChange={this.handleActiveTimeChange}
						onCancelClick={this.cleanAddOperation}
						disabled={!this.state.nameFA || !this.state.organisationFA || !this.state.pickupBusFA}
						onAddButtonClick={this.add}
						addButtonLoading={this.props.adding}
					/>
				)}

				{this.props.importModalOpen && (
					<Import
						template={this.fileTemplate}
						file={this.inputData}
						upload={this.importStudents}
						studentsResponse={this.state.studentsResponse}
						closeImport={this.cleanImportOperation}
					/>
				)}

				{this.props.deleteModalOpen && (
					<DeleteModal
						onClose={this.cleanDeleteOperation}
						open={this.props.deleteModalOpen}
						onCancelClick={this.cleanDeleteOperation}
						onDeleteButtonClick={() => {
							this.deleteUser(this.student_id);
						}}
						deleteButtonLoading={this.props.deleting}
					/>
				)}

				{this.props.openSendVerificationModal && (
					<SendVerificationModal
						onClose={this.cleanSendVerificationOperation}
						open={this.props.openSendVerificationModal}
						onCancelClick={this.cleanSendVerificationOperation}
						onSendButtonClick={this.sendVerificationLink}
						sendButtonLoading={this.props.sending}
					/>
				)}

				{this.props.restoreModal && (
					<RestoreModal
						onClose={this.cleanRestoreOperation}
						open={this.props.restoreModal}
						onCancelClick={this.cleanRestoreOperation}
						onRestoreButtonClick={() => {
							this.restoreUser(this.student_id);
						}}
						restoreButtonLoading={this.props.restoring}
					/>
				)}

				{/*Filter Modal*/}
				{this.props.filterModalOpen && (
					<FilterModal
						onClose={() => {
							this.setState({
								filters: {
									organisations: this.activeFilters.organisations,
								},
							});
							closeFilterModal();
							dispatch('ACTIVE_FILTER', { ...this.props.filters, organisations: [] });
						}}
						open={this.props.filterModalOpen}
						organisationsOptions={this.state.organisations}
						// organisations={this.state.filters.organisations}
						organisations={this.props.filters.organisations}
						onOrganisationFilterChange={this.onOrganisationFilterChange}
						onCancelClick={() => {
							this.setState({
								filters: {
									organisations: [],
								},
							});
							dispatch('ACTIVE_FILTER', { ...this.props.filters, organisations: [] });
							closeFilterModal();
						}}
						onResetClick={() => {
							this.activeFilters = {
								organisations: [],
							};
							this.setState({
								filters: {
									organisations: [],
								},
							});
							// closeFilterModal();
							dispatch('ACTIVE_FILTER', { ...this.props.filters, organisations: [] });
							this.getFilteredStudents();
						}}
						disabled={!this.props.filters.organisations.length}
						onApplyClick={() => {
							trackMixpanelEvent('Students', { Action: 'Filter' });
							this.activeFilters = this.state.filters;
							this.activeFilters.organisations = this.props.filters.organisations;
							this.getFilteredStudents();
							closeFilterModal();
						}}
					/>
				)}

				{this.props.openExportModal && (
					<ExportModal
						open={this.props.openExportModal}
						fileName={this.state.fileName}
						fileNameChange={this.fileNameChange}
						fileExtension={this.state.fileExtension}
						fileExtensionChange={this.fileExtensionChange}
						onDownloadClick={() => exportData(this.props.exportData, this.state.fileName, this.state.fileExtension)}
					/>
				)}
			</CategoryContentMembrane>
		);
	}
}

// Connect to Store
const mapStateToProps = (state) => ({
	students: state.students.students,
	loading: state.students.loading,
	activeIndex: state.students.activeIndex,
	count: state.students.count,
	pageNumber: state.students.pageNumber,
	dataSizeLimit: state.ui.dataSizeLimit,

	adding: state.students.adding,
	addModalOpen: state.students.addModalOpen,

	editMode: state.students.editMode,
	updating: state.students.updating,

	deleteModalOpen: state.students.deleteModalOpen,
	deleting: state.students.deleting,

	submitting: state.students.submitting,
	passwordVisible: state.students.passwordVisible,

	openVGModal: state.students.vehicleGroupModal,
	openSendVerificationModal: state.students.openSendVerificationModal,
	sending: state.students.sending,
	filterModalOpen: state.students.filterModalOpen,

	activeSort: state.students.activeSort,
	sortOrder: state.students.sortOrder,
	importModalOpen: state.students.importModalOpen,

	rowsSelected: state.students.anyRowSelected,
	selectedRows: state.students.selectedRows,

	restoreModal: state.students.restoreModal,
	restoring: state.students.restoring,

	openExportModal: state.students.openExportModal,
	exportData: state.students.exportData,
	exportingData: state.students.exportingData,
	searchQuery: state.vehicles.searchQuery,
	filters: state.vehicles.filters,
});

export default connect(mapStateToProps)(Students);
