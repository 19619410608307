import React from 'react';
import { VEHICLE_GROUPS_API } from '../../../../../logic/configs/api';
import BasicLoader from '../../../blocks/molecules/loader/BasicLoader';
import SearchBox from '../../../blocks/atoms/search-box/SearchBox';
import ToggleSwitch from '../../../blocks/molecules/toggle-switch/ToggleSwitch';
import FooterPagination from '../../../blocks/molecules/pagination/Pagination';
import DeleteModal from '../../../blocks/organisms/modals/vehicle-groups/DeleteModal';
import RestoreModal from '../../../blocks/organisms/modals/vehicle-groups/RestoreModal';
import AddModal from '../../../blocks/organisms/modals/vehicle-groups/AddModal';
import UserModal from '../../../blocks/organisms/modals/vehicle-groups/UserModal';
import VehicleModal from '../../../blocks/organisms/modals/vehicle-groups/VehicleModal';
import ExportModal from '../../../blocks/organisms/modals/vehicle-groups/ExportModal';
import { CategoryContentMembrane } from '../../../styles/containers/CategoryContentMembrane';
import { CategoryHeader } from '../../../styles/containers/CategoryHeader';
import { Expander } from '../../../styles/place-holders/Expander';
import { CategoryMain } from '../../../styles/containers/CategoryMain';
import { AccordionHeader } from '../../../styles/containers/AccordionHeader';
import { AccordionMain } from '../../../styles/containers/AccordionMain';
import { CategoryFooter } from '../../../styles/containers/CategoryFooter';
import PlaceHolder from '../../../blocks/molecules/place-holder/PlaceHolder';
import TransitionLoader from '../../../blocks/molecules/loader/TransitionLoader';
import FilterModal from '../../../blocks/organisms/modals/vehicle-groups/FilterModal';
import {
	closeFilterModal,
	exportData,
	getOrganisationsForDropdown,
	getUsersForDropdown,
	getVehiclesForDropdown,
	openFilterModal,
	refreshVehicleGroups,
	searchVehicleGroups,
	toggleAccordion,
} from '../../../../../logic/middleware/vehicleGroups';
import { dispatch, dispatchNoPayload } from '../../../../../logic/middleware/middleware';
import { connect } from 'react-redux';
import Store from '../../../../../data/store/store';
import { generateSuccessMessage, handleError, handleSuccess } from '../../../../../logic/middleware/messaging';
import { Actions, HeaderElement, Row, RowElement } from '../../../styles/Accordion';
import { Accordion, Button, Form, Icon, Input } from 'semantic-ui-react';
import { AccordionMainContent } from '../../../styles/containers/AccordionMainContent';
import accessSetter from '../../../../../logic/utilities/accessSetter';
import { trackMixpanelEvent } from '../../../../../logic/middleware/mixpanel';
import { deleteData, patchData, postData } from '../../../../../logic/middleware/services/apiRequest';
import { capitalizeWord } from '../../../../../logic/utilities/validator';
/*import { datadogLogs } from "@datadog/browser-logs";*/

// import { FILE_TYPE_EXTENSIONS } from '../../../../../data/constants/file-types';

class VehicleGroups extends React.Component {
	vehicleGroupID = '';
	VgName = '';
	timeoutId;
	isSuperAdminAccess = localStorage.getItem('isSuperAdmin');

	deletedMode = false;
	selectAllActive = false;
	sortOrder = '';
	activeSort = '';
	activeFilters = {
		organisations: null,
	};

	constructor(props) {
		super(props);

		this.state = {
			initialLoading: true,
			organisations: [],
			organisationsFA: [],
			vehiclesFA: [],
			usersFA: [],
			manageAccess: false,
			createAccess: false,
			readAccess: false,
			deleteAccess: false,
			updateAccess: false,

			nameFE: null,
			descFE: null,
			selectedUsersFE: null,
			selectedVehiclesFE: null,

			groupID: '',

			nameFA: null,
			descriptionFA: null,
			organisationFA: null,
			vehicleFA: null,
			userFA: null,

			anyRowSelected: false,
			selectedRows: new Set(),

			deletedMode: false,

			filters: {
				organisations: null,
			},

			fileName: 'Vehicle Groups',
			fileExtension: 'excel',
			searchQuery: '',
		};

		this.toggleDeletedMode = this.toggleDeletedMode.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.add = this.add.bind(this);
		this.cleanAddOperation = this.cleanAddOperation.bind(this);
		this.export = this.export.bind(this);
		this.update = this.update.bind(this);
		this.cleanEditOperation = this.cleanEditOperation.bind(this);
		this.cleanDeleteOperation = this.cleanDeleteOperation.bind(this);
		this.cleanRestoreOperation = this.cleanRestoreOperation.bind(this);
		this.getFilteredVehicleGroups = this.getFilteredVehicleGroups.bind(this);
		this.updateVehicle = this.updateVehicle.bind(this);
		this.cleanVehicleOperation = this.cleanVehicleOperation.bind(this);
		this.updateUser = this.updateUser.bind(this);
		this.cleanUserOperation = this.cleanUserOperation.bind(this);
		this.restoreVehicleGroup = this.restoreVehicleGroup.bind(this);
		this.cleanRestoreOperation = this.cleanRestoreOperation.bind(this);
		this.handleDataSizeLimitChange = this.handleDataSizeLimitChange.bind(this);
		this.deleteVehicleGroup = this.deleteVehicleGroup.bind(this);
		this.sort = this.sort.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.cleanSelectedRows = this.cleanSelectedRows.bind(this);
		this.onOrganisationFilterChange = this.onOrganisationFilterChange.bind(this);
		this.onNameChangeFE = this.onNameChangeFE.bind(this);

		this.onNameChange = this.onNameChange.bind(this);
		this.onDescriptionChange = this.onDescriptionChange.bind(this);
		this.onOrganisationChoose = this.onOrganisationChoose.bind(this);
		this.onVehicleChoose = this.onVehicleChoose.bind(this);
		this.onUserChoose = this.onUserChoose.bind(this);

		this.onUsersUpdate = this.onUsersUpdate.bind(this);

		this.fileExtensionChange = this.fileExtensionChange.bind(this);
		this.fileNameChange = this.fileNameChange.bind(this);

		this.onVehiclesUpdate = this.onVehiclesUpdate.bind(this);
		this.manageAccessSetter = this.manageAccessSetter.bind(this);
		this.handleSearchQuery = this.handleSearchQuery.bind(this);
	}

	manageAccessSetter(rule, action) {
		this.setState({ [action]: rule });
	}

	componentDidMount() {
		const permissions = JSON.parse(localStorage.getItem('permissions'));

		const VehicleGrpPermissions = permissions?.filter((permission) => permission.subject === 'VehicleGroup');
		if (VehicleGrpPermissions) {
			for (const vehicleGrpPrm of VehicleGrpPermissions) {
				accessSetter(vehicleGrpPrm?.action, this.manageAccessSetter);
			}
		}

		let path = window.location.pathname.split('/');
		if (path.length > 3) {
			this.VgName = decodeURIComponent(path[3]);

			if (this.VgName) {
				this.setState({ searchQuery: this.VgName });
				this.handleSearch({ target: { value: this.VgName } });
				return;
			}
		}

		if (this.props.searchQuery) {
			dispatch('UPDATE_SEARCH_QUERY', this.props.searchQuery);
			this.handleSearch({ target: { value: this.props.searchQuery } });
			return;
		}

		if (this.props.filters.organisations) {
			this.activeFilters = this.state.filters;
			this.activeFilters.organisations = this.props.filters.organisations;
			this.getFilteredVehicleGroups();
			return;
		}
		refreshVehicleGroups();
		trackMixpanelEvent('Vehicle Groups', { Action: 'View' });
	}

	// Fetch
	getFilteredVehicleGroups(activePage = 1, resetAccordion = true) {
		if (resetAccordion) {
			dispatch('VEHICLE_GROUPS_TOGGLE_ACCORDION', -1);
		}

		let query = '';
		if (this.state.searchQuery !== '') {
			query = query + searchVehicleGroups(this.state.searchQuery);
		}

		if (this.activeSort !== '' && this.sortOrder !== '') {
			if (this.sortOrder === 'asc') {
				query = query + `&sortBy=${this.activeSort}&orderBy=${this.sortOrder}`;
			} else {
				query = query + `&sortBy=${this.activeSort}&orderBy=${this.sortOrder}`;
			}
		}

		if (this.activeFilters.organisations) {
			query = query + this.activeFilters.organisations.map((org) => `&organisations[]=${org}`).join('');
		}

		if (this.deletedMode) {
			query = query + `&deleted=true`;
		}

		refreshVehicleGroups(query, activePage);
	}

	handleSearch(e) {
		if (this.VgName) {
			this.setState({ searchQuery: this.VgName });
			dispatch('UPDATE_SEARCH_QUERY', this.VgName);
		} else {
			this.setState({ searchQuery: e.target.value });
			dispatch('UPDATE_SEARCH_QUERY', e.target.value);
		}
		if (this.timeoutId) {
			clearTimeout(this.timeoutId);
		}

		this.timeoutId = setTimeout(() => {
			this.getFilteredVehicleGroups();
			this.cleanSelectedRows();
		}, 500);
	}

	toggleDeletedMode() {
		this.deletedMode = !this.deletedMode;
		this.setState({
			deletedMode: !this.state.deletedMode,
		});

		this.getFilteredVehicleGroups();
		this.cleanSelectedRows();
	}

	// Export
	fileNameChange(e) {
		this.setState({ fileName: e.target.value });
	}

	fileExtensionChange(e, { value }) {
		this.setState({ fileExtension: value });
	}

	handleExportSelection(checkedData, id) {
		let selectedRows = this.state.selectedRows;

		if (selectedRows.has(id)) {
			selectedRows.delete(id);
		} else selectedRows.add(id);

		this.setState({
			selectedRows: selectedRows,
			anyRowSelected: !!selectedRows.size,
		});
	}

	handleSelectAll(checked) {
		let selectedRows = new Set();

		if (checked) {
			this.selectAllActive = true;
			this.props.vehicleGroups.forEach((group) => selectedRows.add(group.id));
			this.setState({
				selectedRows: selectedRows,
				anyRowSelected: !!selectedRows.size,
			});
		} else {
			this.selectAllActive = false;
			this.setState({
				selectedRows: new Set(),
				anyRowSelected: !!selectedRows.size,
			});
		}
	}

	export() {
		const { vehicleGroups } = this.props;

		let vehicleGroupsToExport = [];
		vehicleGroups.forEach((group) => {
			if (this.state.selectedRows.has(group.id)) {
				vehicleGroupsToExport.push(group);
			}
		});

		return vehicleGroupsToExport;
	}

	getFilterListQuery() {
		let query = '';

		if (this.state.searchQuery !== '') {
			query = query + searchVehicleGroups(this.state.searchQuery);
		}

		query = query + `&fileType[]=${this.state.fileExtension}`;

		if (this.activeSort !== '' && this.sortOrder !== '') {
			if (this.sortOrder === 'asc') {
				query = query + `&sort=${this.activeSort}`;
			} else {
				query = query + `&sort=-${this.activeSort}`;
			}
		}

		if (this.activeFilters.organisations) {
			query = query + `&organisation=${this.activeFilters.organisations}`;
		}

		if (this.deletedMode) {
			query = query + `&deleted=1`;
		}

		return query;
	}

	// Add
	add() {
		const { nameFA, descriptionFA, organisationFA, vehicleFA, userFA } = this.state;
		let query = {};
		if (nameFA) query = { ...query, name: nameFA.trim() };

		if (descriptionFA) query = { ...query, description: descriptionFA.trim() };

		if (organisationFA)
			query = {
				...query,
				organisation: {
					id: organisationFA,
				},
			};

		if (vehicleFA !== null)
			query = {
				...query,
				vehicles: vehicleFA.map((vehicle) => {
					return {
						id: vehicle,
					};
				}),
			};

		if (userFA !== null)
			query = {
				...query,
				users: userFA.map((user) => {
					return {
						id: user,
					};
				}),
			};
		dispatchNoPayload('VEHICLE_GROUPS_ADDING');

		// axios
		// 	.post(VEHICLE_GROUPS_API, query, {
		// 		headers: {
		// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 			'Content-Type': 'application/json',
		// 		},
		// 	})
		postData(VEHICLE_GROUPS_API, '', query)
			.then((response) => {
				dispatchNoPayload('VEHICLE_GROUPS_ADDED');
				generateSuccessMessage(response);
				this.getFilteredVehicleGroups(this.props.pageNumber);
				this.cleanAddOperation();
			})
			.catch((error) => {
				dispatchNoPayload('VEHICLE_GROUPS_ADDED');
				/*datadogLogs.logger.error('Vehicle group add api', {}, error);*/
				handleError(error);
			});
	}

	cleanAddOperation() {
		dispatchNoPayload('VEHICLE_GROUPS_ADD_MODAL_CLOSE');

		this.setState({
			organisationsFA: [
				{
					key: -1,
					value: -1,
					text: 'Fetching...',
				},
			],
			vehiclesFA: [],
			usersFA: [],
			nameFA: null,
			descriptionFA: null,
			organisationFA: null,
			vehicleFA: null,
			userFA: null,
		});
	}

	onNameChange(e, { value }) {
		const capitalizedValue = capitalizeWord(value);
		this.setState({
			nameFA: capitalizedValue,
		});
	}

	onDescriptionChange(e) {
		this.setState({
			descriptionFA: e.target.value,
		});
	}

	onOrganisationChoose(e, { value }) {
		this.setState({
			organisationFA: value,
			vehicleFA: null,
			userFA: null,
		});
		getVehiclesForDropdown(`?dropdown=true&organisations[]=${value}`).then((response) => {
			this.setState({ vehiclesFA: response });
		});
		getUsersForDropdown(`?dropdown=true&organisations[]=${value}`).then((response) => {
			this.setState({ usersFA: response });
		});
	}

	onVehicleChoose(e, { value }) {
		this.setState({
			vehicleFA: value,
		});
	}

	onNameChangeFE(e, { value }) {
		const capitalizedValue = capitalizeWord(value);
		this.setState({
			nameFE: capitalizedValue,
		});
	}

	onUserChoose(e, { value }) {
		this.setState({
			userFA: value,
		});
	}

	// Update
	updateUser(id) {
		let query = {};

		if (this.state.selectedUsersFE)
			query = {
				...query,
				users: this.state.selectedUsersFE.map((user) => {
					return {
						id: user,
					};
				}),
			};
		dispatchNoPayload('VEHICLE_GROUPS_UPDATING');

		// axios
		// 	.patch(VEHICLE_GROUPS_API + `/${id}`, query, {
		// 		headers: {
		// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 		},
		// 	})
		patchData(VEHICLE_GROUPS_API + `/${id}`, query)
			.then((response) => {
				dispatchNoPayload('VEHICLE_GROUPS_UPDATED');
				generateSuccessMessage(response);
				this.getFilteredVehicleGroups(this.props.pageNumber, false);
				this.cleanUserOperation();
			})
			.catch((error) => {
				dispatchNoPayload('VEHICLE_GROUPS_UPDATED');

				handleError(error);
			});
	}

	cleanUserOperation() {
		dispatchNoPayload('VEHICLE_GROUPS_USER_MODAL_CLOSE');

		this.setState({
			selectedUsersFE: null,
		});
	}

	onUsersUpdate(e, { value }) {
		this.setState({ selectedUsersFE: value });
	}

	// Update Vehicle
	updateVehicle(id) {
		let query = {};

		if (this.state.selectedVehiclesFE)
			query = {
				...query,
				vehicles: this.state.selectedVehiclesFE.map((vehicle) => {
					return {
						id: vehicle,
					};
				}),
			};
		dispatchNoPayload('VEHICLE_GROUPS_UPDATING');

		// axios
		// 	.patch(VEHICLE_GROUPS_API + `/${id}`, query, {
		// 		headers: {
		// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 		},
		// 	})
		patchData(VEHICLE_GROUPS_API + `/${id}`, query)
			.then((response) => {
				Store.dispatch({ type: 'VEHICLE_GROUPS_UPDATED' });
				generateSuccessMessage(response);
				this.getFilteredVehicleGroups(this.props.pageNumber, false);
				this.cleanVehicleOperation();
			})
			.catch((error) => {
				Store.dispatch({ type: 'VEHICLE_GROUPS_UPDATED' });

				handleError(error);
			});
	}

	cleanVehicleOperation() {
		dispatchNoPayload('VEHICLE_GROUPS_VEHICLES_MODAL_CLOSE');

		this.setState({
			selectedVehiclesFE: null,
		});
	}

	onVehiclesUpdate(e, { value }) {
		this.setState({ selectedVehiclesFE: value });
	}

	// Update
	update(id) {
		const { nameFE, descFE } = this.state;
		let query = {};

		if (nameFE) query = { ...query, name: nameFE.trim() };

		query = descFE === null ? query : { ...query, description: descFE.trim() };

		// if (selectedVehiclesFE.length)
		//   query = {
		//     ...query,
		//     vehicles: selectedVehiclesFE.map((vehicle) => {
		//       return {
		//         id: vehicle,
		//       };
		//     }),
		//   };
		//
		// if (selectedUsersFE.length)
		//   query = {
		//     ...query,
		//     users: selectedUsersFE.map((user) => {
		//       return {
		//         id: user,
		//       };
		//     }),
		//   };

		dispatchNoPayload('VEHICLE_GROUPS_UPDATING');

		// axios
		// 	.patch(VEHICLE_GROUPS_API + `/${id}`, query, {
		// 		headers: {
		// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 		},
		// 	})
		patchData(VEHICLE_GROUPS_API + `/${id}`, query)
			.then((response) => {
				dispatchNoPayload('VEHICLE_GROUPS_UPDATED');
				generateSuccessMessage(response);
				this.getFilteredVehicleGroups(this.props.pageNumber, false);
				this.cleanEditOperation();
			})
			.catch((error) => {
				dispatchNoPayload('VEHICLE_GROUPS_UPDATED');
				handleError(error);
			});
	}

	cleanEditOperation() {
		dispatchNoPayload('VEHICLE_GROUPS_EDIT_MODAL_CLOSE');

		this.setState({
			nameFE: null,
			descFE: null,
			selectedUsersFE: null,
			selectedVehiclesFE: null,
			groupID: '',
			vehiclesFA: [],
			usersFA: [],
		});
	}

	// Delete
	deleteVehicleGroup(id) {
		dispatchNoPayload('VEHICLE_GROUPS_DELETING');
		// axios
		// 	.delete(VEHICLE_GROUPS_API + `/${id}`, {
		// 		headers: {
		// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 		},
		// 	})
		deleteData(VEHICLE_GROUPS_API, `/${id}`)
			.then((response) => {
				dispatchNoPayload('VEHICLE_GROUPS_DELETED');
				generateSuccessMessage(response);

				this.getFilteredVehicleGroups(this.props.pageNumber);
				this.cleanDeleteOperation();
			})
			.catch((error) => {
				dispatchNoPayload('VEHICLE_GROUPS_DELETED');
				handleError(error);
			});
	}

	cleanDeleteOperation() {
		dispatchNoPayload('VEHICLE_GROUPS_DELETE_MODAL_CLOSE');

		this.vehicleGroupID = '';
	}

	// Restore
	restoreVehicleGroup(id) {
		dispatchNoPayload('VEHICLE_GROUPS_RESTORING');
		// axios
		// 	.post(
		// 		VEHICLE_GROUPS_API + `/${id}/restore`,
		// 		{},
		// 		{
		// 			headers: {
		// 				Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 			},
		// 		}
		// 	)
		postData(VEHICLE_GROUPS_API, `/${id}/restore`)
			.then((response) => {
				dispatchNoPayload('VEHICLE_GROUPS_RESTORED');
				handleSuccess(response);
				generateSuccessMessage(response, 'Vehicle Group Restored');
				this.getFilteredVehicleGroups(this.props.pageNumber);
				this.cleanRestoreOperation();
			})
			.catch((error) => {
				dispatchNoPayload('VEHICLE_GROUPS_RESTORED');
				/*datadogLogs.logger.error('Vehicle group restore api', {}, error);*/
				handleError(error);
			});
	}

	cleanRestoreOperation() {
		dispatchNoPayload('VEHICLE_GROUPS_RESTORE_MODAL_CLOSE');

		this.vehicleGroupID = '';
	}

	sort(param, activeSort, sortOrder) {
		if (!param) {
			this.activeSort = param;
			this.sortOrder = '';
		} else {
			if (activeSort !== param) {
				this.sortOrder = 'asc';
				this.activeSort = param;
			} else {
				if (sortOrder === 'asc') {
					this.sortOrder = 'desc';
				} else {
					this.sortOrder = 'asc';
				}
			}
		}

		dispatch('VEHICLE_GROUPS_SORT_ORDER', this.sortOrder);
		dispatch('VEHICLE_GROUPS_ACTIVE_ORDER', this.activeSort);
		this.getFilteredVehicleGroups(this.props.pageNumber);
	}

	onOrganisationFilterChange(e, { value }) {
		// this.setState({
		// 	filters: { ...this.state.filters, organisations: value },
		// });
		dispatch('ACTIVE_FILTER', { ...this.props.filters, organisations: value });
	}

	// Pagination
	handleDataSizeLimitChange() {
		this.getFilteredVehicleGroups();
	}

	handlePageChange(activePage) {
		this.getFilteredVehicleGroups(activePage);
		this.cleanSelectedRows();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.vehicleGroups !== this.props.vehicleGroups) {
			if (this.selectAllActive) this.handleSelectAll(true);
		}

		if (prevProps.pageNumber !== this.props.pageNumber) {
			document.getElementById('AccordionMain').scrollTo(0, 0);
		}

		if (prevProps.loading !== this.props.loading) {
			if (!this.props.loading) {
				this.setState({
					initialLoading: false,
				});
			}
		}
	}

	cleanSelectedRows() {
		this.setState({
			anyRowSelected: false,
			selectedRows: new Set(),
		});
	}

	componentWillUnmount() {
		dispatchNoPayload('VEHICLE_GROUPS_UNMOUNT');
	}

	handleSearchQuery(value) {
		dispatch('UPDATE_SEARCH_QUERY', value);
		this.setState({ searchQuery: value });
	}

	render() {
		return (
			<CategoryContentMembrane>
				<CategoryHeader>
					<SearchBox
						placeholder="Search in vehicle groups"
						searchQuery={this.state.searchQuery}
						clearSearchIconClick={() => {
							this.setState({ searchQuery: '' }, () => {
								this.VgName = '';
								this.getFilteredVehicleGroups(this.props.pageNumber);
							});
							dispatch('UPDATE_SEARCH_QUERY', '');
						}}
						value={this.props.searchQuery}
						handleSearchQuery={this.handleSearchQuery}
						handleSearch={this.handleSearch}
						// disabled={!this.state.searchQuery && (this.props.loading || this.props.exportingData)}
					/>
					<Button
						icon
						primary
						disabled={this.props.loading || this.props.exportingData}
						title="Refresh Vehicle Groups"
						onClick={() => this.getFilteredVehicleGroups(this.props.pageNumber)}>
						<Icon name="refresh" />
					</Button>
					<Expander />

					{this.isSuperAdminAccess === 'true' && (
						<Button
							icon
							primary={!this.activeFilters.organisations?.length}
							disabled={this.props.loading || this.props.exportingData}
							color={this.activeFilters.organisations?.length ? 'orange' : null}
							title="Filter Users"
							onClick={() => {
								openFilterModal();
								getOrganisationsForDropdown(`?dropdown=true`).then((response) => {
									this.setState({ organisations: response });
								});
							}}>
							<Icon name="filter" />
						</Button>
					)}

					{/*Add*/}
					{!this.state.deletedMode && (
						<Button
							primary
							disabled={
								this.props.loading || this.props.exportingData || !(this.state.manageAccess || this.state.createAccess)
							}
							title="Add Vehicle Group"
							onClick={() => {
								trackMixpanelEvent('Vehicle Groups', { Action: 'Add' });
								dispatchNoPayload('VEHICLE_GROUPS_ADD_MODAL_OPEN');
								getOrganisationsForDropdown(`?dropdown=true`).then((response) => {
									this.setState({ organisationsFA: response });
								});
							}}>
							Add
						</Button>
					)}

					{/*Export*/}
					{/*<Button*/}
					{/*	primary*/}
					{/*	disabled={*/}
					{/*		!this.state.anyRowSelected ||*/}
					{/*		this.props.loading ||*/}
					{/*		!this.props.vehicleGroups.length ||*/}
					{/*		this.props.exportingData*/}
					{/*	}*/}
					{/*	title={*/}
					{/*		!this.state.anyRowSelected ? 'Please select atleast one vehicle group to export' : 'Export Vehicle Groups'*/}
					{/*	}*/}
					{/*	onClick={() => {*/}
					{/*		openExportModal();*/}
					{/*		dispatch('SET_VEHICLE_GROUPS_EXPORT_DATA', this.export());*/}
					{/*	}}>*/}
					{/*	Export*/}
					{/*</Button>*/}

					{/*Export All*/}

					{/*<Button.Group>*/}
					{/*	<Button*/}
					{/*		primary*/}
					{/*		title="Export All Vehicle Groups"*/}
					{/*		loading={this.props.exportingData}*/}
					{/*		disabled={this.props.loading || !this.props.vehicleGroups.length || this.props.exportingData}*/}
					{/*		onClick={() => exportAll(this.getFilterListQuery(), this.state.fileExtension)}>*/}
					{/*		Export*/}
					{/*	</Button>*/}
					{/*	<Dropdown*/}
					{/*		style={{ background: "#ffffff", color: "#1b3c71", borderColor: "#1b3c71" }}*/}
					{/*		className="button icon"*/}
					{/*		floating*/}
					{/*		value={this.state.fileExtension}*/}
					{/*		options={FILE_TYPE_EXTENSIONS}*/}
					{/*		defaultValue={this.state.fileExtension}*/}
					{/*		onChange={this.state.fileExtensionChange}*/}
					{/*		trigger={<></>}*/}
					{/*	/>*/}
					{/*</Button.Group>*/}
					{this.isSuperAdminAccess === 'true' && (
						<ToggleSwitch
							title={this.deletedMode ? 'Hide Deleted Vehicle Groups' : 'Show Deleted Vehicle Groups'}
							label="Deleted Mode"
							checked={this.deletedMode}
							disabled={this.props.loading || this.props.exportingData}
							action={this.toggleDeletedMode}
						/>
					)}
				</CategoryHeader>

				<CategoryMain>
					<AccordionHeader background={this.deletedMode}>
						{/*<HeaderElement*/}
						{/*	style={{ marginRight: 0 }}*/}
						{/*	width="3%"*/}
						{/*	disabled={this.props.loading || this.props.exportingData || !this.props.vehicleGroups.length}>*/}
						{/*	<CheckboxContainer>*/}
						{/*		<Checkbox*/}
						{/*			checked={*/}
						{/*				this.props.vehicleGroups.length !== 0 &&*/}
						{/*				this.state.selectedRows.size === this.props.vehicleGroups.length*/}
						{/*			}*/}
						{/*			indeterminate={*/}
						{/*				this.state.selectedRows.size !== 0 &&*/}
						{/*				this.state.selectedRows.size !== this.props.vehicleGroups.length*/}
						{/*			}*/}
						{/*			onClick={(e, data) => {*/}
						{/*				this.handleSelectAll(data.checked);*/}
						{/*			}}*/}
						{/*		/>*/}
						{/*	</CheckboxContainer>*/}
						{/*</HeaderElement>*/}
						<HeaderElement
							style={{ marginRight: 0 }}
							width="7%"
							disabled={this.props.loading || this.props.exportingData || !this.props.vehicleGroups.length}
							onClick={() => {
								this.sort('', '', '');
							}}>
							No.
						</HeaderElement>
						<HeaderElement
							style={{ marginRight: 0 }}
							width="15%"
							onClick={() => {
								this.sort('name', this.props.activeSort, this.props.sortOrder);
							}}
							disabled={this.props.loading || this.props.exportingData || !this.props.vehicleGroups.length}
							activeSort={this.activeSort === 'name'}>
							{this.props.activeSort === 'name' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Name
						</HeaderElement>
						<HeaderElement
							style={{ marginRight: 0 }}
							width="20%"
							onClick={() => {
								this.sort('organisation', this.props.activeSort, this.props.sortOrder);
							}}
							disabled={this.props.loading || this.props.exportingData || !this.props.vehicleGroups.length}
							activeSort={this.activeSort === 'organisation'}>
							{this.props.activeSort === 'organisation' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Organisation
						</HeaderElement>
						<HeaderElement
							style={{ marginRight: 0 }}
							width="10%"
							disabled={this.props.loading || this.props.exportingData || !this.props.vehicleGroups.length}>
							Vehicles
						</HeaderElement>
						<HeaderElement
							style={{ marginRight: 0 }}
							width="10%"
							disabled={this.props.loading || this.props.exportingData || !this.props.vehicleGroups.length}>
							Users
						</HeaderElement>
						<HeaderElement
							style={{ marginRight: 0 }}
							width="25%"
							onClick={() => {
								this.sort('description', this.props.activeSort, this.props.sortOrder);
							}}
							disabled={this.props.loading || this.props.exportingData || !this.props.vehicleGroups.length}
							activeSort={this.activeSort === 'description'}>
							{this.props.activeSort === 'description' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Description
						</HeaderElement>
						{this.deletedMode && (
							<HeaderElement
								style={{ marginRight: 0 }}
								width="10%"
								disabled={this.props.loading || this.props.exportingData || !this.props.vehicleGroups.length}>
								Deleted date
							</HeaderElement>
						)}
					</AccordionHeader>

					<AccordionMain id="AccordionMain">
						{this.state.initialLoading ? (
							<BasicLoader />
						) : this.props.vehicleGroups.length ? (
							<Accordion fluid>
								{this.props.vehicleGroups.map((group, index) => {
									return (
										<section key={index}>
											<Accordion.Title
												active={this.props.activeIndex === index}
												index={index}
												onClick={(event, data) => {
													toggleAccordion(event, data, this.props.activeIndex);
													this.cleanEditOperation();
												}}
												style={{ padding: 0 }}>
												<Row>
													{/*<RowElement width="3%" style={{ marginRight: 0 }}>*/}
													{/*	<CheckboxContainer>*/}
													{/*		<Checkbox*/}
													{/*			checked={this.state.selectedRows.has(group.id)}*/}
													{/*			onChange={(e, data) => {*/}
													{/*				e.stopPropagation();*/}
													{/*				this.handleExportSelection(data.checked, group.id);*/}
													{/*			}}*/}
													{/*		/>*/}
													{/*	</CheckboxContainer>*/}
													{/*</RowElement>*/}
													<RowElement width="7%" style={{ marginRight: 0 }}>
														{1 + index + (this.props.pageNumber - 1) * this.props.dataSizeLimit}
													</RowElement>
													<RowElement
														width="15%"
														title={group.name ? group.name : 'Not Available'}
														style={{ marginRight: 0, color: `${group.name ? 'black' : 'grey'}` }}>
														{group.name ? group.name : 'Not Available'}
													</RowElement>
													<RowElement
														width="20%"
														style={{
															marginRight: 0,
															textOverflow: 'ellipsis',
															overflow: 'hidden',
															whiteSpace: 'nowrap',
															color: `${group.organisation.name ? 'black' : 'grey'}`,
														}}
														title={group.organisation.name ? group.organisation.name : 'Not Available'}>
														{group.organisation.name ? group.organisation.name : 'Not Available'}
													</RowElement>
													<RowElement
														width="10%"
														style={{ marginRight: 0, color: `${group.vehicleCount ? 'black' : 'grey'}` }}
														title={
															group.vehicles.map((vehicle) => vehicle.registrationNumber).join(', ')
																? group.vehicleCount
																: 'Not Available'
														}>
														{group.vehicleCount ? group.vehicleCount : 'Not Available'}
													</RowElement>
													<RowElement
														width="10%"
														style={{ marginRight: 0, color: `${group.userCount ? 'black' : 'grey'}` }}
														title={group.users.map((user) => user.name).join(', ') ? group.userCount : 'Not Available'}>
														{group.userCount ? group.userCount : 'Not Available'}
													</RowElement>
													<RowElement
														width="25%"
														style={{
															marginRight: 0,
															color: `${group.description ? 'black' : 'grey'}`,
															textOverflow: 'ellipsis',
															overflow: 'hidden',
															whiteSpace: 'nowrap',
														}}
														title={group.description ? group.description : 'Not Available'}>
														{group.description ? group.description : 'Not Available'}
													</RowElement>
													{this.deletedMode && (
														<RowElement
															width="10%"
															style={{ marginRight: 0, color: `${group.deletedDate ? 'black' : 'grey'}` }}
															title={group.deletedDate ? group.deletedDate : 'Not Available'}>
															{group.deletedDate ? group.deletedDate : 'Not Available'}
														</RowElement>
													)}
												</Row>
											</Accordion.Title>
											{this.props.activeIndex === index && (
												<Accordion.Content active={this.props.activeIndex === index}>
													<AccordionMainContent>
														<Form>
															<Form.Group widths="equal">
																<Form.Field required>
																	<label>Name</label>
																	<Input
																		fluid
																		value={
																			this.state.nameFE !== null
																				? this.state.nameFE
																				: group.name === null
																				? ''
																				: group.name
																		}
																		disabled={!this.props.editMode}
																		// onChange={(e) => this.setState({ nameFE: e.target.value })}
																		onChange={this.onNameChangeFE}
																	/>
																</Form.Field>
																<Form.Field>
																	<label>Description</label>
																	<Input
																		fluid
																		value={
																			this.state.descFE !== null
																				? this.state.descFE
																				: group.description === null
																				? ''
																				: group.description
																		}
																		disabled={!this.props.editMode}
																		onChange={(e) => this.setState({ descFE: e.target.value })}
																	/>
																</Form.Field>
															</Form.Group>
														</Form>
														{this.state.deletedMode ? (
															<Actions>
																<Button
																	primary
																	onClick={() => {
																		// trackMixpanelEvent('Vehicle Groups', { Action: 'Restore' });
																		dispatchNoPayload('VEHICLE_GROUPS_RESTORE_MODAL_OPEN');
																		this.vehicleGroupID = group.id;
																	}}>
																	Restore
																</Button>
															</Actions>
														) : this.props.editMode ? (
															<Actions>
																<Button secondary onClick={this.cleanEditOperation}>
																	Cancel
																</Button>
																<Button
																	positive
																	loading={this.props.updating}
																	onClick={() => {
																		trackMixpanelEvent('Vehicle Groups', { Action: 'Update' });
																		this.update(group.id);
																	}}>
																	Update
																</Button>
															</Actions>
														) : (
															<Actions>
																<Button
																	primary
																	disabled={!(this.state.manageAccess || this.state.updateAccess)}
																	onClick={() => {
																		trackMixpanelEvent('Vehicle Groups', { Action: 'Edit' });
																		dispatchNoPayload('VEHICLE_GROUPS_EDIT_MODAL_OPEN');
																	}}>
																	Edit
																</Button>
																<Button
																	color="teal"
																	title="Edit Vehicles of Vehicle Group"
																	disabled={!(this.state.manageAccess || this.state.updateAccess)}
																	onClick={() => {
																		trackMixpanelEvent('Vehicle Groups', { Action: 'Edit Vehicles' });
																		this.setState({
																			selectedVehiclesFE: group.vehicles.map((group) => group.id),
																			groupID: group.id,
																		});
																		dispatchNoPayload('VEHICLE_GROUPS_VEHICLES_MODAL_OPEN');
																		getVehiclesForDropdown(
																			`?organisations[]=${group.organisation.id}&dropdown=true`
																		).then((response) => {
																			this.setState({ vehiclesFA: response });
																		});
																	}}>
																	Edit Vehicles
																</Button>
																<Button
																	color="teal"
																	disabled={!(this.state.manageAccess || this.state.updateAccess)}
																	title="Edit Users of Vehicle Group"
																	onClick={() => {
																		trackMixpanelEvent('Vehicle Groups', { Action: 'Edit Users' });
																		this.setState({
																			selectedUsersFE: group.users.map((group) => group.id),
																			groupID: group.id,
																		});
																		dispatchNoPayload('VEHICLE_GROUPS_USER_MODAL_OPEN');
																		getUsersForDropdown(`?organisations[]=${group.organisation.id}&dropdown=true`).then(
																			(response) => {
																				this.setState({ usersFA: response });
																			}
																		);
																	}}>
																	Edit Users
																</Button>
																<Button
																	negative
																	disabled={!(this.state.manageAccess || this.state.deleteAccess)}
																	onClick={() => {
																		trackMixpanelEvent('Vehicle Groups', { Action: 'Delete' });
																		dispatchNoPayload('VEHICLE_GROUPS_DELETE_MODAL_OPEN');
																		this.vehicleGroupID = group.id;
																	}}>
																	Delete
																</Button>
															</Actions>
														)}
													</AccordionMainContent>
												</Accordion.Content>
											)}
										</section>
									);
								})}
							</Accordion>
						) : (
							<PlaceHolder />
						)}
					</AccordionMain>
				</CategoryMain>

				<CategoryFooter background={this.deletedMode}>
					<FooterPagination
						handleDataSizeLimitChange={this.handleDataSizeLimitChange}
						pageNumber={this.props.pageNumber}
						handlePageChange={this.handlePageChange}
						count={this.props.count}
						disabled={this.props.loading || !this.props.vehicleGroups.length || this.props.exportingData}
					/>
				</CategoryFooter>

				{this.props.loading && !this.state.initialLoading && <TransitionLoader />}

				{this.props.addModalOpen && (
					<AddModal
						onClose={this.cleanAddOperation}
						open={this.props.addModalOpen}
						onNameChange={this.onNameChange}
						onDescriptionChange={this.onDescriptionChange}
						organisationOptions={this.state.organisationsFA}
						selectedOrganisation={this.state.organisationFA}
						onOrganisationChoose={this.onOrganisationChoose}
						vehiclesOptions={this.state.vehiclesFA}
						selectedVehicles={this.state.vehicleFA}
						onVehicleChoose={this.onVehicleChoose}
						usersOptions={this.state.usersFA}
						selectedUser={this.state.userFA}
						onUserChoose={this.onUserChoose}
						onCancelClick={this.cleanAddOperation}
						disabled={!this.state.nameFA || !this.state.organisationFA}
						onAddButtonClick={this.add}
						addButtonLoading={this.props.adding}
					/>
				)}

				{this.props.userModalOpen && (
					<UserModal
						onClose={this.cleanUserOperation}
						open={this.props.userModalOpen}
						selectedUsers={this.state.selectedUsersFE}
						usersOptions={this.state.usersFA}
						onUsersUpdate={this.onUsersUpdate}
						onCancelClick={this.cleanUserOperation}
						onUpdateButtonClick={() => this.updateUser(this.state.groupID)}
						updateButtonLoading={this.props.updating}
					/>
				)}

				{this.props.vehiclesModalOpen && (
					<VehicleModal
						onClose={this.cleanVehicleOperation}
						open={this.props.vehiclesModalOpen}
						selectedVehicles={this.state.selectedVehiclesFE}
						vehiclesOptions={this.state.vehiclesFA}
						onVehiclesUpdate={this.onVehiclesUpdate}
						onCancelClick={this.cleanVehicleOperation}
						onUpdateButtonClick={() => this.updateVehicle(this.state.groupID)}
						updateButtonLoading={this.props.updating}
					/>
				)}

				{this.props.deleteModalOpen && (
					<DeleteModal
						onClose={this.cleanDeleteOperation}
						open={this.props.deleteModalOpen}
						onCancelClick={this.cleanDeleteOperation}
						onDeleteButtonClick={() => {
							this.deleteVehicleGroup(this.vehicleGroupID);
						}}
						deleteButtonLoading={this.props.deleting}
					/>
				)}

				{this.props.restoreModal && (
					<RestoreModal
						onClose={this.cleanRestoreOperation}
						open={this.props.restoreModal}
						onCancelClick={this.cleanRestoreOperation}
						onRestoreButtonClick={() => {
							this.restoreVehicleGroup(this.vehicleGroupID);
						}}
						restoreButtonLoading={this.props.restoring}
					/>
				)}

				{/*Filter Modal*/}
				{this.props.filterModalOpen && (
					<FilterModal
						onClose={() => {
							this.setState({
								filters: {
									organisations: this.activeFilters.organisations,
								},
							});
							closeFilterModal();
							dispatch('ACTIVE_FILTER', { ...this.props.filters, organisations: [] });
						}}
						open={this.props.filterModalOpen}
						organisationsOptions={this.state.organisations}
						organisations={this.props.filters.organisations}
						onOrganisationFilterChange={this.onOrganisationFilterChange}
						onCancelClick={() => {
							this.setState({
								filters: {
									organisations: null,
								},
							});
							closeFilterModal();
							dispatch('ACTIVE_FILTER', { ...this.props.filters, organisations: [] });
						}}
						onResetClick={() => {
							this.activeFilters = {
								organisations: null,
							};
							this.setState({
								filters: {
									organisations: null,
								},
							});
							// closeFilterModal();
							dispatch('ACTIVE_FILTER', { ...this.props.filters, organisations: [] });
							this.getFilteredVehicleGroups();
						}}
						onApplyClick={() => {
							this.activeFilters = this.state.filters;
							this.activeFilters.organisations = this.props.filters.organisations;
							this.getFilteredVehicleGroups();
							closeFilterModal();
						}}
					/>
				)}

				{this.props.openExportModal && (
					<ExportModal
						open={this.props.openExportModal}
						fileName={this.state.fileName}
						fileNameChange={this.fileNameChange}
						fileExtension={this.state.fileExtension}
						fileExtensionChange={this.fileExtensionChange}
						onDownloadClick={() => exportData(this.props.exportData, this.state.fileName, this.state.fileExtension)}
					/>
				)}
			</CategoryContentMembrane>
		);
	}
}

// Connect to Store
const mapStateToProps = (state) => ({
	vehicleGroups: state.vehicleGroups.vehicleGroups,
	loading: state.vehicleGroups.loading,
	activeIndex: state.vehicleGroups.activeIndex,
	count: state.vehicleGroups.count,
	pageNumber: state.vehicleGroups.pageNumber,
	dataSizeLimit: state.ui.dataSizeLimit,

	adding: state.vehicleGroups.adding,
	addModalOpen: state.vehicleGroups.addModalOpen,

	editMode: state.vehicleGroups.editMode,
	updating: state.vehicleGroups.updating,
	userModalOpen: state.vehicleGroups.userModal,
	vehiclesModalOpen: state.vehicleGroups.vehicleModal,
	filterModalOpen: state.vehicleGroups.filterModalOpen,

	deleteModalOpen: state.vehicleGroups.deleteModalOpen,
	deleting: state.vehicleGroups.deleting,

	activeSort: state.vehicleGroups.activeSort,
	sortOrder: state.vehicleGroups.sortOrder,

	restoreModal: state.vehicleGroups.restoreModal,
	restoring: state.vehicleGroups.restoring,

	openExportModal: state.vehicleGroups.openExportModal,
	exportData: state.vehicleGroups.exportData,
	exportingData: state.vehicleGroups.exportingData,
	searchQuery: state.vehicles.searchQuery,
	filters: state.vehicles.filters,
});

export default connect(mapStateToProps)(VehicleGroups);
