import { EXPORT_API, ORGANISATIONS_API, ORGANISATIONS_DASHBOARD_API } from '../configs/api';
import { ORGANISATIONS_FILE_HEADER } from '../../data/constants/general';
import { STATES_AND_CITIES } from '../../data/constants/state-cities';
import { createOrganisationDTO } from '../dto/organisations';
import Logo from '../../ui/assets/images/logo.png';
import { handleError } from './messaging';
import { exportToExcelWithLogo } from '../utilities/excel';
import { dispatch, dispatchNoPayload } from './middleware';
import Store from '../../data/store/store';
import { jsPDF } from 'jspdf';
import fileDownload from 'js-file-download';
import { exportApi, getData } from './services/apiRequest';
/*import { datadogLogs } from '@datadog/browser-logs';*/

export function fetchOrganisations(params = '') {
	return getData(ORGANISATIONS_API, params);
	// axios(ORGANISATIONS_API + params, {
	// 	headers: {
	// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 		'Content-Type': 'application/json',
	// 	},
	// });
}

export function fetchDashboardOrganisations(params = '') {
	return getData(ORGANISATIONS_DASHBOARD_API, params);
	// axios(ORGANISATIONS_DASHBOARD_API + params, {
	// 	headers: {
	// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 		'Content-Type': 'application/json',
	// 	},
	// });
}

export function refreshOrganisations(params = '', activePage = 1, resetOffset = false) {
	dispatchNoPayload('ORGANISATIONS_LOADING');
	// axios(
	// 	ORGANISATIONS_API +
	// 		'?pagecount=' +
	// 		getPageNumberFromStore().dataSizeLimit +
	// 		'&pagenumber=' +
	// 		(resetOffset ? 1 : activePage) +
	// 		params,
	// 	{
	// 		headers: {
	// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 			'Content-Type': 'application/json',
	// 		},
	// 	}
	// )
	getData(
		ORGANISATIONS_API,
		'?pagecount=' + getPageNumberFromStore().dataSizeLimit + '&pagenumber=' + (resetOffset ? 1 : activePage) + params
	)
		.then((response) => {
			dispatch('FEED_ORGANISATIONS', createOrganisationDTO(response?.data?.data));
			dispatch('ORGANISATIONS_SET_PAGE_COUNT', response?.data?.count || 0);
			dispatch('ORGANISATIONS_SET_PAGE_NUMBER', activePage);
		})
		.catch((error) => {
			dispatchNoPayload('ORGANISATIONS_LOADED');
			/*datadogLogs.logger.error('Refresh organisation api', {}, error);*/
			handleError(error);
		});
}

export function searchOrganisations(query) {
	return `&search=${encodeURIComponent(query)
		.trim()
		.split('+')
		.map((searchTerm) => `${searchTerm}`)}`;
}

// Verification
export function openSendVerificationModal() {
	dispatchNoPayload('ORGANISATIONS_SEND_VERIFICATION_MODAL_OPEN');
}

export function closeSendVerificationModal() {
	dispatchNoPayload('ORGANISATIONS_SEND_VERIFICATION_MODAL_CLOSE');
}

// Export
export function openExportModal() {
	dispatchNoPayload('ORGANISATIONS_EXPORT_MODAL_OPEN');
}

export function closeExportModal() {
	dispatchNoPayload('ORGANISATIONS_EXPORT_MODAL_CLOSE');
}

export function formatExcelExport(response) {
	return response.map((org) => {
		return {
			Organisation: org.name,
			Email: org.email,
			Phone: org.phone,
			// 'Company Address': org.address,
			// Website: org.website,
			// CIN: org.CIN,
			// GST: org.GST,
			// PAN: org.PAN,
			STATE: org.state,
			CITY: org.city,
		};
	});
}

export function exportData(data, fileName, extension) {
	if (extension === 'excel') {
		exportToExcelWithLogo(
			formatExcelExport(data),
			`${!fileName ? 'Organisations' : fileName}`,
			ORGANISATIONS_FILE_HEADER
		);
	} else {
		convertToPdf(formatExcelExport(data), fileName);
	}

	closeExportModal();
}

export function convertToPdf(data, fileName) {
	const doc = new jsPDF({ orientation: 'l', unit: 'mm', format: 'a4' });

	let col = [
		'Organisation',
		'Email',
		'Phone',
		// 'Company Address',
		// 'Website',
		// 'CIN',
		// 'GST',
		// 'PAN',
		'STATE',
		'CITY',
	];
	let rows = [];

	data.forEach((organisation) => {
		let temp = [];
		Object.keys(organisation).forEach((key, index) => {
			if (col[index] === key) temp[index] = organisation[key];
		});

		rows.push(temp);
	});

	let header = function (data) {
		doc.addImage(Logo, 'png', data.settings.margin.left, 10, 40, 14);
	};

	doc.autoTable(col, rows, {
		margin: { top: 25 },
		beforePageContent: header,
		theme: 'grid',
	});

	doc.save(`${!fileName ? 'Organisations' : fileName}`);
}

export function exportAll(param) {
	// dispatchNoPayload('EXPORTING_ORGANISATIONS_EXPORT_DATA');

	// axios(EXPORT_API + '/organisations' + param, {
	// 	responseType: 'arraybuffer',
	// 	headers: {
	// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 	},
	// })
	exportApi(EXPORT_API + '/organisations' + param)
		.then((response) => {
			// openExportModal();
			// dispatch('SET_ORGANISATIONS_EXPORT_DATA', createOrganisationDTO(response?.data?.data));
			// dispatchNoPayload('EXPORTED_ORGANISATIONS_EXPORT_DATA');
			// fileDownload(response?.data, `Organisations.${fileExtension}`);

			const contentDisposition = response.headers['content-disposition'];
			const matches = /filename="([^"]+)"/.exec(contentDisposition);

			if (matches) {
				const fileName = matches[1];
				fileDownload(response?.data, fileName);
			}
		})
		.catch((error) => {
			handleError(error);
			/*datadogLogs.logger.error('Organisation export api', {}, error);*/
			dispatchNoPayload('EXPORTED_ORGANISATIONS_EXPORT_DATA');
		});
}

// Accordion
export function toggleAccordion(e, titleProps, current) {
	const { index } = titleProps;
	const { activeIndex } = {
		activeIndex: current,
	};
	const newIndex = activeIndex === index ? -1 : index;
	dispatchNoPayload('ORGANISATIONS_EDIT_MODAL_CLOSE');
	dispatch('ORGANISATIONS_TOGGLE_ACCORDION', newIndex);
}

export function openFilterModal() {
	dispatchNoPayload('ORGANISATIONS_FILTER_MODAL_OPEN');
}

export function closeFilterModal() {
	dispatchNoPayload('ORGANISATIONS_FILTER_MODAL_CLOSE');
}

// Dropdown Options
export function getStateForDropdown() {
	return Object.keys(STATES_AND_CITIES).map((state, index) => {
		return {
			key: index,
			value: state,
			text: state,
		};
	});
}

export function getCityForDropdown(cities) {
	if (cities) {
		return cities.map((state, index) => {
			return {
				key: index,
				value: state,
				text: state,
			};
		});
	} else return [];
}

// Connect to Store
function getPageNumberFromStore() {
	return {
		pageNumber: Store.getState().organisations.pageNumber,
		dataSizeLimit: Store.getState().ui.dataSizeLimit,
	};
}
