import { EXPORT_API, ROLES_API } from '../configs/api';
import { Roles_FILE_HEADER } from '../../data/constants/general';
import { createRolesDTO } from '../dto/roles';
import Logo from '../../ui/assets/images/logo.png';
import Store from '../../data/store/store';
import { dispatch, dispatchNoPayload } from './middleware';
import { handleError } from './messaging';
import { exportToExcelWithLogo } from '../utilities/excel';
import { jsPDF } from 'jspdf';
import fileDownload from 'js-file-download';
import { exportApi, getData } from './services/apiRequest';
/*import { datadogLogs } from '@datadog/browser-logs';*/

export function fetchRoles(params = '') {
	return getData(ROLES_API, params);
	// axios(ROLES_API + params, {
	// 	headers: {
	// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 		'Content-Type': 'application/json',
	// 	},
	// });
}

export function refreshRoles(params = '', activePage = 1, resetOffset = false) {
	dispatchNoPayload('ROLES_LOADING');

	// axios(
	// 	ROLES_API +
	// 		'?pagecount=' +
	// 		getPageNumberFromStore().dataSizeLimit +
	// 		'&pagenumber=' +
	// 		(resetOffset ? 1 : activePage) +
	// 		params,
	// 	{
	// 		headers: {
	// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 			'Content-Type': 'application/json',
	// 		},
	// 	}
	// )
	getData(
		ROLES_API,
		'?pagecount=' + getPageNumberFromStore().dataSizeLimit + '&pagenumber=' + (resetOffset ? 1 : activePage) + params
	)
		.then((response) => {
			dispatch('FEED_ROLES', createRolesDTO(response?.data?.data));
			dispatch('ROLES_SET_PAGE_COUNT', response?.data?.count || 0);
			dispatch('ROLES_SET_PAGE_NUMBER', activePage);
		})
		.catch((error) => {
			handleError(error);
			/*datadogLogs.logger.error('Refresh roles api', {}, error);*/
			dispatchNoPayload('ROLES_LOADED');
		});
}

export function fetchRolePermissions(params = '') {
	return getData(ROLES_API + '/permissions', params);
	// axios(ROLES_API + '/permissions' + params, {
	// 	headers: {
	// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 		'Content-Type': 'application/json',
	// 	},
	// });
}

export function searchRoles(query) {
	return `&search=${encodeURIComponent(query)
		.trim()
		.split('+')
		.map((searchTerm) => `${searchTerm}`)}`;
}

// Export
export function openExportModal() {
	dispatchNoPayload('ROLES_EXPORT_MODAL_OPEN');
}

export function closeExportModal() {
	dispatchNoPayload('ROLES_EXPORT_MODAL_CLOSE');
}

export function formatExcelExport(response) {
	let roles = [];
	response.forEach((role) => {
		roles.push({
			Name: role.name,
			Organisation: role.organisation.name,
			Description: role.description,
			IsSuperAdmin: role.isSuperAdmin ? 'Yes' : 'No',
		});
	});

	return roles;
}

export function exportData(data, fileName, extension) {
	if (extension === 'excel') {
		exportToExcelWithLogo(formatExcelExport(data), `${!fileName ? 'Roles' : fileName}`, Roles_FILE_HEADER);
	} else {
		convertToPdf(formatExcelExport(data), fileName);
	}

	closeExportModal();
}

export function convertToPdf(data, fileName) {
	const doc = new jsPDF({ orientation: 'l', unit: 'mm', format: 'a4' });

	let col = ['Name', 'Organisation', 'Description', 'IsSuperAdmin'];
	let rows = [];

	data.forEach((role) => {
		let temp = [];
		Object.keys(role).forEach((key, index) => {
			if (col[index] === key) temp[index] = role[key];
		});

		rows.push(temp);
	});

	let header = function (data) {
		doc.addImage(Logo, 'png', data.settings.margin.left, 10, 40, 14);
	};

	doc.autoTable(col, rows, {
		margin: { top: 25 },
		beforePageContent: header,
		theme: 'grid',
	});

	doc.save(`${!fileName ? 'Roles' : fileName}`);
}

export function exportAll(param = [], fileExtension) {
	// dispatchNoPayload('FETCHING_ROLES_EXPORT_DATA');
	// axios(EXPORT_API + '/roles' + param, {
	// 	headers: {
	// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 		'Content-Type': 'application/json',
	// 	},
	// })
	exportApi(EXPORT_API + '/roles' + param)
		.then((response) => {
			// openExportModal();
			// dispatch('SET_ROLES_EXPORT_DATA', createRolesDTO(response?.data?.data));
			// dispatchNoPayload('FETCHED_ROLES_EXPORT_DATA');
			fileDownload(response?.data, fileExtension);
		})
		.catch((error) => {
			handleError(error);
			/*datadogLogs.logger.error('Roles export api', {}, error);*/
			dispatchNoPayload('FETCHED_ROLES_EXPORT_DATA');
		});
}

export function openFilterModal() {
	dispatchNoPayload('ROLES_FILTER_MODAL_OPEN');
}

export function closeFilterModal() {
	dispatchNoPayload('ROLES_FILTER_MODAL_CLOSE');
}

// Accordion
export function toggleAccordion(e, titleProps, current) {
	const { index } = titleProps;
	const { activeIndex } = {
		activeIndex: current,
	};
	const newIndex = activeIndex === index ? -1 : index;
	dispatchNoPayload('ROLES_EDIT_MODAL_CLOSE');
	dispatch('ROLES_TOGGLE_ACCORDION', newIndex);
}

// Connect to Store
function getPageNumberFromStore() {
	return {
		pageNumber: Store.getState().roles.pageNumber,
		dataSizeLimit: Store.getState().ui.dataSizeLimit,
	};
}
