import { ALERTS_API, EVENTS_API, EXPORT_API } from '../configs/api';
import { ALERTS_FILE_HEADER } from '../../data/constants/general';
import Logo from '../../ui/assets/images/logo.png';
import { dispatch, dispatchNoPayload } from './middleware';
import Store from '../../data/store/store';
import { handleError } from './messaging';
import { exportToExcelWithLogo } from '../utilities/excel';

//Utility Package
import { jsPDF } from 'jspdf';
import { createAlertsDTO } from '../dto/alerts';
import { fetchOrganisations } from './organisations';
import { createOrganisationDTO } from '../dto/organisations';
import { fetchUsers } from './users';
import { createUsersDTO } from '../dto/users';
import fileDownload from 'js-file-download';
import { exportApi, getData } from './services/apiRequest';
/*import { datadogLogs } from '@datadog/browser-logs';*/

export function fetchAlerts(params = '') {
	return getData(ALERTS_API, params);

	// axios(ALERTS_API + params, {
	// 	headers: {
	// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 		'Content-Type': 'application/json',
	// 	},
	// });
}

export function refreshAlerts(params = '', activePage = 1, resetOffset = false) {
	dispatchNoPayload('ALERTS_LOADING');
	// axios(
	// 	ALERTS_API +
	// 		('?pagecount=' + getPageNumberFromStore().dataSizeLimit + '&pagenumber=' + (resetOffset ? 1 : activePage)) +
	// 		(params ? `${params}` : ''),
	// 	{
	// 		headers: {
	// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 			'Content-Type': 'application/json',
	// 		},
	// 	}
	// );
	// (params ? `${params}` : '')
	getData(
		ALERTS_API,
		'?pagecount=' + getPageNumberFromStore().dataSizeLimit + '&pagenumber=' + (resetOffset ? 1 : activePage) + params
	)
		.then((response) => {
			dispatch('FEED_ALERTS', createAlertsDTO(response?.data?.data));
			dispatch('ALERTS_SET_PAGE_COUNT', response?.data?.count || 0);
			dispatch('ALERTS_SET_PAGE_NUMBER', activePage);
		})
		.catch((error) => {
			dispatchNoPayload('ALERTS_LOADED');
			/*datadogLogs.logger.error('refresh alerts api', {}, error);*/
			handleError(error);
		});
}

export function searchAlerts(query) {
	return `&search=${encodeURIComponent(query)
		.trim()
		.split('+')
		.map((searchTerm) => `${searchTerm}`)}`;
}

// Export
export function openExportModal() {
	dispatchNoPayload('ALERTS_EXPORT_MODAL_OPEN');
}

export function closeExportModal() {
	dispatchNoPayload('ALERTS_EXPORT_MODAL_CLOSE');
}

export function formatExcelExport(response) {
	return response.map((alert) => {
		return {
			Name: alert.name,
			Description: alert.description,
			Type: alert.type,
			'Active Days': alert.activeDays,
			'Active Time': `${alert.activeStartTime} - ${alert.activeEndTime}`,
			Enabled: alert.isEnabled ? 'Yes' : 'No',
			Organisation: alert.organisation.name,
			Users: alert.users.map((user) => user.name).join(', '),
		};
	});
}

export function exportData(data, fileName, extension) {
	if (extension === 'excel') {
		exportToExcelWithLogo(formatExcelExport(data), `${!fileName ? 'Alerts' : fileName}`, ALERTS_FILE_HEADER);
	} else {
		convertToPdf(formatExcelExport(data), fileName);
	}

	closeExportModal();
}

export function convertToPdf(data, fileName) {
	const doc = new jsPDF({ orientation: 'l', unit: 'mm', format: 'a4' });

	let col = ['Name', 'Description', 'Type', 'Active Days', 'Active Time', 'Enabled', 'Organisation', 'Users'];
	let rows = [];

	data.forEach((alert) => {
		let temp = [];
		Object.keys(alert).forEach((key, index) => {
			if (col[index] === key) temp[index] = alert[key];
		});

		rows.push(temp);
	});

	let header = function (data) {
		doc.addImage(Logo, 'png', data.settings.margin.left, 10, 40, 14);
	};

	doc.autoTable(col, rows, {
		margin: { top: 25 },
		beforePageContent: header,
		theme: 'grid',
	});

	doc.save(`${!fileName ? 'Sims' : fileName}`);
}

export function exportAll(param, fileExtension) {
	dispatchNoPayload('EXPORTING_ALERTS_EXPORT_DATA');

	// axios
	// .get(ALERTS_API + '?pagecount=1000&pagenumber=1' + param, {
	// .get(EXPORT_API + '/alerts' + param, {
	// 	headers: {
	// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 		'Content-Type': 'application/json',
	// 	},
	// })
	exportApi(EXPORT_API + '/alerts' + param)
		.then((response) => {
			// openExportModal();
			// dispatch('SET_ALERTS_EXPORT_DATA', createAlertsDTO(response?.data?.data));
			// dispatchNoPayload('EXPORTED_ALERTS_EXPORT_DATA');
			fileDownload(response?.data, fileExtension);
		})
		.catch((error) => {
			handleError(error);
			/*datadogLogs.logger.error('Alerts export api', {}, error);*/
			dispatchNoPayload('EXPORTED_ALERTS_EXPORT_DATA');
		});
}

// Accordion
export function toggleAccordion(e, titleProps, current) {
	const { index } = titleProps;
	const { activeIndex } = {
		activeIndex: current,
	};
	const newIndex = activeIndex === index ? -1 : index;

	dispatchNoPayload('ALERTS_EDIT_MODAL_CLOSE');
	dispatch('ALERTS_TOGGLE_ACCORDION', newIndex);
}

export function openFilterModal() {
	dispatchNoPayload('ALERTS_FILTER_MODAL_OPEN');
}

export function closeFilterModal() {
	dispatchNoPayload('ALERTS_FILTER_MODAL_CLOSE');
}

// Dropdown
export function getOrganisationsForDropdown(params = '') {
	return fetchOrganisations(params)
		.then((response) => {
			return createOrganisationDTO(response?.data?.data).map((organisation, index) => {
				return {
					key: index,
					value: organisation.id,
					text: organisation.name,
				};
			});
		})
		.catch((error) => {
			handleError(error);
			return [];
		});
}

export function getUsersForDropdown(params = '') {
	return fetchUsers(params)
		.then((response) => {
			return createUsersDTO(response?.data?.data).map((user, index) => {
				return {
					key: index,
					value: user.id,
					text: user.name,
				};
			});
		})
		.catch((error) => {
			handleError(error);
			return [];
		});
}

export function getEventTypesForDropdown() {
	// axios(EVENTS_API + '/types', {
	// 	headers: {
	// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 		'Content-Type': 'application/json',
	// 	},
	// })
	return getData(EVENTS_API, '/types')
		.then((response) => {
			const eventTypes = response.data;
			if (!eventTypes) return [];

			return Object.keys(eventTypes).map((key, index) => {
				return {
					key: index,
					value: eventTypes[key],
					text: key,
				};
			});
		})
		.catch((error) => {
			handleError(error);
			return [];
		});
}

export function getActiveDaysForDropdown() {
	return [
		{
			key: 1,
			value: 'Sunday',
			text: 'Sunday',
		},
		{
			key: 2,
			value: 'Monday',
			text: 'Monday',
		},
		{
			key: 3,
			value: 'Tuesday',
			text: 'Tuesday',
		},
		{
			key: 4,
			value: 'Wednesday',
			text: 'Wednesday',
		},
		{
			key: 5,
			value: 'Thursday',
			text: 'Thursday',
		},
		{
			key: 6,
			value: 'Friday',
			text: 'Friday',
		},
		{
			key: 7,
			value: 'Saturday',
			text: 'Saturday',
		},
	];
}

export function getIsEnabledForDropdown() {
	return [
		{
			key: 1,
			value: true,
			text: 'Yes',
		},
		{
			key: 2,
			value: false,
			text: 'No',
		},
	];
}

// Connect to Store
function getPageNumberFromStore() {
	return {
		pageNumber: Store.getState().sims.pageNumber,
		dataSizeLimit: Store.getState().ui.dataSizeLimit,
	};
}
