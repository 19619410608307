import { handleError } from './messaging';
import axios from 'axios';
import { DOCUMENTS_API, EXPORT_API, USERS_API } from '../configs/api';
import { exportToExcelWithLogo } from '../utilities/excel';
import { USER_DOCUMENTS_FILE_HEADERS } from '../../data/constants/general';
import { closeExportModal } from './devices';
import { jsPDF } from 'jspdf';
import Logo from '../../ui/assets/images/logo.png';
import { exportApi, getData, postData } from './services/apiRequest';
import fileDownload from 'js-file-download';
/*import { datadogLogs } from '@datadog/browser-logs';*/

export function getUsersForDropdown(query) {
	// axios(USERS_API + query, {
	// 	headers: {
	// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 		'Content-Type': 'application/json',
	// 	},
	// })
	return getData(USERS_API, query)
		.then((response) => {
			return response?.data?.data.map((user, index) => {
				return {
					key: index,
					value: user.id,
					text: user.name,
				};
			});
		})
		.catch((error) => {
			handleError(error);
			return [];
		});
}

export const fetchUserDocuments = (query) => {
	return getData(DOCUMENTS_API, query);
	// axios(DOCUMENTS_API + query, {
	// 	headers: {
	// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 		'Content-Type': 'application/json',
	// 	},
	// });
};

export function postAddRequest(formData) {
	return postData(DOCUMENTS_API, '', formData);
	// axios.post(DOCUMENTS_API, formData, {
	// 	headers: {
	// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 		'Content-Type': 'multipart/form-data',
	// 	},
	// });
}

export function patchEditRequest(id, formData) {
	return axios.patch(DOCUMENTS_API + `/${id}`, formData, {
		headers: {
			Authorization: 'Bearer ' + localStorage.getItem('token'),
			'Content-Type': 'multipart/form-data',
		},
	});
}

export function fetchDocumentFile(id) {
	return exportApi(EXPORT_API, `/documents/${id}`);
	// axios(EXPORT_API + `/documents/${id}`, {
	// 	responseType: 'arraybuffer',
	// 	headers: {
	// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 		'Content-Type': 'application/json',
	// 	},
	// });
}

export function exportAll(param, fileExtension) {
	exportApi(EXPORT_API + '/documents', param)
		.then((response) => {
			fileDownload(response?.data, `UserDocument.${fileExtension}`);
		})
		.catch((error) => {
			/*datadogLogs.logger.error('User documents export api', {}, error);*/
			handleError(error);
		});
}

export function downloadDocumentFile(id) {
	return exportApi(EXPORT_API + `/documents/${id}`);
	// axios(EXPORT_API + `/documents/${id}`, {
	// 	responseType: 'arraybuffer',
	// 	headers: {
	// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 		'Content-Type': 'application/json',
	// 	},
	// });
}

export function exportData(data, fileName, extension) {
	if (extension === 'excel') {
		exportToExcelWithLogo(formatExcelExport(data), `${!fileName ? 'Devices' : fileName}`, USER_DOCUMENTS_FILE_HEADERS);
	} else {
		convertToPdf(formatExcelExport(data), fileName);
	}

	closeExportModal();
}

export function formatExcelExport(response) {
	return response.map((document) => {
		return {
			'Document Type': document.documentType,
			'User Name': document.userName,
			Organisation: document.organisationName,
			'Expiry Date': document.documentExpiry,
			Description: document.documentDescription,
		};
	});
}

export function convertToPdf(data, fileName) {
	const doc = new jsPDF({ orientation: 'l', unit: 'mm', format: 'a4' });

	let col = ['Document Type', 'User Name', 'Organisation', 'Expiry Date', 'Description'];
	let rows = [];

	data.forEach((document) => {
		let temp = [];
		Object.keys(document).forEach((key, index) => {
			if (col[index] === key) temp[index] = document[key];
		});

		rows.push(temp);
	});

	let header = function (data) {
		doc.addImage(Logo, 'png', data.settings.margin.left, 10, 40, 14);
	};

	doc.autoTable(col, rows, {
		margin: { top: 25 },
		beforePageContent: header,
		theme: 'grid',
	});

	doc.save(`${!fileName ? 'Devices' : fileName}`);
}

export const deleteDocument = (id) => {
	return axios.delete(DOCUMENTS_API + `/${id}`, {
		headers: {
			Authorization: 'Bearer ' + localStorage.getItem('token'),
			'Content-Type': 'application/json',
		},
	});
};

export function documentRestore(id) {
	return postData(DOCUMENTS_API, `/${id}/restore`);
	// axios.post(
	// 	DOCUMENTS_API + `/${id}/restore`,
	// 	{},
	// 	{
	// 		headers: {
	// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 			'Content-Type': 'application/json',
	// 		},
	// 	}
	// );
}
