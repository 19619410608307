import React from 'react';

import AppGateway from './AppGateway';

import Store from './data/store/store';

import 'react-toastify/dist/ReactToastify.css';
import 'semantic-ui-css/semantic.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Theme
import { COLORS } from './ui/styles/colors';

import { initializeFirebase } from './logic/middleware/firebase';

import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { Flip, ToastContainer } from 'react-toastify';
import { initializeMixpanel } from './logic/middleware/mixpanel';
/*import { DataDog } from './logic/middleware/services/dataDog';*/

const App = () => {
	initializeFirebase();
	initializeMixpanel();
	/*DataDog();*/

	return (
		<Provider store={Store}>
			<ThemeProvider theme={COLORS}>
				<AppGateway />
				<ToastContainer position={'bottom-left'} transition={Flip} draggable={true} />
			</ThemeProvider>
		</Provider>
	);
};

export default App;
